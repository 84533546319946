import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import sobres from "../../assets/sobres.png";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioNumByPath } from "../../utils/helpers";

function Scenario4ExtraIntro({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const activeScenario = getScenarioNumByPath(location.pathname);

	return (
		<div className="h-100 bg-preguntas-3">
			<div className="container-fluid px-5 pt-5 h-100">
				<div className="d-flex">
					<p
						className="txt30 txt-purple bold line-height-40"
						style={{ maxWidth: "58%" }}
					>
						Pero antes de intentar conseguir el último sello, te
						lanzamos un nuevo reto…
					</p>
				</div>
				<div className="row">
					<div className="col-8 d-flex flex-column align-items-start">
						<div
							className="txt20 txt-blue"
							style={{ maxWidth: "80%" }}
						>
							<p className="pt-1">
								Desde varios servicios quirúrgicos, te han
								dirigido este fin de semana{" "}
								<span className="bold">
									9 interconsultas relacionadas con el uso de
									nutrición parenteral.
								</span>
							</p>
							<p className="pt-1">
								Identifica en cuáles de las siguientes 9
								situaciones estaría mejor indicado el{" "}
								<span className="bold">
									inicio de nutrición enteral
								</span>{" "}
								(es decir: son contraindicaciones{" "}
								<span className="bold">relativas</span> de
								nutrición enteral).
							</p>
							<p className="pt-1">
								Se contabilizan como errores tanto las casillas
								incorrectamente marcadas como las correctas no
								marcadas.
							</p>
							<p className="pt-2 bold">
								Tienes todos los intentos que necesites.
							</p>
						</div>
						<br />
					</div>
					<div className="col-4 d-flex align-items-end justify-content-start">
						<img src={sobres} width={"85%"}></img>
					</div>
					<div>
						<div
							className="px-5"
							style={{
								position: "absolute",
								left: "0px",
								bottom: "15vh",
							}}
						>
							<NextButton
								navigateTo={ROUTES.SCENARIO4_EXTRA}
								activeScenario={activeScenario}
							></NextButton>
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar activeScenario={activeScenario}></ScenarioBar>
		</div>
	);
}

export default injectIntl(Scenario4ExtraIntro);
