import React from "react";
import dosIntentos from "../assets/dos-intentos.png";
import unIntento from "../assets/un-intento.png";
import ceroIntentos from "../assets/cero-intentos.png";

function Intentos({ intentosRestantes }) {
	return (
		<div>
			<img
				src={
					intentosRestantes === 2
						? dosIntentos
						: intentosRestantes === 1
						? unIntento
						: ceroIntentos
				}
				width={71}
			></img>
		</div>
	);
}
export default Intentos;
