import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnswerByQuizAndGroup, postAnswer } from "../../api/answer";
import bombilla from "../../assets/bombilla-caso.svg";
import cross from "../../assets/close.svg";
import flechaAvanzar from "../../assets/flecha-avanzar.svg";
import paciente1Lila from "../../assets/paciente1-lila.png";
import paciente1Preview from "../../assets/paciente1-preview.png";
import paciente1 from "../../assets/paciente1.png";
import paciente2Lila from "../../assets/paciente2-lila.png";
import paciente2Preview from "../../assets/paciente2-preview.png";
import paciente2 from "../../assets/paciente2.png";
import paciente3Lila from "../../assets/paciente3-lila.png";
import paciente3Preview from "../../assets/paciente3-preview.png";
import paciente3 from "../../assets/paciente3.png";
import paciente4Lila from "../../assets/paciente4-lila.png";
import paciente4Preview from "../../assets/paciente4-preview.png";
import paciente4 from "../../assets/paciente4.png";
import paciente1PDF from "../../assets/pdf/paciente1.pdf";
import paciente2PDF from "../../assets/pdf/paciente2.pdf";
import paciente3PDF from "../../assets/pdf/paciente3.pdf";
import paciente4PDF from "../../assets/pdf/paciente4.pdf";
import iconCompletado from "../../assets/scenariobar/ico-completed.svg";
import iconZoom from "../../assets/zoom.svg";
import Intentos from "../../components/Intentos";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateScenario, updateTime } from "../../redux/actions";
import { useLang } from "../../translations/i18n";
import {
	QUESTION_ALREADY_ANSWERED_BUTTON,
	QUESTION_ALREADY_ANSWERED_TEXT,
	QUIZZES,
	ROUTES,
	SCENARIOS,
} from "../../utils/constants";
import {
	getScenarioNumByPath,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import {
	modalCasoClinico,
	modalPDF,
	modalQuestionResolution,
} from "../../utils/logger";

function Scenario3Extra({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const group = useSelector((state) => state.group);
	const gameId = useSelector((state) => state.game)?._id;

	const activeScenario = getScenarioNumByPath(location.pathname);

	const [ACTIVE, setACTIVE] = useState(0);
	const [time, setTime] = useState(0);
	const [canAdvance, setCanAdvance] = useState(false);
	const [intentosRestantes, setIntentosRestantes] = useState(2);

	const SCENARIO_ID = SCENARIOS.SCENARIO_3;

	function getPatientExplanation(patientIdx) {
		const patient = options[ACTIVE];
		return `<div class="txt14">
				<div class="row d-flex align-items-start">
					<div class="col-2 d-flex justify-content-end">
						<img
							class="me-3 circle mb-3"
							src=${patient.profile}
							width="77"
						></img>
					</div>
					<div class="col-10">
						<div class="d-flex flex-column align-items-start">
							<div class="bold txt-blue">
								Paciente ${patientIdx + 1}
							</div>
							<div class="text-left mt-1">
								${patient.profileDescription}
							</div>
						</div>
						<div>
							<div class="d-flex flex-column align-items-start text-left mt-2">
								<div class="bold txt-blue">
									Motivo de ingreso
								</div>
								<div class="mt-1">${patient.motivoIngreso}</div>
							</div>
						</div>
						<div class="d-flex flex-column align-items-start text-left mt-2">
							<div class="bold txt-blue">
								${patient.title}
							</div>
							<div class="mt-1">
								${patient.options.find((x) => x.correct).text}
							</div>
						</div>
					</div>
				</div>
				<div class="d-flex justify-content-start bombilla-caso" style="margin-top: -30px;">
					<img class="ms-4" src=${bombilla}></img>
				</div>
				<div class="d-flex justify-content-center">
					<div class="d-flex flex-column align-items-start text-left mt-3 bg-purple-caso p-3">
						<div class="bold txt16 txt-white">Justificación</div>
						<div class="text-left mt-2 txt14 txt-white">
							${patient.justification}
						</div>
					</div>
				</div>
			</div>`;
	}

	function canAchieveStamp() {
		let canAchieveStamp = true;

		options.forEach((item) => {
			if (item.completed) {
				if (item.options.some((x) => x.selected && !x.correct))
					canAchieveStamp = false;
			}
		});

		return canAchieveStamp;
	}

	const CONTENT = [
		{
			title: "¿Qué suplementación sería adecuada para él?",
			profileImage: ACTIVE === 0 ? paciente1Lila : paciente1,
			profile: paciente1,
			quizId: QUIZZES.CASO_CLINICO_1,
			tab: "Paciente 1",
			img: paciente1Preview,
			completed: false,
			options: [
				{
					text: "Suplementación «estándar» hipercalórica normoproteica.",
					selected: false,
					correct: false,
				},
				{
					text: "Suplementación específica de paciente renal (hipercalórica, hipoproteica, pobre en fósforo y potasio).",
					selected: false,
					correct: false,
				},
				{
					text: "Suplementación inmunomoduladora (hipercalórica, hiperproteica, enriquecida en glutamina, arginina, nucleótidos y ácidos grasos omega-3).",
					selected: false,
					correct: true,
				},
				{
					text: "Suplementación específica de paciente con hepatopatía (hipercalórica, normoproteica, exenta de aminoácidos aromáticos).",
					selected: false,
					correct: false,
				},
			],
			profileDescription:
				"Alberto, 51 años. Antecedentes de esteaosis hepática por enolismo crónico y enfermedad renal estadio III",
			motivoIngreso: `<div class="d-flex align-items-center justify-content-start">
						<div class="me-1 bold txt-blue">·</div>
						<div>
							Adenocarcinoma de esófago pendiente de cirugía.
						</div>
					</div>
					<div class="d-flex align-items-center justify-content-start">
						<div class="me-1 bold txt-blue">·</div>
						<div>
							Desnutrición grave con pérdida de más del 10 % del
							peso habitual.
						</div>
					</div>`,
			justification: `En el paciente quirúrgico con patología oncológica de tracto gastrointestinal superior y desnutrición, la utilización de inmunonutrición ha demostrado una disminución en las comorbilidades<sup>12</sup>.`,
		},
		{
			title: "¿Qué suplementación sería adecuada para ella?",
			tab: "Paciente 2",
			profileImage: ACTIVE === 1 ? paciente2Lila : paciente2,
			profile: paciente2,
			quizId: QUIZZES.CASO_CLINICO_2,
			img: paciente2Preview,
			completed: false,
			options: [
				{
					text: "Suplementación específica de paciente respiratorio (hipercalórica, hiperproteica, rica en grasa).",
					selected: false,
					correct: false,
				},
				{
					text: "Suplementación peptídica (hipercalórica, hiperproteica con proteína hidrolizada).",
					selected: false,
					correct: true,
				},
				{
					text: "Suplementación específica para paciente con diabetes (hipercalórica, hiperproteica, hidratos de carbono de bajo índice glucémico, rica en ácidos grasos monoinsaturados y mezcla de fibras).",
					selected: false,
					correct: false,
				},
				{
					text: "No precisa suplementación artificial; simplemente, ajuste de la dieta.",
					selected: false,
					correct: false,
				},
			],
			profileDescription:
				"Carla, 20 años. Antecedentes personales de fibrosis quística con insuficiencia pancreática exocrina y endocrina, en tratamiento con insulina.",
			motivoIngreso: `<div class="d-flex justify-content-start">
						<div>
						Cuadro de insuficiencia respiratoria grave y disminución importante de la ingesta (<70 %), con distensión abdominal y diarreas frecuentes a pesar de la suplementación con enzimas pancreáticas.
						</div>
					</div>`,
			justification: `En el paciente con fibrosis quística e insuficiencia
			pancreática no controlada, la utilización de
			suplementación artificial peptídica podría tener
			utilidad para mejorar la tolerabilidad<sup>13</sup>.`,
		},
		{
			title: "¿Qué suplementación sería adecuada para él?",
			tab: "Paciente 3",
			profileImage: ACTIVE === 2 ? paciente3Lila : paciente3,
			profile: paciente3,
			quizId: QUIZZES.CASO_CLINICO_3,
			img: paciente3Preview,
			completed: false,
			options: [
				{
					text: "Suplementación específica de paciente respiratorio (hipercalórica, hiperproteica, rica en grasa).",
					selected: false,
					correct: false,
				},
				{
					text: "Suplementación hipercalórica e hiperproteica, con una fuente proteica enriquecida en aminoácidos ramificados.",
					selected: false,
					correct: true,
				},
				{
					text: "Suplementación específica de paciente renal (hipercalórica, hipoproteica, pobre en fósforo y potasio).",
					selected: false,
					correct: false,
				},
				{
					text: "Suplementación específica normocalórica, normoproteica, pobre en sodio.",
					selected: false,
					correct: false,
				},
			],
			profileDescription:
				"Máximo, 81 años. Antecedentes de insuficiencia cardíaca y enfermedad pulmonar obstructiva crónica. Enfermedad renal crónica en estadio III. Estable en el momento actual.",
			motivoIngreso: `<div class="d-flex align-items-center justify-content-start">
						<div class="me-2 bold txt-blue">·</div>
						<div>
							Acude a la consulta de nutrición clínica para valoración nutricional.
						</div>
					</div>
					<div class="d-flex align-items-center justify-content-start">
						<div class="me-2 bold txt-blue">·</div>
						<div>
							El paciente es diagnosticado de desnutrición y sarcopenia.
						</div>
					</div>`,
			justification: `En el paciente no diabético con desnutrición ingresado con patología cardiopulmonar o infecciosa, la utilización de suplementación artificial con fuente de proteínas enriquecida en aminoácidos ramificados o sus metabólicos demostró disminución de la mortalidad y las complicaciones<sup>14</sup>.`,
		},
		{
			title: "¿Qué suplementación sería adecuada para ella?",
			tab: "Paciente 4",
			profileImage: ACTIVE === 3 ? paciente4Lila : paciente4,
			profile: paciente4,
			quizId: QUIZZES.CASO_CLINICO_4,
			img: paciente4Preview,
			completed: false,
			options: [
				{
					text: "Suplementación específica de paciente renal (hipercalórica, hiperproteica, pobre en fósforo y potasio).",
					selected: false,
					correct: true,
				},
				{
					text: "Suplementación específica de paciente con diabetes (hipercalórica, hiperproteica, hidratos de carbono de bajo índice glucémico, rica en ácidos grasos monoinsaturados y mezcla de fibras).",
					selected: false,
					correct: false,
				},
				{
					text: "Suplementación específica de paciente renal (hipercalórica, hipoproteica, pobre en fósforo y potasio).",
					selected: false,
					correct: false,
				},
				{
					text: "Suplementación «estándar» hipercalórica, normoproteica.",
					selected: false,
					correct: false,
				},
			],
			profileDescription:
				"Adelina, 64 años. Enfermedad renal crónica por nefropatía diabética, en tratamiento mediante hemodiálisis.",
			motivoIngreso: `<div class="d-flex align-items-center justify-content-start">
						<div class="me-2 bold txt-blue">·</div>
						<div>
							Complicación en la fístula arteriovenosa.
						</div>
					</div>
					<div class="d-flex align-items-center justify-content-start">
						<div class="me-2 bold txt-blue">·</div>
						<div>
							Datos de desgaste energético proteico (ingesta <50 % de la habitual). 
						</div>
					</div>`,
			justification: `<div>El paciente con enfermedad renal crónica en tratamiento con hemodiálisis tiene un aumento de las necesidades proteicas (1,2-1,5 g/kg/día) y es necesaria la restricción de iones como el fósforo y el potasio.
			Si no se alcanzan los requerimientos con dieta oral adaptada, es necesaria la utilización de suplementación artificial, y la suplementación nutricional específica renal puede tener utilidad en estos pacientes<sup>15</sup>.</div>`,
		},
	];

	const [options, setOptions] = useState(CONTENT);

	const CORRECT_ANSWER_TEXT = `<div>
			<p class="txt22 txt-green bold">¡Correcto!</p>
			<div class="mt-1">${getPatientExplanation(ACTIVE)}</div>
		</div>`;

	const WORNG_ANSWER_TEXT = `<div>
		<p class="txt22 txt-red bold">¡Vaya!</p>
		<p class="txt18">Te queda un intento. Habla con tus compañeros y consensuad la solución.</p>
		<p class="txt18">${canAchieveStamp() ? "¡Os jugáis un sello!" : ""}</p>

		</div>`;
	const INCORRECT_ANSWER_TEXT = `<div>
		<div class="txt22 txt-red bold">¡Lástima!</div>
		<br />
		<div class="txt18">Has fallado a la hora de indicar la suplementación. Aquí tienes la solución:</div>
		<br />
		<div>${getPatientExplanation(ACTIVE)}</div>
	</div>`;

	const RECUERDA_RESPONDER_TEXT = `<div>
	<div class="txt22 txt-red bold">
		Recuerda que, para poder avanzar, tienes que completar la pregunta actual.
	</div>
</div>`;

	const RECUERDA_SELECCIONAR_TEXT = `<div>
		<div class="txt22 txt-red bold">
			Recuerda que debe tener una opción marcada para que se active el botón Comprobar.
		</div>
	</div>`;

	const FEEDBACK_FINAL = `<div>
			<p class="bold">
				La prescripción de suplementación artificial oral es un
				tratamiento médico.
			</p>
			<p>
				Es necesario prescribir en función de las características del
				paciente y sus requerimientos energeticoproteicos.
			</p>
			<p>
				Para ellos, es <span class="bold">imprescindible</span> conocer el tratamiento
				(suplementación artificial) que les prescribimos y no pautar
				únicamente en función del nombre del suplemento.
			</p>
		</div>`;

	useEffect(() => {
		getAnswerByQuizAndGroup(options[ACTIVE]?.quizId, group?._id)
			.then((res) => {
				if (res.status === 200 && res.data.length > 0) {
					const updatedOptions = options.map((item, index) => {
						if (index === ACTIVE) {
							const updatedItem = {
								...item,
								options: item.options.map((x, xIndex) =>
									x.correct
										? {
												...x,
												selected:
													res.data[0].score === 1,
										  }
										: { ...x }
								),
								completed: true,
							};
							return updatedItem;
						}
						return item;
					});

					setOptions(updatedOptions);

					modalQuestionResolution({
						correct: false,
						allowOutsideClick: false,
						showCancelButton: false,
						showConfirmButton: true,
						customConfirmButtonHtml:
							QUESTION_ALREADY_ANSWERED_BUTTON,
						textHtml: QUESTION_ALREADY_ANSWERED_TEXT,
					}).then((res) => {
						if (res.isConfirmed) {
							if (ACTIVE === options.length - 1)
								navigate(ROUTES.TRANSITION_SCENARIOS, {
									state: {
										activeScenario: 4,
									},
								});
							else {
								handleNext();
							}
						}
					});
				}
			})
			.catch((error) => {});
	}, [ACTIVE]);

	useTimer(
		() => {
			setTime(time + 1);
		},
		options[ACTIVE].completed ? null : 1000
	);

	function setActiveOptionCompleted() {
		const updatedOptions = options.map((item, index) => {
			if (index === ACTIVE) {
				const updatedItem = {
					...item,
					completed: true,
				};
				return updatedItem;
			}
			return item;
		});

		setOptions(updatedOptions);
	}

	async function submitAnswer(correct) {
		const score = correct ? 1 : 0;
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: options[ACTIVE].quizId,
			group: group?._id,
			score: score,
			time: time,
		})
			.then((res) => {
				if (res.status === 201) {
					setActiveOptionCompleted();
				}
			})
			.catch((error) => {});
	}

	const handleActive = (index) => {
		if (options[index].completed) return;
		if (ACTIVE + 1 < index && options[ACTIVE].completed) return;
		if (ACTIVE < index && !options[ACTIVE].completed) {
			modalQuestionResolution({
				correct: false,
				textHtml: RECUERDA_RESPONDER_TEXT,
				allowOutsideClick: true,
			});
			return;
		}
		setTime(0);
		setIntentosRestantes(2);
		setACTIVE(index);
	};

	function renderTabs() {
		const getPatientProfileImage = (patientIndex) => {
			if (patientIndex === 0) {
				if (ACTIVE === patientIndex && !options[ACTIVE].completed)
					return paciente1Lila;
				else return paciente1;
			} else if (patientIndex === 1) {
				if (ACTIVE === patientIndex && !options[ACTIVE].completed)
					return paciente2Lila;
				else return paciente2;
			} else if (patientIndex === 2) {
				if (ACTIVE === patientIndex && !options[ACTIVE].completed)
					return paciente3Lila;
				else return paciente3;
			} else if (patientIndex === 3) {
				if (ACTIVE === patientIndex && !options[ACTIVE].completed)
					return paciente4Lila;
				else return paciente4;
			}
		};
		return (
			<div className="row w-100">
				<div className="col-12 p-0 pt-5 mt-2">
					<ul
						className="nav nav-tabs"
						style={{
							borderBottomWidth: "0px",
						}}
					>
						{options.map((item, idx) => (
							<div className="position-relative">
								<li
									className="nav-item text-center"
									onClick={() => handleActive(idx)}
								>
									<div
										className={`nav-link px-4 ${
											item.completed &&
											item.options.some(
												(x) => x.selected && x.correct
											)
												? "correct"
												: item.completed
												? "incorrect"
												: ACTIVE === idx
												? "active"
												: ""
										}`}
									>
										<Image
											className="me-2"
											style={{
												marginTop:
													ACTIVE === idx ? "1px" : "",
											}}
											src={getPatientProfileImage(idx)}
											width={30}
											roundedCircle
										></Image>
										{item.tab}
										{item.completed && (
											<div className="completed-tab">
												<img src={iconCompletado} />
											</div>
										)}
									</div>
								</li>
							</div>
						))}
					</ul>
				</div>
			</div>
		);
	}

	function renderContent() {
		let data = options[ACTIVE];
		return (
			<div
				className="row bg-tab-content d-flex align-items-center ps-4"
				style={{ paddingTop: "3%", paddingBottom: "3.5%" }}
			>
				<div className="col-4 d-flex justify-content-center">
					<div className="patient-report-preview-container">
						<img src={data?.img} alt="informe" width={260}></img>
						<img
							style={{
								position: "absolute",
								bottom: "4%",
								right: "4%",
							}}
							className="cursor-pointer"
							src={iconZoom}
							alt="ampliar"
							onClick={() =>
								modalPDF({
									pdf:
										ACTIVE === 0
											? paciente1PDF
											: ACTIVE === 1
											? paciente2PDF
											: ACTIVE === 2
											? paciente3PDF
											: paciente4PDF,
									title: data.tab,
									customCancelButtonHtml: `<div class="d-flex align-items-center justify-content-center"><img class="me-2" src=${cross}></img>CERRAR</div>`,
								})
							}
						></img>
					</div>
				</div>
				<div className="col-8">
					<p className="txt22 bold txt-pink mb-4">{data.title}</p>
					{data.options?.map((option, index) => {
						const questionCompleted = options[ACTIVE].completed;
						return (
							<div
								className={`d-flex align-items-start ${
									ACTIVE === 1 ? "mt-2" : "mt-3"
								}`}
							>
								<div
									className={`d-flex justify-content-start`}
									style={{
										marginTop:
											option.text.length >= 70
												? "4px"
												: "1px",
									}}
								>
									<div
										className={`radio-button-container ${
											questionCompleted && option.correct
												? "correct"
												: questionCompleted &&
												  option.selected &&
												  !option.correct
												? "incorrect"
												: option.selected
												? "selected"
												: ""
										}`}
										onClick={() => handleOptionClick(index)}
									>
										<div
											className={`radio-button ${
												questionCompleted &&
												option.correct
													? "correct"
													: questionCompleted &&
													  option.selected &&
													  !option.correct
													? "incorrect"
													: option.selected
													? "selected"
													: ""
											}`}
										></div>
									</div>
								</div>
								<div
									className="d-flex justify-content-start"
									style={{ maxWidth: "87%" }}
								>
									<div
										className={`ms-3 txt16 cursor-pointer ${
											questionCompleted && option.correct
												? "txt-green bold"
												: questionCompleted &&
												  option.selected &&
												  !option.correct
												? "txt-red bold"
												: option.selected
												? "txt-pink bold"
												: ""
										}`}
										style={{ lineHeight: "18px" }}
										onClick={() => handleOptionClick(index)}
									>
										{String.fromCharCode(
											"a".charCodeAt(0) + index
										) +
											") " +
											option.text}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}

	const handleOptionClick = (optionIndex) => {
		if (options[ACTIVE].completed) return;
		const updatedOptions = options.map((item, index) => {
			if (index === ACTIVE) {
				const updatedItem = {
					...item,
					options: item.options.map((x, xIndex) => ({
						...x,
						selected: xIndex === optionIndex,
					})),
				};
				return updatedItem;
			}
			return item;
		});

		setOptions(updatedOptions);
	};

	const handleNext = () => {
		setTime(0);
		setIntentosRestantes(2);
		setACTIVE(ACTIVE < options.length - 1 ? ACTIVE + 1 : ACTIVE);
	};

	function checkAnswers() {
		const questionOptions = options[ACTIVE].options;
		if (!questionOptions.some((x) => x.selected)) {
			modalQuestionResolution({
				correct: false,
				textHtml: RECUERDA_SELECCIONAR_TEXT,
				allowOutsideClick: true,
			});
			return;
		}

		let correct = questionOptions.some((x) => x.selected && x.correct);

		let completed = false;

		if (correct) {
			modalCasoClinico({
				correct: correct,
				textHtml: CORRECT_ANSWER_TEXT,
			});
			completed = true;
		} else {
			if (intentosRestantes > 1)
				modalQuestionResolution({
					correct: correct,
					textHtml: WORNG_ANSWER_TEXT,
					allowOutsideClick: true,
				});
			else {
				completed = true;
				modalCasoClinico({
					correct: correct,
					textHtml: INCORRECT_ANSWER_TEXT,
				});
			}
		}
		setIntentosRestantes(intentosRestantes - 1);

		if (completed) {
			submitAnswer(correct);
		}
	}

	return (
		<div className="h-100">
			<div
				className="container-fluid px-5 pt-5 h-100"
				style={{ marginLeft: "-4%", marginTop: "-3%" }}
			>
				<div className="container h-100 bgIntranet px-4">
					<div
						className="position-absolute px-4 pt-3"
						style={{ top: "12%", right: "0px" }}
					>
						<Intentos
							intentosRestantes={intentosRestantes}
						></Intentos>
					</div>
					<div className="px-4">
						{renderTabs()}
						{renderContent()}
					</div>
				</div>
				<div
					style={{
						position: "absolute",
						right: "5%",
						bottom: "8vh",
					}}
				>
					{!options[ACTIVE].completed ? (
						<button
							className="btn-main"
							onClick={() => {
								checkAnswers();
							}}
						>
							<div className="text-btn-main">COMPROBAR</div>
						</button>
					) : ACTIVE < options.length - 1 ? (
						<button className="btn-main" onClick={handleNext}>
							<div className="text-btn-main">SIGUIENTE</div>
							<img className="ms-2" src={flechaAvanzar}></img>
						</button>
					) : (
						<button
							className="btn-main"
							onClick={() =>
								modalQuestionResolution({
									correct: true,
									textHtml: FEEDBACK_FINAL,
									showCancelButton: false,
									showConfirmButton: true,
									allowOutsideClick: false,
									customConfirmButtonHtml: `<div class="d-flex justify-content-center align-items-center"><div class="text-btn-main">AVANZAR</div><img class="ms-2" src=${flechaAvanzar}></img></div>`,
								}).then((res) => {
									if (res.isConfirmed) {
										if (group) {
											let time = getTimer();
											dispatch(updateTime(time));
											dispatch(updateScenario(4));
											updateStats({
												...group,
												actualScenario: 4,
												time: time,
												lastVisited:
													ROUTES.SCENARIO4_INTRO,
											});
											navigate(ROUTES.STAMP_PROGRESS, {
												state: {
													activeScenario: 4,
													navigateTo:
														ROUTES.TRANSITION_SCENARIOS,
												},
											});
										}
									}
								})
							}
						>
							<div className="text-btn-main">AVANZAR</div>
							<img className="ms-2" src={flechaAvanzar}></img>
						</button>
					)}
				</div>
			</div>
			<ScenarioBar activeScenario={activeScenario} />
		</div>
	);
}

export default injectIntl(Scenario3Extra);
