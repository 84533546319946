import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnswerByQuizAndGroup, postAnswer } from "../../api/answer";
import iconSeleccionar from "../../assets/icon-seleccionar.svg";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateTime } from "../../redux/actions";
import {
	QUESTION_ALREADY_ANSWERED_BUTTON,
	QUESTION_ALREADY_ANSWERED_TEXT,
	QUIZZES,
	ROUTES,
	SCENARIOS,
} from "../../utils/constants";
import { getScenarioNumByPath, getTimer } from "../../utils/helpers";
import { modalQuestionResolution } from "../../utils/logger";

function Scenario4Extra({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const group = useSelector((state) => state.group);

	const activeScenario = getScenarioNumByPath(location.pathname);

	const [checkedAnswers, setCheckedAnswers] = useState(false);
	const [isCompleted, setIsCompleted] = useState(false);
	const [time, setTime] = useState(0);

	const [options, setOptions] = useState([
		{
			id: 1,
			text: "Isquemia mesentérica crónica",
			selected: null,
			correct: true,
		},
		{
			id: 2,
			text: "Hemorragia gastrointestinal aguda",
			selected: null,
			correct: false,
		},
		{
			id: 3,
			text: "Obstrucción intestinal completa",
			selected: null,
			correct: false,
		},
		{
			id: 4,
			text: "Perforación intestinal yeyunal",
			selected: null,
			correct: false,
		},
		{
			id: 5,
			text: "Fístulas",
			selected: null,
			correct: true,
		},
		{
			id: 6,
			text: "Infarto mesentérico agudo",
			selected: null,
			correct: false,
		},
		{
			id: 7,
			text: "Inestabilidad hemodinámica",
			selected: null,
			correct: false,
		},
		{
			id: 8,
			text: "Enfermedad inflamatoria intestinal (fase aguda)",
			selected: null,
			correct: true,
		},
		{
			id: 9,
			text: "Insuficiente superficie absortiva",
			selected: null,
			correct: false,
		},
	]);

	const CORRECT_ANSWER_TEXT = `<div>
			<p class="txt22 txt-green bold">
				¡Correcto!
			</p>
			<p class="txt18">
				Ya dispones de acceso a la información.
			</p>
		</div>`;

	function getWrongAnswerText(numErrores) {
		return `<div>
			<p class="txt18">
				Hay <span class="txt-red bold">${numErrores} ${
			numErrores === 1 ? "error" : "errores"
		}</span>
			</p>
			<div class="txt18">
				<p>
					Se contabilizan como errores tanto las casillas incorrectamente marcadas como las correctas no marcadas. 
				</p>
				<p>
					Inténtalo de nuevo.
				</p>
			</div>				
			</div>`;
	}

	const SelectOption = ({ id }) => {
		let option = options.find((x) => x.id === id);

		const handleOptionClick = () => {
			if (isCompleted) return;
			let updatedOptions = options.map((x) =>
				x.id === id ? { ...x, selected: !x.selected } : { ...x }
			);

			setOptions(updatedOptions);
		};

		const backgroundColor =
			checkedAnswers && option?.correct ? "#87CA1B" : "";

		return (
			<div>
				<div
					className={`select-option-sobres txt20 bold cursor-pointer text-center txt-blue ${
						option.selected ? "selected" : ""
					}`}
					onClick={handleOptionClick}
					style={{ backgroundColor: backgroundColor }}
				>
					<div style={{ maxWidth: "60%" }}>{option.text}</div>
				</div>
			</div>
		);
	};

	const QUIZ_ID = QUIZZES.CONTRAINDICACIONES_ENTERAL;
	const SCENARIO_ID = SCENARIOS.SCENARIO_4;

	useEffect(() => {
		getAnswerByQuizAndGroup(QUIZ_ID, group?._id)
			.then((res) => {
				if (res.status === 200 && res.data.length > 0) {
					modalQuestionResolution({
						correct: false,
						allowOutsideClick: false,
						showCancelButton: false,
						showConfirmButton: true,
						customConfirmButtonHtml:
							QUESTION_ALREADY_ANSWERED_BUTTON,
						textHtml: QUESTION_ALREADY_ANSWERED_TEXT,
					}).then((res) => {
						if (res.isConfirmed) navigate(ROUTES.SCENARIO4_END);
					});
					setIsCompleted(true);
				}
			})
			.catch((error) => {});
	}, []);

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	async function submitAnswer(correct) {
		const score = correct ? 1 : 0;
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			group: group?._id,
			score: score,
			time: time,
		})
			.then((res) => {
				if (res.status === 201) {
					setIsCompleted(true);
				}
			})
			.catch((error) => {});
	}

	function checkAnswers() {
		let wrongOptions = options.filter(
			(x) => (x.selected && !x.correct) || (!x.selected && x.correct)
		);

		let correct = wrongOptions.length === 0;

		if (correct) {
			modalQuestionResolution({
				correct: correct,
				textHtml: CORRECT_ANSWER_TEXT,
				allowOutsideClick: true,
				customCancelButtonHtml: "<div>AVANZAR</div>",
			});
		} else {
			modalQuestionResolution({
				correct: correct,
				textHtml: getWrongAnswerText(wrongOptions.length),
				allowOutsideClick: true,
			});
		}

		if (correct) {
			setCheckedAnswers(true);
			submitAnswer(correct);
		}
	}

	return (
		<>
			<div className="h-100 bg-sobres">
				<div className="container-fluid px-5 pt-4 h-100">
					<div className="d-flex justify-content-center align-items-center">
						<img className="me-2" src={iconSeleccionar}></img>
						<div className="txt20 txt-blue">
							Marca{" "}
							<span className="bold">
								las contraindicaciones relativas de nutrición
								enteral:
							</span>
						</div>
					</div>
					<div className="row d-flex justify-content-center align-items-center px-5 pt-3">
						{options.map((option) => {
							return (
								<>
									<div className="col-4 my-2">
										<SelectOption
											id={option.id}
										></SelectOption>
									</div>
								</>
							);
						})}
						<div
							className="px-5 d-flex justify-content-end"
							style={{
								bottom: "9vh",
								position: "absolute",
							}}
						>
							{!isCompleted ? (
								<button
									className="btn-main bold"
									onClick={() => {
										checkAnswers();
									}}
								>
									<div className="text-btn-main">
										COMPROBAR
									</div>
								</button>
							) : (
								<NextButton
									navigateTo={ROUTES.SCENARIO4_END}
									activeScenario={activeScenario}
								></NextButton>
							)}
						</div>
					</div>
				</div>
				<ScenarioBar activeScenario={activeScenario} />
			</div>
		</>
	);
}

export default injectIntl(Scenario4Extra);
