import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import puntitos from "../../assets/puntitos.svg";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioNumByPath } from "../../utils/helpers";

function SupertestInstructions({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const activeScenario = getScenarioNumByPath(location.pathname);

	return (
		<>
			<div className="h-100 bg-enunciado-supertest">
				<div className="container-fluid px-5 pt-5 h-100">
					<div className="row">
						<div className="col-7 d-flex flex-column align-items-start">
							<div>
								<img src={puntitos}></img>
							</div>
							<div className="txt30 txt-purple bold line-height-40 mt-4">
								<p>
									Esta prueba consiste en{" "}
									<span className="txt-pink">
										3 preguntas de tipo test
									</span>{" "}
									sobre los conceptos clave que se han
									trabajado a lo largo de esta historia.
								</p>
								<br />
								<p>
									Cuando hayas respondido correctamente a las{" "}
									<span className="txt-pink">
										3 preguntas,
									</span>{" "}
									podrás entrar en el despacho de Carmen y
									hablar con ella.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div
						className="px-5"
						style={{
							position: "absolute",
							left: "0px",
							bottom: "20vh",
						}}
					>
						<NextButton
							navigateTo={ROUTES.SUPERTEST}
							activeScenario={activeScenario}
							classes={"mt-4"}
						></NextButton>
					</div>
				</div>
				<ScenarioBar activeScenario={activeScenario} />
			</div>
		</>
	);
}

export default injectIntl(SupertestInstructions);
