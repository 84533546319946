import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import flechaVerde from "../../assets/flecha-verde.svg";
import paco from "../../assets/paco.png";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioNumByPath } from "../../utils/helpers";

function Scenario1ExtraRecuerda({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const activeScenario = getScenarioNumByPath(location.pathname);

	return (
		<div className="h-100 bg-recuerda">
			<div className="container-fluid px-5 pt-3 h-100">
				<div
					className="container bg-globo-recuerda"
					style={{ height: "70%" }}
				>
					<div className="d-flex flex-column align-items-center ps-3 ms-1">
						<p className="txt30 txt-purple bold mt-4 pt-2 pe-4">
							Recuerda:
						</p>
						<div className="txt20 txt-blue mt-4">
							<div className="row d-flex align-items-start mt-4 pt-2">
								<div className="col-1 d-flex justify-content-end">
									<img src={flechaVerde}></img>
								</div>
								<div className="col-10">
									La albúmina{" "}
									<span className="bold">
										es un parámetro que desciende con la
										situación de inflamación
									</span>{" "}
									y no siempre se relaciona con la
									desnutrición o se detecta alteración en
									estados muy avanzados de la desnutrición
									<sup>3</sup>.
								</div>
							</div>
							<div className="row d-flex align-items-start mt-3">
								<div className="col-1 d-flex justify-content-end">
									<img src={flechaVerde}></img>
								</div>
								<div className="col-10">
									El{" "}
									<span className="bold">
										3 % de la población general sufre
										disfagia{" "}
									</span>
									(no el 10 %, como decía el enunciado)
									<sup>2</sup>.
								</div>
							</div>
							<div className="row d-flex align-items-start mt-3">
								<div className="col-1 d-flex justify-content-end">
									<img src={flechaVerde}></img>
								</div>
								<div className="col-10">
									El{" "}
									<span className="bold">estudio EFFORT</span>{" "}
									demostró que, con los pacientes ingresados
									en los que se diagnosticó desnutrición y se
									realizó un{" "}
									<span className="bold">
										tratamiento nutricional personalizado
									</span>
									, se obtuvo un ahorro de 214 francos suizos
									(199 euros) por paciente. Además, mostraron
									menor mortalidad, pero también menos
									complicaciones<sup>4,6</sup>.
								</div>
							</div>
						</div>
					</div>
					<div
						style={{
							position: "absolute",
							bottom: "1%",
							left: "3%",
							right: "0px",
						}}
					>
						<div className="d-flex justify-content-between align-items-center px-5">
							<img src={paco} width={"14%"}></img>
							<div>
								<NextButton
									navigateTo={ROUTES.TRANSITION_SCENARIOS}
									activeScenario={2}
								></NextButton>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar activeScenario={activeScenario}></ScenarioBar>
		</div>
	);
}

export default injectIntl(Scenario1ExtraRecuerda);
