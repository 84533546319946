import { baseClient, API } from "../index";

export const getDefaultGame = async () => {
	return baseClient().get(`${API}/game/default/`);
};

export const getGameById = async (id) => {
	return baseClient().get(`${API}/game/${id}`);
};

export const getAvailableTableByGameId = async (id) => {
	return baseClient().get(`${API}/game/${id}/available-table`);
};
