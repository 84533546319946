import React from "react";
import numero1Lila from "../assets/num-1-supertest-lila.svg";
import numero2Lila from "../assets/num-2-supertest-lila.svg";
import numero3Lila from "../assets/num-3-supertest-lila.svg";
import num1 from "../assets/num-1-supertest.svg";
import num2 from "../assets/num-2-supertest.svg";
import num3 from "../assets/num-3-supertest.svg";
import dossierSupertest from "../assets/dossier-supertest.png";
import dossierSupertestOK from "../assets/dossier-supertest-ok.png";
import dossierSupertestKO from "../assets/dossier-supertest-ko.png";

function SupertestProgress({ activeQuestion, results }) {
	return (
		<div>
			<div className="d-flex align-items-center">
				<div className="d-flex flex-column align-items-center me-4 pe-2">
					<img
						src={
							activeQuestion > 1
								? dossierSupertestOK
								: results[0] === null
								? dossierSupertest
								: results[0]
								? dossierSupertestOK
								: dossierSupertestKO
						}
						width={40}
					></img>
					<img
						className="mt-2 pt-1"
						src={activeQuestion === 1 ? numero1Lila : num1}
						width={25}
					></img>
				</div>
				<div
					className="d-flex flex-column align-items-center me-4 pe-2"
					style={{ opacity: activeQuestion < 2 ? "0.3" : "" }}
				>
					<img
						src={
							activeQuestion > 2
								? dossierSupertestOK
								: results[1] === null
								? dossierSupertest
								: results[1]
								? dossierSupertestOK
								: dossierSupertestKO
						}
						width={40}
					></img>
					<img
						className="mt-2 pt-1"
						src={activeQuestion === 2 ? numero2Lila : num2}
						width={25}
					></img>
				</div>
				<div
					className="d-flex flex-column align-items-center"
					style={{ opacity: activeQuestion < 3 ? "0.3" : "" }}
				>
					<img
						src={
							activeQuestion > 3
								? dossierSupertestOK
								: results[2] === null
								? dossierSupertest
								: results[2]
								? dossierSupertestOK
								: dossierSupertestKO
						}
						width={40}
					></img>
					<img
						className="mt-2 pt-1"
						src={activeQuestion === 3 ? numero3Lila : num3}
						width={25}
					></img>
				</div>
			</div>
		</div>
	);
}
export default SupertestProgress;
