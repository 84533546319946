import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import candadoAbiertoBlanco from "../assets/candado-abierto-blanco.svg";
import candadoAbierto from "../assets/candado-abierto.svg";
import candadoCerrado from "../assets/candado-cerrado.svg";
import ScenarioBar from "../components/ScenarioBar";
import { ROUTES } from "../utils/constants";

function TransitionScenarios({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();
	const actualScenario = useSelector((state) => state?.group?.actualScenario);
	const activeScenario = prevState?.state?.activeScenario || actualScenario;

	const ScenarioItem = ({ title, scenarioNum }) => {
		const active = activeScenario === scenarioNum;
		const passed = activeScenario > scenarioNum;

		let navigateTo =
			scenarioNum === 1
				? ROUTES.SCENARIO1_INTRO
				: scenarioNum === 2
				? ROUTES.SCENARIO2_INTRO
				: scenarioNum === 3
				? ROUTES.SCENARIO3_INTRO
				: scenarioNum === 4
				? ROUTES.SCENARIO4_INTRO
				: scenarioNum === 5
				? ROUTES.SCENARIO5_INTRO
				: scenarioNum === 6
				? ROUTES.SUPERTEST_INTRO
				: "";

		return (
			<div
				className={`oval-scenario-container ${
					active ? "active" : passed ? "passed" : ""
				}`}
				onClick={() => {
					if (active) navigate(navigateTo);
				}}
			>
				<div
					className={`oval-scenario ${
						active ? "active" : passed ? "passed" : ""
					}`}
				>
					<div
						className={`position-relative oval-scenario-inner ${
							active ? "active" : passed ? "passed" : ""
						}`}
					>
						<div>{title}</div>
						<img
							className="position-absolute"
							style={{ bottom: "10%" }}
							src={
								active
									? candadoAbiertoBlanco
									: passed
									? candadoAbierto
									: candadoCerrado
							}
							width={active || passed ? 29 : 20}
						/>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="h-100 bg-donuts">
			<div className="container-fluid h-100 px-5 pt-5 txt24 bold">
				<div className="row d-flex justify-content-center align-items-center mt-3">
					<div className="col-4 d-flex justify-content-end">
						<ScenarioItem
							title={"Afirmaciones importantes"}
							scenarioNum={1}
						></ScenarioItem>
					</div>
					<div className="col-4 d-flex justify-content-center">
						<ScenarioItem
							title={"Valoración morfofuncional. Nuevas técnicas"}
							scenarioNum={2}
						></ScenarioItem>
					</div>
					<div className="col-4 d-flex justify-content-start">
						<ScenarioItem
							title={"Dietoterapia y suplementación oral"}
							scenarioNum={3}
						></ScenarioItem>
					</div>
				</div>
				<div className="row d-flex justify-content-center align-items-center mt-5">
					<div className="col-4 d-flex justify-content-end">
						<ScenarioItem
							title={"Nutrición enteral y parenteral"}
							scenarioNum={4}
						></ScenarioItem>
					</div>
					<div className="col-4 d-flex justify-content-center">
						<ScenarioItem
							title={"Nutrición enteral domiciliaria"}
							scenarioNum={5}
						></ScenarioItem>
					</div>
					<div className="col-4 d-flex justify-content-start">
						<ScenarioItem
							title={"Supertest"}
							scenarioNum={6}
						></ScenarioItem>
					</div>
				</div>
			</div>
			<ScenarioBar activeScenario={activeScenario} />
		</div>
	);
}

export default injectIntl(TransitionScenarios);
