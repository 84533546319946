import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SERVER_URL } from "../api";
import { getGroupById } from "../api/group";
import logoNutricia from "../assets/nutricia.svg";
import profileContainer from "../assets/results/circulo-perfil.svg";
import selloGris from "../assets/sello-inactive.svg";
import selloRojo from "../assets/sello-rojo.svg";
import selloVerde from "../assets/sello-verde.svg";
import logoGebro from "../assets/vivactis.png";
import { setGroup } from "../redux/actions";
import { useLang } from "../translations/i18n";
import { ROUTES } from "../utils/constants";
import { updateStats } from "../utils/helpers";

function Results({ intl }) {
	const lang = useLang();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [img, setImg] = useState(null);
	const [group, setCurrentGroup] = useState(
		useSelector((state) => state.group)
	);

	const [time, setTime] = useState(group?.time || 0);
	const [groupName, setGroupName] = useState(null);
	const [dataLoaded, setDataLoaded] = useState(false);

	const [stampProgress, setStampProgress] = useState([
		false,
		false,
		false,
		false,
		false,
	]);

	useEffect(() => {
		if (group) {
			getGroupById(group?._id)
				.then((res) => {
					if (res.status === 200) {
						let group = res.data;
						setCurrentGroup(group);
						setGroupName(group?.fullName);
						setImg(`${SERVER_URL}/${group?.image}`);

						let time = group?.time;
						setTime(time);

						let stamp1 = group?.stamp1;
						let stamp2 = group?.stamp2;
						let stamp3 = group?.stamp3;
						let stamp4 = group?.stamp4;
						let stamp5 = group?.stamp5;

						let stamps = [stamp1, stamp2, stamp3, stamp4, stamp5];

						setStampProgress(stamps);

						setDataLoaded(true);
					}
				})
				.catch((error) => {});

			dispatch(
				setGroup({
					...group,
					lastVisited: ROUTES.RESULTS,
					finished: true,
				})
			);
			updateStats({
				...group,
				lastVisited: ROUTES.RESULTS,
				finished: true,
			});
		}
	}, []);

	return (
		<div className="h-100">
			<div
				className="container-fluid bg-preguntas-3 pt-5 h-100"
				onClick={() => {}}
			>
				<div className="row text-center pt-3 d-flex justify-content-center">
					<h1 className="txt42 txt-blue bold">{`Resultado equipo ${
						group?.tableNum || "X"
					}`}</h1>
				</div>
				<div className="row text-center mt-4 pt-4 justify-content-center d-flex align-items-center">
					<div
						className="col-11 d-flex align-items-center"
						style={{ marginLeft: "12%" }}
					>
						<div className="d-flex flex-column align-items-center position-relative">
							<div className="teamfoto">
								<img
									src={group?.image ? img : ""}
									style={{
										width: group?.image ? "" : "0px",
									}}
								/>
							</div>
							<img src={profileContainer} alt="" />
							<div
								style={{
									position: "absolute",
									top: "110%",
									lineHeight: "36px",
								}}
								className="txt32 txt-blue"
							>
								{groupName || "Nombre equipo"}
							</div>
						</div>
						<div className="separator-line"></div>
						<div className="px-5 bg-results pt-5 pb-5">
							<div className="d-flex flex-column align-items-start mt-3">
								<div className="txt32 txt-blue bold">
									Tiempo de juego
								</div>
								<div className="d-flex align-items-center mt-3">
									<div className="timer-big bold">
										{Math.floor(time / 60) < 10
											? "0" + Math.floor(time / 60)
											: Math.floor(time / 60)}
									</div>
									<div className="title1 px-1 bold txt-black txt32">
										:
									</div>
									<div className="timer-big bold">
										{time % 60 < 10
											? "0" + (time % 60)
											: time % 60}
									</div>
								</div>
								<div className="txt32 txt-blue bold mt-5">
									Sellos obtenidos
								</div>
								<div className="d-flex align-items-center mt-3">
									{stampProgress.map((item) => {
										return (
											<img
												className="me-3"
												src={
													!dataLoaded
														? selloGris
														: item
														? selloVerde
														: selloRojo
												}
												width={70}
											></img>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="w-100 d-flex legalGebro position-absolute px-5 justify-content-between align-items-end mb-2">
				<div className="d-flex flex-column align-items-end">
					<div className="mb-2 txt12 txt-white">Organizado por:</div>
					<img
						src={logoGebro}
						width={150}
						alt="20 years Gebro Pharma"
					/>
				</div>
				<div className="d-flex flex-column align-items-center">
					<div className="txt16 txt-white text-center pt-4">
						Uso exclusivo del profesional sanitario.
					</div>
				</div>

				<div className="d-flex flex-column align-items-end">
					<div className="mb-2 txt12 txt-white">Patrocinado por:</div>
					<img src={logoNutricia} width={150} />
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Results);
