import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnswerByQuizAndGroup, postAnswer } from "../../api/answer";
import iconImportant from "../../assets/importante.svg";
import masInfoSmall from "../../assets/mas-info-pequeño.svg";
import masInfo from "../../assets/mas-info.svg";
import criteriosGlimPDF from "../../assets/pdf/criterios-glim.pdf";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateTime } from "../../redux/actions";
import {
	QUESTION_ALREADY_ANSWERED_BUTTON,
	QUESTION_ALREADY_ANSWERED_TEXT,
	QUIZZES,
	ROUTES,
	SCENARIOS,
} from "../../utils/constants";
import {
	getScenarioNumByPath,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import {
	modalConfirm,
	modalPDF,
	modalQuestionExplanation,
	modalQuestionResolution,
} from "../../utils/logger";

function Scenario1({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const prevState = useLocation();
	const group = useSelector((state) => state.group);

	const [selectedOption, setSelectedOption] = useState(null);

	const activeScenario = getScenarioNumByPath(location.pathname);

	const [time, setTime] = useState(0);
	const [isCompleted, setIsCompleted] = useState(false);

	const QUIZ_ID = QUIZZES.NUTRICION_CLINICA;
	const SCENARIO_ID = SCENARIOS.SCENARIO_1;

	const [options, setOptions] = useState([
		{
			id: 1,
			text: "Una cuarta parte de los pacientes ingresados se encuentran en riesgo de desnutrición.",
			answer: `<p>
						Más del 24 % de los pacientes ingresados están en riesgo
						de malnutrición<sup>1</sup>.
					</p>
					<p>
						El 9,6 % de los pacientes con buen estado nutricional al
						ingreso se desnutren durante la hospitalización<sup>1</sup>.
					</p>`,
			checked: false,
			necessary: true,
		},
		{
			id: 2,
			text: "Un tercio de los pacientes mayores de 65 años y más del 75 % de los pacientes mayores de 85 años pueden presentar algún grado de disfagia.",
			answer: `<p>El 3 % de la población general<sup>2</sup>.</p>
					<p>El 24-30,7 % de los pacientes hospitalizados<sup>2</sup>.</p>
					<p>
						El 30 % de los pacientes mayores de 65 años/el 82,4 % en
						mayores de 60 años<sup>2</sup>.
					</p>`,
			checked: false,
			necessary: true,
		},
		{
			id: 3,
			text: "La valoración de la ingesta realizada al paciente y a la familia es útil para el diagnóstico de la desnutrición.",
			answer: `<p>No. Es necesario realizar una valoración de la ingesta reglada por un profesional (médico, enfermera, dietista) para el adecuado diagnóstico de desnutrición<sup>3</sup>.</p>`,
			checked: false,
			adequate: true,
		},
		{
			id: 4,
			text: "El diagnóstico adecuado de desnutrición y la implantación de medidas nutricionales reducen los costes de hospitalización.",
			answer: `<p>El estudio EFFORT demostró que, con los pacientes ingresados en los que se diagnosticó desnutrición y se realizó un tratamiento nutricional personalizado, se obtuvo un ahorro de 214 francos suizos (199 euros) por paciente.</p><p>Además, mostraron menos complicaciones y menor mortalidad<sup>4</sup>.</p>`,
			checked: false,
			necessary: true,
		},
		{
			id: 5,
			text: "La báscula es el primer paso, pero no el único, para el diagnóstico de la desnutrición relacionada con la enfermedad.",
			answer: `<p>El peso (y el índice de masa corporal, estrechamente relacionado con este) es uno de los parámetros para el diagnóstico de desnutrición relacionada con la enfermedad.</p><p>Valores normales o elevados de este parámetro no excluyen el diagnóstico de desnutrición. A veces son necesarias pruebas más específicas<sup>5</sup>.</p>`,
			checked: false,
			necessary: true,
		},
		{
			id: 6,
			text: "La determinación de la albúmina es básica e imprescindible para el diagnóstico de desnutrición.",
			answer: `<p>La albúmina es un parámetro que desciende con la situación de inflamación y no siempre se relaciona con la desnutrición o se detecta alteración en estados muy avanzados de la desnutrición<sup>3</sup>.</p>`,
			checked: false,
			innecessary: true,
		},
		{
			id: 7,
			text: "Podemos realizar el diagnóstico de la desnutrición solo con el peso y la talla.",
			answer: `<p>El adecuado diagnóstico de desnutrición requiere de parámetros antropométricos clásicos y técnicas más específicas manejadas por profesionales concretos<sup>3</sup>.</p>`,
			checked: false,
			adequate: true,
		},
		{
			id: 8,
			text: "El mejor tratamiento de la desnutrición son las dietas prefijadas o “dietas de cajón”.",
			answer: `<p>El manejo de la desnutrición se basa en un adecuado diagnóstico de esta y un tratamiento dietético y nutricional personalizado ajustado a la caracterización diagnóstica<sup>6</sup>.</p>`,
			checked: false,
			innecessary: true,
		},
		{
			id: 9,
			text: "El diagnóstico de desnutrición relacionada con la enfermedad se realiza solo al inicio del proceso que lo desencadena.",
			answer: `<p>La adecuada evaluación de la desnutrición relacionada con la enfermedad se basa en el cribado, diagnóstico y monitorización tanto del paciente con desnutrición como del paciente con una enfermedad crónica, en cuya evolución se puede desencadenar este proceso<sup>7</sup>.</p>`,
			checked: false,
			adequate: true,
		},
		{
			id: 10,
			text: "El diagnóstico y tratamiento médico nutricional puede realizarlo cualquier profesional, pero puede precisar de especialistas en nutrición clínica en los casos más complejos.",
			answer: `<p>El cribado de desnutrición puede ser realizado por cualquier profesional.</p><p>El diagnóstico de desnutrición, sobre todo en estadios preclínicos o con técnicas específicas, debe realizarse por un equipo especializado<sup>8</sup>.</p>`,
			checked: false,
			necessary: true,
		},
	]);

	const PERFECT_ANSWER_TEXT = `<div>
			<div class="txt22 txt-green bold">
				¡Has seleccionado correctamente las 5 afirmaciones fundamentales!
			</div>
		</div>`;

	const CORRECT_SELECTION_TEXT = `<div>
			<div class="txt22 txt-green bold">
				Has seleccionado una afirmación fundamental. ¡Enhorabuena!
			</div>
		</div>`;

	const GOOD_ANSWER_TEXT = `<div>
		<div class="txt22 txt-green bold">
			Ya has seleccionado correctamente las 5 afirmaciones fundamentales
		</div>
	</div>`;

	const RECUERDA_SELECCIONAR_TEXT = `<div>
		<div class="txt22 txt-red bold">
			Recuerda seleccionar una afirmación
		</div>
	</div>`;

	const INTERMEDIATE_ANSWER_TEXT = `<div>
			<div class="txt22 txt-orange bold">
				Has seleccionado una de las 3 afirmaciones adecuadas pero recuerda que debes conseguir las 5 fundamentales.
			</div>
			<br />
			<p class="txt18">
				${canAchieveStamp() ? "Aún puedes conseguir el sello." : ""}
			</p>
			<p class="txt18 bold">
				¡Adelante!
			</p>
		</div>`;

	const WRONG_ANSWER_TEXT = `<div>
			<div class="txt22 txt-red bold">
				Has seleccionado una de las 2 afirmaciones innecesarias
			</div>
			<br />
			<div class="txt18">
			Ya no puedes conseguir el sello, pero continúa con la prueba. Podrás avanzar cuando hayas seleccionado las 5 afirmaciones imprescindibles.
			</div>
			<br />
			<div class="txt18 bold">Recuerda que es importante leer las respuestas. Quizá esa información te haga falta más adelante…</div>
		</div>`;

	useEffect(() => {
		getAnswerByQuizAndGroup(QUIZ_ID, group?._id)
			.then((res) => {
				if (res.status === 200 && res.data.length > 0) {
					modalQuestionResolution({
						correct: false,
						allowOutsideClick: false,
						showCancelButton: false,
						showConfirmButton: true,
						customConfirmButtonHtml:
							QUESTION_ALREADY_ANSWERED_BUTTON,
						textHtml: QUESTION_ALREADY_ANSWERED_TEXT,
					}).then((res) => {
						if (res.isConfirmed)
							navigate(ROUTES.SCENARIO1_EXTRA_INTRO);
					});
					setIsCompleted(true);
				}
			})
			.catch((error) => {});
	}, []);

	function canAchieveStamp() {
		let canAchieveStamp = !options.some((x) => x.checked && x.innecessary);

		return canAchieveStamp;
	}

	const SelectOption = ({ number }) => {
		let option = options.find((x) => x.id === number);

		const handleOptionClick = () => {
			if (option.checked || isCompleted) return;
			setSelectedOption(option);
		};

		return (
			<div style={{ height: "13vh" }}>
				<div
					className={`select-option-rectangle cursor-pointer ${
						option?.selected ? "selected" : ""
					}`}
					onClick={handleOptionClick}
				>
					<div
						className={`select-option-number-container ${
							option?.id === selectedOption?.id || option?.checked
								? "txt-white"
								: "txt-blue"
						} bold txt21`}
						style={{
							backgroundColor:
								option?.id === selectedOption?.id
									? "#1B3A94"
									: option?.checked && option?.necessary
									? "#87CA1B"
									: option?.checked && option?.adequate
									? "#EC7700"
									: option?.checked && option?.innecessary
									? "#FF3A3A"
									: "",
						}}
					>
						{number}.
					</div>
					<div className="ms-2 txt17" style={{ maxWidth: "81%" }}>
						{option?.text}
					</div>
					{option?.checked && (
						<div className="select-option-mas-info">
							<img
								src={masInfoSmall}
								alt="Más información"
								onClick={(e) => {
									e.stopPropagation();
									modalQuestionExplanation({
										question: number + ". " + option.text,
										answerHtml: option.answer,
									});
								}}
							/>
						</div>
					)}
				</div>
			</div>
		);
	};

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	async function submitAnswer(correct) {
		const score = correct ? 1 : 0;
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			group: group?._id,
			score: score,
			time: time,
		})
			.then((res) => {
				if (res.status === 201) {
					let time = getTimer();
					updateStats({
						...group,
						stamp1: correct,
						lastVisited: ROUTES.SCENARIO1_EXTRA_INTRO,
						time: time,
					});
					setIsCompleted(true);
				}
			})
			.catch((error) => {});
	}

	function checkAnswers() {
		let updatedOptions = options.map((option) => {
			return option.id === selectedOption?.id
				? {
						...option,
						checked: true,
				  }
				: option;
		});

		let allNecessaryOptionsChecked = updatedOptions
			.filter((x) => x.necessary)
			.every((x) => x.checked);
		let allInnecessaryOptionsUnchecked = updatedOptions
			.filter((x) => x.innecessary)
			.every((x) => !x.checked);

		let stampObtained =
			allNecessaryOptionsChecked && allInnecessaryOptionsUnchecked;

		if (stampObtained) {
			modalQuestionResolution({
				correct: true,
				textHtml: PERFECT_ANSWER_TEXT,
				showCancelButton: true,
				customCancelButtonHtml: `<img class="me-2" src=${masInfoSmall}></img>AMPLIAR INFORMACIÓN</div>`,
				cancelFitContent: true,
			}).then((res) => {
				modalQuestionExplanation({
					question: selectedOption?.id + ". " + selectedOption?.text,
					answerHtml: selectedOption?.answer,
					allowOutsideClick: false,
					showCloseButton: false,
					showConfirmButton: true,
				}).then((res) => {
					modalPDF({ pdf: criteriosGlimPDF });
				});
			});
		} else if (allNecessaryOptionsChecked)
			modalQuestionResolution({
				correct: true,
				textHtml: GOOD_ANSWER_TEXT,
				customCancelButtonHtml: `<img class="me-2" src=${masInfoSmall}></img>AMPLIAR INFORMACIÓN</div>`,
				cancelFitContent: true,
			}).then((res) => {
				modalQuestionExplanation({
					question: selectedOption?.id + ". " + selectedOption?.text,
					answerHtml: selectedOption?.answer,
					allowOutsideClick: false,
					showCloseButton: false,
					showConfirmButton: true,
				}).then((res) => {
					modalPDF({ pdf: criteriosGlimPDF });
				});
			});
		else if (selectedOption?.necessary)
			modalQuestionResolution({
				correct: true,
				textHtml: CORRECT_SELECTION_TEXT,
				showCancelButton: true,
				customCancelButtonHtml: `<img class="me-2" src=${masInfoSmall}></img>AMPLIAR INFORMACIÓN</div>`,
				cancelFitContent: true,
			}).then((res) => {
				modalQuestionExplanation({
					question: selectedOption?.id + ". " + selectedOption?.text,
					answerHtml: selectedOption?.answer,
					showCloseButton: true,
				});
			});
		else if (selectedOption?.innecessary)
			modalQuestionResolution({
				correct: false,
				textHtml: WRONG_ANSWER_TEXT,
				customCancelButtonHtml: `<img class="me-2" src=${masInfoSmall}></img>AMPLIAR INFORMACIÓN</div>`,
				cancelFitContent: true,
			}).then((res) => {
				modalQuestionExplanation({
					question: selectedOption?.id + ". " + selectedOption?.text,
					answerHtml: selectedOption?.answer,
				});
			});
		else if (selectedOption?.adequate)
			modalQuestionResolution({
				correct: null,
				textHtml: INTERMEDIATE_ANSWER_TEXT,
				showCancelButton: true,
				customCancelButtonHtml: `<img class="me-2" src=${masInfoSmall}></img>AMPLIAR INFORMACIÓN</div>`,
				cancelFitContent: true,
			}).then((res) => {
				modalQuestionExplanation({
					question: selectedOption?.id + ". " + selectedOption?.text,
					answerHtml: selectedOption?.answer,
				});
			});

		setSelectedOption(null);
		setOptions(updatedOptions);

		if (allNecessaryOptionsChecked) {
			if (stampObtained) submitAnswer(true);
			else submitAnswer(false);
		}
	}

	return (
		<>
			<div className="h-100 bg-blue-plain">
				<div className="container-fluid px-5 pt-4 h-100">
					<div className="d-flex align-items-center justify-content-center">
						<SelectOption number={1}></SelectOption>
						<div className="ms-3">
							<SelectOption number={6}></SelectOption>
						</div>
					</div>
					<div className="d-flex align-items-center justify-content-center mt-2">
						<SelectOption number={2}></SelectOption>
						<div className="ms-3">
							<SelectOption number={7}></SelectOption>
						</div>
					</div>
					<div className="d-flex align-items-center justify-content-center mt-2">
						<SelectOption number={3}></SelectOption>
						<div className="ms-3">
							<SelectOption number={8}></SelectOption>
						</div>
					</div>
					<div className="d-flex align-items-center justify-content-center mt-2">
						<SelectOption number={4}></SelectOption>
						<div className="ms-3">
							<SelectOption number={9}></SelectOption>
						</div>
					</div>
					<div className="d-flex align-items-center justify-content-center mt-2">
						<SelectOption number={5}></SelectOption>
						<div className="ms-3">
							<SelectOption number={10}></SelectOption>
						</div>
					</div>
					<div className="d-flex justify-content-between align-items-start mt-4">
						<div className="d-flex align-items-center">
							<img className="me-2" src={iconImportant}></img>
							<div className="txt16 txt-blue">
								Una vez comprobada tu afirmación, haz clic en
								<img className="mx-1" src={masInfo}></img>para
								ampliar información
							</div>
						</div>
						<div>
							{!isCompleted ? (
								<button
									className="btn-main bold"
									onClick={() => {
										if (!selectedOption) {
											modalQuestionResolution({
												correct: false,
												textHtml:
													RECUERDA_SELECCIONAR_TEXT,
												showGLIMCriteria: false,
												allowOutsideClick: true,
												close: true,
											});
											return;
										}
										modalConfirm().then((res) => {
											if (res.isConfirmed) {
												checkAnswers();
											}
										});
									}}
								>
									<div className="text-btn-main">
										COMPROBAR
									</div>
								</button>
							) : (
								<NextButton
									activeScenario={activeScenario}
									navigateTo={ROUTES.STAMP_PROGRESS}
									navigateToAfter={
										ROUTES.SCENARIO1_EXTRA_INTRO
									}
								></NextButton>
							)}
						</div>
					</div>
				</div>
				<ScenarioBar activeScenario={activeScenario} />
			</div>
		</>
	);
}

export default injectIntl(Scenario1);
