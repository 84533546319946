import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnswerByQuizAndGroup, postAnswer } from "../../api/answer";
import iconEye from "../../assets/eye.svg";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateTime } from "../../redux/actions";
import {
	QUESTION_ALREADY_ANSWERED_BUTTON,
	QUESTION_ALREADY_ANSWERED_TEXT,
	QUIZZES,
	ROUTES,
	SCENARIOS,
} from "../../utils/constants";
import { getScenarioNumByPath, getTimer } from "../../utils/helpers";
import { modalQuestionResolution } from "../../utils/logger";

function Scenario2Extra({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const group = useSelector((state) => state.group);

	const activeScenario = getScenarioNumByPath(location.pathname);

	const SCENARIO_ID = SCENARIOS.SCENARIO_2;

	const activeQuestionNumber =
		location.pathname === ROUTES.SCENARIO2_EXTRA
			? 0
			: location.pathname === ROUTES.SCENARIO2_EXTRA_2
			? 1
			: 2;

	const QUIZ_ID =
		location.pathname === ROUTES.SCENARIO2_EXTRA
			? QUIZZES.ECOGRAFIA_NUTRICIONAL
			: location.pathname === ROUTES.SCENARIO2_EXTRA_2
			? QUIZZES.DINAMOMETRIA_MANO
			: QUIZZES.BIOIMP_ELECTRICA;

	const [checkedAnswers, setCheckedAnswers] = useState(null);

	const [isCompleted, setIsCompleted] = useState(null);
	const [time, setTime] = useState(0);

	const FINAL_GOOD_ANSWER_TEXT = `<div>
			<div class="txt22 txt-green bold">¡Perfecto!</div>
			<br />
			<p class="txt18">Envías las respuestas a <i>TastyMed</i> confiando en que te 
			tocará la «experiencia culinaria saludable e inolvidable».</p>
			<p class="txt18 bold">¡Ahora ya puedes seguir con tu misión!</p>
		</div>`;

	const GOOD_ANSWER_TEXT = `<div>
			<div class="txt22 txt-green bold">¡Correcto!</div>
			<br />
			<p class="txt18">¡Ya puedes seguir con tu misión!</p>
		</div>`;

	const WRONG_ANSWER_TEXT = `<div>
		<div class="txt22 txt-red bold">¡Incorrecto!</div>
		<br />
		<p class="txt18">¡Así no podrás ganar el concurso!
		</p>
		<p class="txt18">Inténtalo de nuevo.</p>
	</div>`;

	const RECUERDA_SELECCIONAR_TEXT = `<div>
		<div class="txt22 txt-red bold">
			Recuerda contestar la pregunta
		</div>
	</div>`;

	const CONTENT = [
		{
			title: "1. La ecografía nutricional permite:",
			options: [
				{
					id: 1,
					text: "Evaluar la calidad muscular",
					selected: false,
					correct: false,
				},
				{
					id: 2,
					text: "Evaluar la masa muscular",
					selected: false,
					correct: false,
				},
				{
					id: 3,
					text: "Evaluar la masa y la calidad muscular",
					selected: false,
					correct: true,
				},
			],
		},
		{
			title: "2. La dinamometría de mano permite:",
			options: [
				{
					id: 1,
					text: "Evaluar la masa muscular",
					selected: false,
					correct: false,
				},
				{
					id: 2,
					text: "Evaluar la capacidad funcional",
					selected: false,
					correct: false,
				},
				{
					id: 3,
					text: "Evaluar la fuerza muscular",
					selected: false,
					correct: true,
				},
			],
		},
		{
			title: "3. La determinación básica en la bioimpedancia eléctrica es:",
			options: [
				{
					id: 1,
					text: "La masa muscular",
					selected: false,
					correct: false,
				},
				{
					id: 2,
					text: "La hidratación corporal",
					selected: false,
					correct: true,
				},
				{
					id: 3,
					text: "La masa grasa",
					selected: false,
					correct: false,
				},
			],
		},
	];

	const [options, setOptions] = useState(CONTENT);

	useEffect(() => {
		getAnswerByQuizAndGroup(QUIZ_ID, group?._id)
			.then((res) => {
				if (res.status === 200 && res.data.length > 0) {
					modalQuestionResolution({
						correct: false,
						allowOutsideClick: false,
						showCancelButton: false,
						showConfirmButton: true,
						customConfirmButtonHtml:
							QUESTION_ALREADY_ANSWERED_BUTTON,
						textHtml: QUESTION_ALREADY_ANSWERED_TEXT,
					}).then((res) => {
						if (res.isConfirmed) {
							setIsCompleted(false);
							navigate(
								activeQuestionNumber === 0
									? ROUTES.SCENARIO2_EXTRA_2
									: activeQuestionNumber === 1
									? ROUTES.SCENARIO2_EXTRA_3
									: ROUTES.SCENARIO2_EXTRA_SOLUCION
							);
						}
					});
				}
			})
			.catch((error) => {});
	}, [location]);

	const SelectOption = ({ optionId }) => {
		let option = options[activeQuestionNumber].options.find(
			(x) => x.id === optionId
		);

		const handleOptionClick = () => {
			if (isCompleted) return;
			setCheckedAnswers(false);

			const updatedOptions = options.map((item, index) => {
				if (index === activeQuestionNumber) {
					const updatedItem = {
						...item,
						options: item.options.map((x) => ({
							...x,
							selected: x.id === option.id ? true : false,
						})),
					};
					return updatedItem;
				}
				return item;
			});

			setOptions(updatedOptions);
		};

		return (
			<div style={{ height: "10vh" }}>
				<div
					className={`select-option-rectangle ${
						!isCompleted ? "cursor-pointer" : ""
					} txt-blue bold ${option?.selected ? "selected" : ""}`}
					style={{ width: "40vw" }}
					onClick={handleOptionClick}
				>
					<div
						className={`select-option-number-container ${
							option?.selected ? "txt-white" : "txt-blue"
						} bold txt21`}
						style={{
							backgroundColor:
								option?.selected &&
								option?.correct &&
								checkedAnswers
									? "#87CA1B"
									: option?.selected &&
									  !option?.correct &&
									  checkedAnswers
									? "#FF3A3A"
									: option?.selected
									? "#1B3A94"
									: "",
						}}
					>
						{String.fromCharCode(
							"a".charCodeAt(0) + option?.id - 1
						)}
						.
					</div>
					<div className="ms-3 txt21" style={{ maxWidth: "81%" }}>
						{option?.text}
					</div>
				</div>
			</div>
		);
	};

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	async function submitAnswer(correct) {
		const score = correct ? 1 : 0;
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			group: group?._id,
			score: score,
			time: time,
		})
			.then((res) => {
				if (res.status === 201) {
					setIsCompleted(true);
				}
			})
			.catch((error) => {});
	}

	function checkAnswers() {
		setCheckedAnswers(true);
		let correct = options[activeQuestionNumber].options.some(
			(x) => x.selected && x.correct
		);
		if (!options[activeQuestionNumber].options.some((x) => x.selected)) {
			modalQuestionResolution({
				correct: false,
				textHtml: RECUERDA_SELECCIONAR_TEXT,
				allowOutsideClick: true,
			});
			return;
		}
		modalQuestionResolution({
			correct: correct,
			textHtml:
				correct && activeQuestionNumber === CONTENT.length - 1
					? FINAL_GOOD_ANSWER_TEXT
					: correct
					? GOOD_ANSWER_TEXT
					: WRONG_ANSWER_TEXT,
			showCancelButton: true,
			customCancelButtonHtml: correct ? "AVANZAR" : null,
			allowOutsideClick: true,
		});

		if (correct) submitAnswer(correct);
	}

	const handleQuestionAdvance = () => {
		setTime(0);
		setCheckedAnswers(false);
		setIsCompleted(false);
	};

	return (
		<div className="h-100 bg-mult-choice">
			<div className="container-fluid px-5 pt-5 h-100">
				<div className="row d-flex align-items-center">
					<div className="col-12 d-flex justify-content-center">
						{activeQuestionNumber === 0 ? (
							<div className="txt20 txt-blue bold pb-4">
								Para participar, debes responder correctamente 3
								preguntas sucesivas de tipo test.
							</div>
						) : (
							<div>
								<br />
								<br />
							</div>
						)}
					</div>
				</div>
				<div>
					<div className="d-flex flex-column align-items-center pt-4">
						<div className="txt30 txt-blue bold">
							{options[activeQuestionNumber].title}
						</div>
						<div className="mt-4 pt-4">
							<SelectOption optionId={1}></SelectOption>
							<div className="mt-4">
								<SelectOption optionId={2}></SelectOption>
							</div>
							<div className="mt-4">
								<SelectOption optionId={3}></SelectOption>
							</div>
						</div>
					</div>
				</div>
				<div
					className="d-flex justify-content-between align-items-end"
					style={{ position: "relative", top: "10%" }}
				>
					<div className="d-flex align-items-center">
						<img className="me-2" src={iconEye}></img>
						<div className="txt16 txt-blue bold">
							Visualiza la justificación al final de la prueba.
						</div>
					</div>
					{!isCompleted ? (
						<button
							className="btn-main"
							onClick={() => {
								checkAnswers();
							}}
						>
							<div className="text-btn-main">COMPROBAR</div>
						</button>
					) : (
						<div onClick={handleQuestionAdvance}>
							<NextButton
								navigateTo={
									activeQuestionNumber === 0
										? ROUTES.SCENARIO2_EXTRA_2
										: activeQuestionNumber === 1
										? ROUTES.SCENARIO2_EXTRA_3
										: ROUTES.SCENARIO2_EXTRA_SOLUCION
								}
								activeScenario={activeScenario}
							></NextButton>
						</div>
					)}
				</div>
			</div>
			<ScenarioBar activeScenario={activeScenario} />
		</div>
	);
}

export default injectIntl(Scenario2Extra);
