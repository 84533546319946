import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getGroupById } from "../api/group";
import logo from "../assets/logo-receta-exito.svg";
import selloGris from "../assets/sello-inactive.svg";
import selloRojo from "../assets/sello-rojo.svg";
import selloVerde from "../assets/sello-verde.svg";
import info from "../assets/top/info.svg";
import { useTimer } from "../hooks/useTimer";
import { updateTime } from "../redux/actions";
import { GAME_TIME_SECS, ROUTES } from "../utils/constants";
import {
	formatMinutes,
	formatSeconds,
	getTimer,
	updateStats,
	updateTimer,
} from "../utils/helpers";
import { modalIntro, modalText } from "../utils/logger";

export default function TopBar() {
	const timeout = GAME_TIME_SECS;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const game = useSelector((state) => state.game);
	const [group, setGroup] = useState(useSelector((state) => state.group));
	const [time, setTime] = useState(parseInt(group?.time));
	let isACTIVE = false;

	const [stampProgress, setStampProgress] = useState([
		null,
		null,
		null,
		null,
		null,
	]);

	function getSelloByStampNumber(number) {
		if (stampProgress.length === 0) return selloGris;
		let index = number - 1;
		if (stampProgress[index] === null) return selloGris;
		if (stampProgress[index]) return selloVerde;
		if (!stampProgress[index]) return selloRojo;
	}

	useTimer(() => {
		if (!isACTIVE && getTimer()) isACTIVE = true;
		if (isACTIVE) handleTimer();
	}, 1000);

	const handleTimer = () => {
		if (!getTimer()) isACTIVE = false;
		else {
			setTime(time + 1);
			updateTimer(time);
		}
	};

	useEffect(() => {
		if (
			time === timeout &&
			group.lastVisited !== ROUTES.GAMEOVER &&
			!game?.demo
		) {
			dispatch(updateTime(time));
			updateStats({
				...group,
				time: time,
				lastVisited: ROUTES.GAMEOVER,
				finished: true,
			});
			navigate(ROUTES.GAMEOVER);
		} else if (group?.finished) {
			navigate(group?.lastVisited || ROUTES.RESULTS);
		}
	}, [time]);

	useEffect(() => {
		if (group) {
			getGroupById(group?._id)
				.then((res) => {
					if (res.status === 200) {
						let group = res.data;
						let stamp1 = group?.stamp1;
						let stamp2 = group?.stamp2;
						let stamp3 = group?.stamp3;
						let stamp4 = group?.stamp4;
						let stamp5 = group?.stamp5;

						let stamps = [stamp1, stamp2, stamp3, stamp4, stamp5];

						setStampProgress(stamps);
					}
				})
				.catch((error) => {});
		}
	}, [location]);

	const REFERENCIAS = `<div>
			<div>
				<span class="txt12 pe-2 bold">1.</span>
				<span class="bold">
					Álvarez-Hernández J, Planas Vila M, León-Sanz M, García de
					Lorenzo A, Celaya-Pérez S, García-Lorda P, et al.
				</span>
				Prevalence and costs of malnutrition in hospitalized patients;
				the PREDyCES Study. Nutr Hosp. 2012;27(4):1049-59.
			</div>
			<div class="mt-3">
				<span class="txt12 pe-2 bold">2.</span>
				<span class="bold">
					Zugasti Murillo A, Gonzalo Montesinos I, Cancer Minchot E,
					Botella Romero F.
				</span>
				Hospital management of the patient with dysphagia. Survey and
				recommendations of SEEN nutrition area. Endocrinol Diabetes Nutr
				(English Ed). 2023;70(Suppl. 3):1-9.
			</div>
			<div class="mt-3">
				<span class="txt12 pe-2 bold">3.</span>
				<span class="bold">
					García-Almeida JM, García-García C, Ballesteros-Pomar MD,
					Olveira G, López-Gómez JJ, Bellido V, et al.
				</span>
				Expert consensus on morphofunctional assessment in
				disease-related malnutrition. Grade Review and Delphi Study.
				Nutrients. 2023;15(3):612.
			</div>
			<div class="mt-3">
				<span class="txt12 pe-2 bold">4.</span>
				<span class="bold">
					Schuetz P, Sulo S, Walzer S, Vollmer L, Stanga Z, Gomes F,
					et al.
				</span>
				Economic evaluation of individualized nutritional support in
				medical inpatients: Secondary analysis of the EFFORT trial. Clin
				Nutr. 2020;39(11):3361-8.
			</div>
			<div class="mt-3">
				<span class="txt12 pe-2 bold">5.</span>
				<span class="bold">
					García Almeida JM, García García C, Vegas Aguilar IM,
					Bellido Castañeda V, Bellido Guerrero D.
				</span>
				Morphofunctional assessment of patient’s nutritional status: a
				global approach. Nutr Hosp. 2021;38(3):592-600.
			</div>
			<div class="mt-3">
				<span class="txt12 pe-2 bold">6.</span>
				<span class="bold">
					Schuetz P, Fehr R, Baechli V, Geiser M, Deiss M, Gomes F, et
					al.
				</span>
				Individualized nutritional support in medical inpatients at
				nutritional risk: a randomized clinical trial. Lancet.
				2019;393(10188):2312-21.
			</div>
			<div class="mt-3">
				<span class="txt12 pe-2 bold">7.</span>
				<span class="bold">
					López Gómez JJ, López Ufano ML, Álvarez Hernández J.
				</span>
				Valoración morfofuncional del estado nutricional y vinculación
				con Atención Primaria. Nutr Hosp. 2022;38:19-25.
			</div>
			<div class="mt-3">
				<span class="txt12 pe-2 bold">8.</span>
				<span class="bold">
					Cederholm T, Jensen GL, Correia MITD, Gonzalez MC, Fukushima
					R, Higashiguchi t, et al.
				</span>
				GLIM criteria for the diagnosis of malnutrition - A consensus
				report from the global clinical nutrition community. Clin Nutr.
				2019;38(1):1-9.
			</div>
			<div class="mt-3">
				<span class="txt12 pe-2 bold">9.</span>
				<span class="bold">
					García-Almeida JM, García-García C, Vegas-Aguilar IM,
					Ballesteros-Pomar MD, Cornejo-Pareja IM, Fernández-Medina B,
					et al.
				</span>
				Nutritional Ultrasound®: Conceptualisation, technical
				considerations and standardisation. Endocrinol Diabetes Nutr
				(English Ed). 2023;70(Suppl. 1):74-84.
			</div>
			<div class="mt-3">
				<span class="txt12 pe-2 bold">10.</span>
				<span class="bold">
					Bellido D, García-García C, Talluri A, Lukaski HC,
					García-Almeida JM.
				</span>
				Futures lines of research on phase angle: Strengths and
				limitations. Rev Endocr Metab Disord. 2023;24(3):563-83.
			</div>
			<div class="mt-3">
				<span class="txt12 pe-2 bold">11.</span>
				<span class="bold">
					Del Olmo García MD, Ocón Bretón J, Álvarez J,
					Ballesteros-Pomar MD, Botella-Romero F, Bretón-Lesmes I, et
					al.
				</span>
				Términos, conceptos y definiciones en nutrición clínica
				artificial. Proyecto ConT-SEEN. Endocrinol Diabetes Nutr.
				2018;65(1):5-16.
			</div>
			<div class="mt-3">
				<span class="txt12 pe-2 bold">12.</span>
				<span class="bold">
					Weimann A, Braga M, Carli F, Higashiguchi T, Hübner M, Klek
					S, et al.
				</span>
				ESPEN guideline: Clinical nutrition in surgery. Clin Nutr.
				2021;40(7):4745-61.
			</div>
			<div class="mt-3">
				<span class="txt12 pe-2 bold">13.</span>
				<span class="bold">
					Arvanitakis M, Ockenga J, Bezmarevic M, Gianotti L, Krznaric
					Z, Lobo DN.
				</span>
				ESPEN guideline on clinical nutrition in acute and chronic
				pancreatitis. Clin Nutr. 2020;39(3):612-31.
			</div>
			<div class="mt-3">
				<span class="txt12 pe-2 bold">14.</span>
				<span class="bold">
					Deutz NE, Matheson EM, Matarese LE, Tappenden KA, Ziegler
					TR.
				</span>
				Readmission and mortality in malnourished, older, hospitalized
				adults treated with a specialized oral nutritional supplement: A
				randomized clinical Trial. Clin Nutr. 2016;35(1):18-26.
			</div>
			<div class="mt-3">
				<span class="txt12 pe-2 bold">15.</span>
				<span class="bold">
					Fiaccadori E, Sabatino A, Barazzoni R, Carrero JJ, Cupisti
					A, De Waele E, et al.
				</span>
				ESPEN guideline on clinical nutrition in hospitalized patients
				with acute or chronic kidney disease. Clin Nutr.
				2021;40(4):1644-68.
			</div>
			<div class="mt-3">
				<span class="txt12 pe-2 bold">16.</span>
				<span class="bold">
					López Gómez JJ, Torres Torres B, Gómez Hoyos E, Ortolá
					Buigues A, De Luis Román DA.
				</span>
				Nutrición Enteral: concepto, indicación, fórmulas, seguimiento y
				complicaciones. En: De Luis Román DA, Bellido Guerrero D, García
				Luna PP, Olveira Fuster G. Dietoterapia, nutrición clínica y
				metabolismo. Madrid: Aula Médica; 2018. pp. 733-52.
			</div>
			<div class="mt-3">
				<span class="txt12 pe-2 bold">17.</span>
				<span class="bold">
					Ministerio de Sanidad y Consumo. Secretaría General Técnica.
				</span>
				Guía de nutrición enteral domiciliaria en el Sistema Nacional de
				Salud. Madrid: Ministerio de Sanidad y Consumo, Centro de
				Publicaciones; 2008.
			</div>
			<div class="mt-3">
				<span class="txt12 pe-2 bold">18.</span>
				<span class="bold">
					Cuéllar Olmedo LA, Cordero Vaquero AA, De Luis Román DA.
				</span>
				Nutrición Enteral: vías de acceso. En: De Luis Román DA, Bellido
				Guerrero D, García Luna PP, Olveira Fuster G. Dietoterapia,
				nutrición clínica y metabolismo. Madrid: Aula Médica; 2018. pp.
				753-62.
			</div>
		</div>`;

	return (
		<div className="pt-2 bg-white">
			<div className="row d-flex justify-content-between align-items-center legalGebro px-4 pb-2">
				<div className="col-4 d-flex justify-content-start">
					<img src={logo} alt="La Receta del Exito" />
				</div>
				<div className="col-4 d-flex align-items-center justify-content-center">
					<div className="mx-2">
						<div>
							<img
								src={getSelloByStampNumber(1)}
								width={43}
								alt="sello"
							/>
						</div>
					</div>
					<div className="mx-2">
						<img
							src={getSelloByStampNumber(2)}
							width={43}
							alt="sello"
						/>
					</div>
					<div className="mx-2">
						<img
							src={getSelloByStampNumber(3)}
							width={43}
							alt="sello"
						/>
					</div>
					<div className="mx-2">
						<img
							src={getSelloByStampNumber(4)}
							width={43}
							alt="sello"
						/>
					</div>
					<div className="mx-2">
						<img
							src={getSelloByStampNumber(5)}
							width={43}
							alt="sello"
						/>
					</div>
				</div>
				<div className="col-4 d-flex align-items-center justify-content-end">
					<div
						className="roundBtn mx-2"
						onClick={() => {
							modalText({
								title: "REFERENCIAS BIBLIOGRÁFICAS",
								html: REFERENCIAS,
							});
						}}
					>
						B
					</div>
					<div
						className="roundBtn mx-2 d-flex align-items-center justify-content-center pb-1"
						onClick={() => {
							modalIntro();
						}}
					>
						<img src={info} alt="información" />
					</div>
					<div className="mx-2"></div>
					<div className="timer title1">
						{formatMinutes(time, timeout, group?.finished)}
					</div>
					<div className="title1 px-1 pt-1 blink bold">:</div>
					<div className="timer title1">
						{formatSeconds(time, timeout, group?.finished)}
					</div>
				</div>
			</div>
		</div>
	);
}
