import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import flechaAvanzar from "../assets/flecha-avanzar.svg";
import logoNutricia from "../assets/logo-nutricia.svg";
import logoVivactis from "../assets/logo-vivactis.png";
import { ROUTES } from "../utils/constants";
import { clearItem, getGroupTable } from "../utils/helpers";
import { alertResume, modalText } from "../utils/logger";

function Home({ intl }) {
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const gameId = searchParams.get("game");

	useEffect(() => {
		if (getGroupTable()) {
			if (gameId) {
				alertResume().then((res) => {
					if (res.isConfirmed) {
						navigate(ROUTES.REGISTER);
					} else {
						clearItem("groupTable");
						clearItem("total");
					}
				});
			} else {
				navigate(ROUTES.REGISTER);
			}
		} else {
			clearItem("total");
		}
	}, []);

	const AVISO_LEGAL = `<div class="mt-4">
			<p class="txt-blue bold">ACEPTACIÓN DEL USUARIO</p>
			<p>
				Este documento (a partir de aquí llamado "Condiciones de uso")
				tiene como objetivo regular el uso de la aplicación que
				EUROMEDICE, Ediciones Médicas, S.L. pone a disposición del
				usuario.
			</p>
			<p>
				Los derechos de propiedad intelectual de la aplicación, su
				código fuente, las bases de datos y los distintos elementos que
				contiene son propiedad de EUROMEDICE, Ediciones Médicas, S.L., a
				quien corresponde el ejercicio exclusivo de explotación de los
				derechos de la propia aplicación y, en especial, los de
				reproducción, distribución, comunicación pública, mantenimiento
				y transformación.
			</p>
			<p>
				La utilización de la aplicación por un tercero le atribuye la
				condición de usuario y supone la completa aceptación de este
				usuario de todas y cada una de las condiciones que establece
				esta Política de privacidad.
			</p>

			<p class="txt18 txt-blue bold">USO CORRECTO DE LA APLICACIÓN</p>
			<p>
				El usuario se compromete a utilizar la aplicación, los
				contenidos y los servicios de acuerdo con la Ley, esta Política
				de privacidad, las buenas prácticas y el orden público.
			</p>
			<p>
				De la misma manera, el usuario se obliga a no utilizar la
				aplicación o los servicios que se presten a través de ella con
				finalidades o efectos ilícitos o contrarios al contenido de esta
				Política de privacidad, que lesionen los intereses o derechos de
				terceros o que de cualquier forma puedan dañar, inutilizar o
				deteriorar la propia aplicación o sus servicios o impedir un uso
				satisfactorio de la aplicación a otros usuarios.
			</p>
			<p>
				Asimismo, el usuario se compromete expresamente a no destruir,
				alterar, inutilizar o, de cualquier otra forma, dañar los datos,
				los programas o los documentos electrónicos y otros que se
				encuentren en la presente aplicación.
			</p>
			<p>
				El usuario se compromete a no obstaculizar el acceso de otros
				usuarios al servicio mediante el consumo masivo de los recursos
				informáticos a través de los cuales EUROMEDICE, Ediciones
				Médicas, S.L. presta el servicio, así como a no realizar
				acciones que dañen, interrumpan o generen errores en los
				sistemas citados.
			</p>
			<p>
				El usuario se compromete a no introducir programas, virus,
				macroinstrucciones, miniaplicaciones, controles de cualquier
				tipo o cualquier otro dispositivo lógico o secuencia de
				caracteres que causen o puedan causar cualquier tipo de
				alteración en los sistemas informáticos de EUROMEDICE, Ediciones
				Médicas, S.L. o de terceros o, en su caso, violar los derechos
				de la propiedad intelectual o industrial.
			</p>
			<p>
				El usuario se compromete a no hacer un mal uso de las
				informaciones, mensajes, gráficos, dibujos, archivos de sonido
				y/o imagen, fotografías, programas y, en general, cualquier
				clase de material accesible a través de la presente aplicación o
				de los servicios que ofrece.
			</p>

			<p>
				Por consiguiente, EUROMEDICE, Ediciones Médicas, S.L. declina
				igualmente toda responsabilidad derivada de los daños y
				perjuicios que pueda sufrir cualquier visitante de esta
				aplicación en sus recursos tecnológicos (informáticos o
				telemáticos) a consecuencia de la producción de cualquiera de
				las circunstancias o hechos mencionados en el párrafo anterior.
			</p>

			<p>
				De igual manera, EUROMEDICE, Ediciones Médicas, S.L. no
				garantiza que la aplicación y el servidor estén libres de virus
				y no se hace responsable de los daños causados por el acceso a
				la aplicación o por la imposibilidad de acceder.
			</p>

			<p>
				EUROMEDICE, Ediciones Médicas, S.L. no es responsable de la
				información que se puede obtener a través de enlaces a sistemas
				externos desde esta aplicación.
			</p>

			<p class="txt18 txt-blue bold">PROPIEDAD INTELECTUAL E INDUSTRIAL</p>
			<p>
				Esta aplicación y todos sus contenidos, incluidos los textos,
				los documentos, las fotografías, los dibujos, las
				representaciones gráficas, las bases de datos, los programas
				informáticos, así como los logotipos, las marcas, los nombres
				comerciales y otros signos distintivos son propiedad de
				EUROMEDICE, Ediciones Médicas, S.L. o de terceros que le han
				autorizado para su uso. Todos los derechos están reservados.
			</p>

			<p class="txt18 txt-blue bold">RÉGIMEN DE RESPONSABILIDAD</p>
			<p>
				Responsabilidad de EUROMEDICE, Ediciones Médicas, S.L. por la
				<span class="bold">utilización de la aplicación:</span>
			</p>
			<p>
				- El usuario es el único responsable de las infracciones en que
				pueda incurrir y de los perjuicios que pueda causar o causarse
				por la utilización de la presente aplicación. Por su parte,
				EUROMEDICE, Ediciones Médicas, S.L. está exonerada de cualquier
				responsabilidad que se pudiese derivar por las acciones del
				usuario.
			</p>
			<p>
				- El usuario será el único responsable para cualquier
				reclamación o acción legal, judicial o extrajudicial iniciada
				por terceras personas contra EUROMEDICE, Ediciones Médicas, S.L.
				basada en la utilización de la aplicación por el usuario. En
				este caso, el usuario asumirá todos los gastos, los costes y las
				indemnizaciones que se reclamen a EUROMEDICE, Ediciones Médicas,
				S.L. con motivo de reclamaciones o de acciones legales que le
				sean atribuidas.
			</p>

			<p>
				Responsabilidad de EUROMEDICE, Ediciones Médicas, S.L. por el
				<span class="bold">funcionamiento de la aplicación:</span>
			</p>
			<p>
				- EUROMEDICE, Ediciones Médicas, S.L. declina cualquier
				responsabilidad que se pudiese derivar por transferencias,
				omisiones, interrupciones, intromisiones indeseables,
				deficiencias de telecomunicaciones, virus informáticos, averías
				telefónicas o desconexiones en el funcionamiento operativo del
				sistema electrónico, motivados por causas ajenas a EUROMEDICE,
				Ediciones Médicas, S.L.
			</p>

			<p>
				Responsabilidad de EUROMEDICE, Ediciones Médicas, S.L. por
				<span class="bold">enlaces desde la aplicación:</span>
			</p>
			<p>
				- EUROMEDICE, Ediciones Médicas, S.L. declina toda
				responsabilidad respecto a la información (contenidos)
				(perjuicios técnicos, morales o económicos) que haya fuera de la
				aplicación y a la que se pueda acceder mediante enlace desde
				esta, ya que la función de los enlaces que aparecen es
				únicamente informar sobre un tema concreto.
			</p>

			<p class="txt18 txt-blue bold">
				COMPROMISO DE PROTECCIÓN DE DATOS Y DE CUMPLIMIENTO DE LA LSSICE
			</p>
			<p>
				En cumplimiento del RGPD (Reglamento General de Protección de
				datos), EUROMEDICE, Ediciones Médicas, S.L. informa a los
				usuarios de que los datos de carácter personal que proporcionen
				al emplear los formularios o requerimientos de contrato se
				recogerán en ficheros responsabilidad de EUROMEDICE, Ediciones
				Médicas, S.L. y serán tratados con la finalidad de gestionar la
				inscripción y el desarrollo en el programa funcional de la
				aplicación y permitir poder prestar e informar sobre los
				servicios o sobre los productos que EUROMEDICE, Ediciones
				Médicas, S.L. ofrece.
			</p>
			<p>
				El hecho de rellenar un formulario de esta aplicación implica
				que el usuario reconoce que la información y los datos
				personales que nos indica son suyos, exactos y ciertos.
			</p>
			<p>
				El usuario registrado conserva en todo momento la posibilidad de
				ejercitar sus derechos de acceso, rectificación, oposición,
				supresión, tratamiento limitado, portabilidad u oposición al
				tratamiento de sus datos. Asimismo, y de conformidad con la Ley
				34/2002, de 1 de julio, de servicios de la sociedad de la
				información y comercio electrónico, puede revocar en cualquier
				momento el consentimiento prestado a la recepción de
				comunicaciones comerciales.
			</p>
			<p>
				En caso de duda, así como para ejercitar los derechos
				mencionados, puede dirigirse a EUROMEDICE, Ediciones Médicas,
				S.L. a través del correo electrónico euromedice@euromedice.net o
				por correo postal a la dirección: Passeig de Gràcia, 101, 1.º
				3.ª, 08008, Barcelona.
			</p>

			<p class="txt18 txt-blue bold">
				LEGISLACIÓN APLICABLE Y COMPETENCIA JUDICIAL
			</p>
			<p>
				Cualquier controversia surgida en la interpretación o la
				ejecución de esta Política de privacidad se interpretará a
				partir de la legislación española.
			</p>
			<p>
				Asimismo, EUROMEDICE, Ediciones Médicas, S.L., a través de su
				representación legal, y el usuario renuncian a cualquier otra
				jurisdicción y se someten a la de los juzgados y tribunales del
				domicilio del usuario para cualquier controversia que pudiese
				suceder. En el caso de que el usuario tenga su domicilio fuera
				de España, EUROMEDICE, Ediciones Médicas, S.L. y el usuario se
				someten a los juzgados y tribunales de la ciudad de Barcelona.
			</p>
		</div>`;
	const POLITICA_PRIVACIDAD = `<div class="mt-4">
			<p>
				El responsable del tratamiento de los datos recabados por medio
				de esta aplicación es EUROMEDICE, Ediciones Médicas, S.L., con
				NIF B61655825 y domicilio en Passeig de Gràcia, 101 1º 3ª 08008
				Barcelona (en adelante, “Euromedice”), provisto del teléfono
				93.497.07.30 y de la dirección de correo electrónico
				euromedice@euromedice.net.
			</p>
			<p>
				Le informamos de que los datos personales que nos proporcione a
				través de esta aplicación o que recopilemos serán tratados para
				las siguientes finalidades (o alguna de ellas, en función del
				formulario de recogida de datos que haya aceptado):
			</p>
			<p class="ms-4">
				1. Atender las solicitudes de información y/o consultas
				efectuadas por el usuario a través de la aplicación. Los datos
				tratados con esta finalidad se conservarán por parte de
				Euromedice hasta haber dado respuesta a la solicitud de
				información y/o consulta, tras lo cual serán conservados hasta
				haber transcurrido el máximo plazo de prescripción de las
				infracciones en materia de protección de datos personales. La
				base jurídica para llevar a caboeste tratamiento de datos es el
				consentimiento del usuario dado para que su solicitud y/o
				consulta sea atendida.
			</p>
			<p class="ms-4">
				2. Tramitar su inscripción y posibilitar la funcionabilidad de
				la aplicación. Los datos tratados con esta finalidad se
				conservarán hasta la total finalización de la funcionabilidad de
				la aplicación y, tras ello, durante los plazos de conservación y
				de prescripción de responsabilidades legalmente previstos.La
				base jurídica para llevar a cabo este tratamiento de datos es el
				consentimiento del usuario dado en el momento del registro en la
				aplicación.
			</p>
			<p class="ms-4">
				3. Mantener informado al usuario, incluso por medios
				electrónicos, acerca de servicios de Euromedice, siempre y
				cuando el usuario haya consentido de forma expresa el
				tratamiento de sus datos con esta finalidad, marcando la casilla
				correspondiente en el formulario de recogida de datos. Los datos
				tratados con esta finalidad se conservarán hasta el momento en
				que el usuario retire su consentimiento dado para la recepción
				de dichas comunicaciones. La base jurídica para llevar a cabo
				este tratamiento de datos es el consentimiento del usuario dado
				en el momento del registro en la aplicación.
			</p>
			<p>
				Sus datos personales no serán transmitidos para su uso por
				terceros, a menos que hayamos obtenido su consentimiento o
				cuando así lo exija la ley.
			</p>
			<p>
				No obstante lo anterior, Euromedice informa a los usuarios de la
				aplicación de que sus datos personales serán comunicados al
				patrocinador de la misma para las siguientes finalidades:
			</p>
			<p class="ms-4">
				1. Posibilitar y velar por el correcto desarrollo de la
				funcionabilidad de la aplicación. Los datos tratados con esta
				finalidad se conservarán hasta la total finalización de la
				funcionabilidad de la aplicación y, tras ello, durante los
				plazos de conservación y de prescripción de responsabilidades
				legalmente previstos. La base jurídica para llevar a cabo este
				tratamiento de datos es el consentimiento del usuario dado en el
				momento del registro en la aplicación.
			</p>
			<p class="ms-4">
				2. Mantener informado al usuario, incluso por medios
				electrónicos, acerca de los productos, servicios y novedades,
				temas de ámbito científico, profesional, sanitario o
				farmacéutico y próximos eventos, siempre y cuando usted haya
				consentido de forma expresa el tratamiento de sus datos con
				dicha finalidad, marcando la casilla correspondiente en el
				formulario de recogida de datos. Los datos tratados con esta
				finalidad se conservarán hasta el momento en que el usuario
				retire su consentimiento dado para la recepción de dichas
				comunicaciones. La base jurídica para llevar a cabo este
				tratamiento de datos es el consentimiento del usuario dado para
				la recepción de dichas comunicaciones comerciales.
			</p>
			<p class="bold">Derechos que asisten al usuario:</p>
			<p>
				El usuario puede ejercer ante Euromedice sus derechos de acceso,
				rectificación, supresión, limitación del tratamiento,
				portabilidad de los datos, oposición y a no ser objeto de
				decisiones individuales automatizadas, incluida la elaboración
				de perfiles. De igual modo, en los tratamientos de los datos del
				usuario cuya legitimación se base en el consentimiento dado por
				el usuario, este tiene el derecho a retirar dicho consentimiento
				en cualquier momento, sin que ello afecte a la licitud del
				tratamiento basado en el consentimiento previo a su retirada.
			</p>
			<p>
				Para el ejercicio de tales derechos, el usuario puede enviar su
				solicitud por escrito a:
			</p>
			<p>
				Euromedice, Ediciones Médicas, S.L. (A/A Dpto. Legal) Passeig de
				Gràcia, 101, 1.º 3.ª 08008 - Barcelona
			</p>
			<p>O a través del correo electrónico: euromedice@euromedice.net</p>
			<p>
				En todo caso, el usuario tiene derecho a presentar una
				reclamación ante la correspondiente autoridad de control si lo
				estima oportuno.
			</p>
		</div>`;

	return (
		<div className="startbg txtWhite">
			<div className="row h-100 d-flex justify-content-center align-items-center">
				<div
					className="col-4 d-flex justify-content-center"
					style={{ marginTop: "18vh" }}
				>
					<div
						className="btn-main"
						style={{ width: "141px" }}
						onClick={() => {
							navigate(ROUTES.REGISTER, {
								state: { game: gameId },
							});
						}}
					>
						<div className="text-btn-main">ENTRAR</div>
						<img className="ms-2" src={flechaAvanzar}></img>
					</div>
				</div>
			</div>
			<div
				className="px-5 pt-4 mt-3"
				style={{ position: "absolute", right: "0px", top: "0px" }}
			>
				<img src={logoVivactis} width={150}></img>
			</div>

			<div
				className="px-5 pt-4 mt-3"
				style={{
					position: "absolute",
					right: "0px",
					top: "24%",
					width: "30%",
					lineHeight: "20px",
				}}
			>
				<div className="text-left mt-5 pt-5">
					<div className="txt20 txt-blue">
						Actividad coordinada por:
					</div>
					<div className="txt18 bold txt-blue ms-2 mt-1">
						Dr. Juan José López Gómez
					</div>
					<div className="txt14 txt-blue ms-2 mt-1">
						Especialista en Endocrinología y Nutrición. Hospital
						Clínico Universitario de Valladolid.{" "}
					</div>
				</div>
			</div>

			<div className="legalGebro position-absolute w-100">
				<div className="d-flex justify-content-end align-items-end mb-4 pb-1 ms-5 ps-5 pe-5">
					<div className="d-flex justify-content-center align-items-center txtWhite me-5">
						<div
							className="btn-home mx-1 txt12 cursor-pointer"
							onClick={(event) => {
								event.stopPropagation();
								modalText({
									title: "AVISO LEGAL",
									html: AVISO_LEGAL,
								});
							}}
						>
							{"Aviso Legal"}
						</div>
						|
						<div
							className="btn-home mx-1 txt12 cursor-pointer"
							onClick={(event) => {
								event.stopPropagation();

								modalText({
									title: "POLÍTICA DE PRIVACIDAD",
									html: POLITICA_PRIVACIDAD,
								});
							}}
						>
							{"Política de Privacidad"}
						</div>
					</div>
					<img className="ms-5" src={logoNutricia}></img>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Home);
