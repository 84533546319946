import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import flecha from "../../assets/flecha-lila.svg";
import dibujoPlan from "../../assets/plan-nutricional.png";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioNumByPath } from "../../utils/helpers";

function Scenario5Explicacion({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const activeScenario = getScenarioNumByPath(location.pathname);

	return (
		<>
			<div className="h-100 bg-preguntas-3">
				<div className="container-fluid px-5 pt-4 h-100">
					<div className="row">
						<div className="col-8 txt30 txt-purple line-height-40">
							<p className="bold">
								La nutrición enteral domiciliaria es un
								tratamiento más, no exento de complicaciones.{" "}
							</p>
							<p className="txt-blue txt22">
								Es necesario tener:
							</p>
						</div>
					</div>
					<div className="row txt22 txt-blue">
						<div className="col-5 d-flex align-items-start">
							<img className="me-3" src={flecha}></img>
							<div>
								un{" "}
								<span className="bold">
									objetivo terapéutico
								</span>{" "}
								adecuado,
							</div>
						</div>
						<div className="col-6 d-flex align-items-start">
							<img className="me-3" src={flecha}></img>
							<div>
								una{" "}
								<span className="bold">
									pauta de tratamiento
								</span>{" "}
								estructurada,
							</div>
						</div>
					</div>
					<div className="row txt22 txt-blue mt-3">
						<div className="col-5 d-flex align-items-start">
							<img className="me-3" src={flecha}></img>
							<div>
								una{" "}
								<span className="bold">
									educación en el manejo
								</span>{" "}
								planificada tanto para el paciente como para los
								cuidadores,
							</div>
						</div>
						<div className="col-5 d-flex align-items-start">
							<img className="me-3" src={flecha}></img>
							<div>
								un{" "}
								<span className="bold">
									planteamiento de las posibles incidencias
								</span>{" "}
								y de <span className="bold">cómo actuar</span>{" "}
								ante ellas,
							</div>
						</div>
					</div>
					<div className="row txt22 txt-blue mt-3">
						<div className="col-5 d-flex align-items-start">
							<img className="me-3" src={flecha}></img>
							<div>
								un{" "}
								<span className="bold">
									planteamiento de posibilidad de fin de
									tratamiento.
								</span>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div
						className="px-5"
						style={{
							position: "absolute",
							left: "0px",
							bottom: "10%",
							zIndex: "10",
						}}
					></div>
				</div>
				<div className="bg-purple px-5 pt-4">
					<div className="row d-flex align-items-end">
						<div className="col-7 d-flex flex-column align-items-start">
							<p className="txt22 txt-white line-height-30">
								Para ello, son necesarios{" "}
								<span className="bold">
									profesionales sensibilizados y liderados por
									Unidades de Nutrición Clínica
								</span>{" "}
								de soporte formadas por distintos colectivos
								sanitarios (médicos y enfermeros)
								<sup>16-18</sup>.
							</p>
							<div style={{ zIndex: "9999" }}>
								<NextButton
									navigateTo={ROUTES.STAMP_PROGRESS}
									navigateToAfter={ROUTES.SCENARIO5_END}
									activeScenario={activeScenario}
								></NextButton>
							</div>
						</div>
						<div
							className="d-flex justify-content-end"
							style={{
								position: "absolute",
								right: "0px",
								bottom: "5%",
							}}
						>
							<img src={dibujoPlan} width={"40%"}></img>
						</div>
					</div>
				</div>
				<ScenarioBar activeScenario={activeScenario} />
			</div>
		</>
	);
}

export default injectIntl(Scenario5Explicacion);
