import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import puntitosWhite from "../../assets/puntitos-white.svg";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioNumByPath } from "../../utils/helpers";

function Scenario4End({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const activeScenario = getScenarioNumByPath(location.pathname);

	return (
		<div className="h-100 bg-moon">
			<div className="container-fluid px-5 pt-5 h-100">
				<div className="row d-flex flex-column align-items-start">
					<div className="mt-2">
						<img src={puntitosWhite}></img>
					</div>
					<p className="col-6 txt30 txt-white bold line-height-40 pt-4 mt-2">
						Respondes a todas las interconsultas y das por
						finalizada tu jornada. Quieres irte a dormir temprano
						porque{" "}
						<span className="txt-pink">
							mañana es el día en que debes conseguir el último
							sello
						</span>
						.
					</p>
				</div>
				<br />
				<p className="txt30 txt-white bold pt-4">
					¡No puedes dejarlo escapar!
				</p>
				<div>
					<div
						className="px-5"
						style={{
							position: "absolute",
							left: "0px",
							bottom: "15vh",
						}}
					>
						<NextButton
							navigateTo={ROUTES.TRANSITION_SCENARIOS}
							activeScenario={5}
							classes={"mt-4"}
						></NextButton>
					</div>
				</div>
			</div>
			<ScenarioBar activeScenario={activeScenario}></ScenarioBar>
		</div>
	);
}

export default injectIntl(Scenario4End);
