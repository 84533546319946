import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import flechaAvanzar from "../assets/flecha-avanzar.svg";
import { setGroup } from "../redux/actions";
import { useLang } from "../translations/i18n";
import { ROUTES } from "../utils/constants";
import { updateStats } from "../utils/helpers";

function Gameover({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);

	useEffect(() => {
		if (group) {
			dispatch(
				setGroup({
					...group,
					lastVisited: ROUTES.RESULTS,
					finished: true,
				})
			);
			updateStats({
				...group,
				lastVisited: ROUTES.RESULTS,
				finished: true,
			});
		}
	}, []);

	return (
		<div className="h-100">
			<div className="container-fluid bggameover h-100">
				<div className="row h-100 justify-content-center">
					<div
						className="d-flex align-items-center justify-content-center"
						style={{ position: "absolute", top: "73%" }}
					>
						<div className="text-center">
							<div className="title8B text-center text-white">
								<p className="bold txtWhite txt25">
									Lo sentimos, el tiempo de juego ha
									finalizado.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div
					className="px-5"
					style={{
						position: "absolute",
						bottom: "15%",
						right: "0",
					}}
				>
					<button
						onClick={() => navigate(ROUTES.RESULTS)}
						className="btn-main px-4 w-fit-content"
					>
						<div className="text-btn-main">VER RESULTADOS</div>
						<img className="ms-2" src={flechaAvanzar}></img>
					</button>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Gameover);
