import { updateGroup } from "../api/group";
import { ROUTES } from "./constants";

export function formatTime(time) {
	if (!time) return ` 0' 00"`;
	else
		return `${Math.floor(time / 60)}' ${
			time - Math.floor(time / 60) * 60
		}"`;
}

export function formatMinutes(time, timeout, finished) {
	if (time === undefined || isNaN(time) || finished) return "00";
	let remaining = (timeout - time) / 60;
	if (remaining <= 0) return "00";
	return (remaining < 10 ? "0" : "") + Math.floor(remaining);
}
export function formatSeconds(time, timeout, finished) {
	if (time === undefined || isNaN(time) || finished) return "00";
	let remaining = (timeout - time) % 60;
	if (remaining < 0) return "00";
	return (remaining < 10 ? "0" : "") + remaining;
}

export function updateTimer(value) {
	localStorage.setItem("total", value);
}

export function getTimer() {
	return localStorage.getItem("total");
}

export function clearItem(item) {
	localStorage.removeItem(item);
}

export function getGroupTable() {
	return localStorage.getItem("groupTable");
}

export function setGroupTable(groupTable) {
	localStorage.setItem("groupTable", groupTable);
}

export async function updateStats(group) {
	delete group.stamp1;
	delete group.stamp2;
	delete group.stamp3;
	delete group.stamp4;
	delete group.stamp5;
	delete group.timeAchieveStamps;

	await updateGroup(group?._id, group);
}

export function getScenarioNumByPath(path) {
	let scenario0Paths = [ROUTES.START_GAME];
	let scenario1Paths = [
		ROUTES.SCENARIO1,
		ROUTES.SCENARIO1_EXTRA,
		ROUTES.SCENARIO1_EXTRA_INTRO,
		ROUTES.SCENARIO1_EXTRA_RECUERDA,
		ROUTES.SCENARIO1_INSTRUCTIONS,
		ROUTES.SCENARIO1_INTRO,
	];

	let scenario2Paths = [
		ROUTES.SCENARIO2,
		ROUTES.SCENARIO2_EXTRA,
		ROUTES.SCENARIO2_EXTRA_2,
		ROUTES.SCENARIO2_EXTRA_3,
		ROUTES.SCENARIO2_EXTRA_INTRO,
		ROUTES.SCENARIO2_EXTRA_SOLUCION,
		ROUTES.SCENARIO2_INSTRUCTIONS,
		ROUTES.SCENARIO2_INTRO,
	];

	let scenario3Paths = [
		ROUTES.SCENARIO3,
		ROUTES.SCENARIO3_EXTRA,
		ROUTES.SCENARIO3_EXTRA_INSTRUCTIONS,
		ROUTES.SCENARIO3_INSTRUCTIONS,
		ROUTES.SCENARIO3_INSTRUCTIONS_2,
		ROUTES.SCENARIO3_INTRO,
	];

	let scenario4Paths = [
		ROUTES.SCENARIO4,
		ROUTES.SCENARIO4_END,
		ROUTES.SCENARIO4_EXTRA,
		ROUTES.SCENARIO4_EXTRA_INTRO,
		ROUTES.SCENARIO4_INSTRUCTIONS,
		ROUTES.SCENARIO4_INTRO,
	];

	let scenario5Paths = [
		ROUTES.SCENARIO5,
		ROUTES.SCENARIO5_EXPLICATION,
		ROUTES.SCENARIO5_INSTRUCTIONS,
		ROUTES.SCENARIO5_INTRO,
	];

	let supertestPaths = [
		ROUTES.SCENARIO5_END,
		ROUTES.SUPERTEST,
		ROUTES.SUPERTEST_2,
		ROUTES.SUPERTEST_3,
		ROUTES.SUPERTEST_INSTRUCTIONS,
		ROUTES.SUPERTEST_INTRO,
	];

	let allScenariosPaths = [ROUTES.FINAL_SCENE];

	if (scenario0Paths.includes(path)) return 0;
	else if (scenario1Paths.includes(path)) return 1;
	else if (scenario2Paths.includes(path)) return 2;
	else if (scenario3Paths.includes(path)) return 3;
	else if (scenario4Paths.includes(path)) return 4;
	else if (scenario5Paths.includes(path)) return 5;
	else if (supertestPaths.includes(path)) return 6;
	else if (allScenariosPaths.includes(path)) return 7;
}

export function getLocationAfterStampProgressScreen(stampNumber) {
	if (!stampNumber) return;
	if (stampNumber === 1) return ROUTES.SCENARIO1_EXTRA_INTRO;
	else if (stampNumber === 2) return ROUTES.SCENARIO2_EXTRA_INTRO;
	else if (stampNumber === 3) return ROUTES.TRANSITION_SCENARIOS;
	else if (stampNumber === 4) return ROUTES.SCENARIO4_EXTRA_INTRO;
	else if (stampNumber === 5) return ROUTES.SCENARIO5_END;
}
