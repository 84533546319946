import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnswerByQuizAndGroup, postAnswer } from "../../api/answer";
import donutGlobo from "../../assets/donut-globo.png";
import globo1 from "../../assets/globo1.svg";
import globo2 from "../../assets/globo2.svg";
import globo3 from "../../assets/globo3_2.svg";
import icoFalsoSelected from "../../assets/ico-falso-selected.png";
import icoFalso from "../../assets/ico-falso.png";
import icoVerdaderoSelected from "../../assets/ico-verdadero-selected.png";
import icoVerdadero from "../../assets/ico-verdadero.png";
import paco from "../../assets/paco.png";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateTime } from "../../redux/actions";
import {
	QUESTION_ALREADY_ANSWERED_BUTTON,
	QUESTION_ALREADY_ANSWERED_TEXT,
	QUIZZES,
	ROUTES,
	SCENARIOS,
} from "../../utils/constants";
import { getScenarioNumByPath, getTimer } from "../../utils/helpers";
import { modalQuestionResolution } from "../../utils/logger";

function Scenario1Extra({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const group = useSelector((state) => state.group);

	const activeScenario = getScenarioNumByPath(location.pathname);

	const [isCompleted, setIsCompleted] = useState(false);
	const [time, setTime] = useState(0);

	const QUIZ_ID = QUIZZES.AFIRMACIONES_VF;
	const SCENARIO_ID = SCENARIOS.SCENARIO_1;

	const GOOD_ANSWER_TEXT = `<div>
			<div class="txt22 txt-green bold">¡Correcto!</div>
			<br />
			<p class="txt18">Anselmo te agradece las aclaraciones y se va.</p>
			<p class="txt18 bold">
				¡Ya puedes ir en busca del siguiente sello!
			</p>
		</div>`;

	const RECUERDA_SELECCIONAR_TEXT = `<div>
		<div class="txt22 txt-red bold">
			Recuerda contestar todas las preguntas
		</div>
	</div>`;

	function getWrongAnswerText(numErrores) {
		return `<div>
		<div class="txt22 txt-red bold">¡Incorrecto!</div>
		<br />
		<p class="txt18">Hay <span class="txt-red bold">${numErrores} ${
			numErrores === 1 ? "error" : "errores"
		}</span></p>
		<p class="txt18">Anselmo no se irá hasta que le des las respuestas correctas. Inténtalo de nuevo.</p>
	</div>`;
	}

	const [options, setOptions] = useState([
		{
			id: 1,
			text: "Un paciente puede tener desnutrición grave y mantener valores normales de albúmina.",
			background: globo1,
			selected: null,
			correct: true,
		},
		{
			id: 2,
			text: "Una de cada 10 personas de la población general presenta disfagia.",
			background: globo2,
			selected: null,
			correct: false,
		},
		{
			id: 3,
			text: "El estudio EFFORT demostró que un tratamiento nutricional personalizado en pacientes ingresados con desnutrición diagnosticada resultó en menores costes por paciente y menor mortalidad, sin incidencia en la aparición de complicaciones.",
			background: globo3,
			selected: null,
			correct: false,
		},
	]);

	useEffect(() => {
		getAnswerByQuizAndGroup(QUIZ_ID, group?._id)
			.then((res) => {
				if (res.status === 200 && res.data.length > 0) {
					modalQuestionResolution({
						correct: false,
						allowOutsideClick: false,
						showCancelButton: false,
						showConfirmButton: true,
						customConfirmButtonHtml:
							QUESTION_ALREADY_ANSWERED_BUTTON,
						textHtml: QUESTION_ALREADY_ANSWERED_TEXT,
					}).then((res) => {
						if (res.isConfirmed)
							navigate(ROUTES.SCENARIO1_EXTRA_RECUERDA);
					});
					setIsCompleted(true);
				}
			})
			.catch((error) => {});
	}, []);

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	async function submitAnswer(correct) {
		const score = correct ? 1 : 0;
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			group: group?._id,
			score: score,
			time: time,
		})
			.then((res) => {
				if (res.status === 201) {
					setIsCompleted(true);
				}
			})
			.catch((error) => {});
	}

	function checkAnswers() {
		if (options.some((x) => x.selected === null)) {
			modalQuestionResolution({
				correct: false,
				textHtml: RECUERDA_SELECCIONAR_TEXT,
				allowOutsideClick: true,
			});
			return;
		}
		let wrongOptions = options.filter((x) => x.selected !== x.correct);
		let correct = wrongOptions.length === 0;

		modalQuestionResolution({
			correct: correct,
			textHtml: correct
				? GOOD_ANSWER_TEXT
				: getWrongAnswerText(wrongOptions.length),
			showConfirmButton: !correct,
			showCancelButton: correct,
			customConfirmButtonHtml: correct ? null : "VOLVER A INTENTAR",
			allowOutsideClick: true,
		});

		if (correct) submitAnswer(correct);
	}

	const Question = ({ id }) => {
		let question = options.find((x) => x.id === id);

		const handleOptionClick = (selected) => {
			if (isCompleted) return;
			let updatedOptions = options.map((option) =>
				option.id === question.id
					? {
							...option,
							selected: selected,
					  }
					: option
			);

			setOptions(updatedOptions);
		};

		function getStyle() {
			let baseStyle = { position: "absolute" };
			let specificStyle;
			if (id === 1) specificStyle = { top: "22%", left: "8%" };
			else if (id === 2) specificStyle = { top: "24%", left: "8%" };
			else if (id === 3) specificStyle = { top: "15%", left: "8%" };

			return { ...baseStyle, ...specificStyle };
		}
		return (
			<div className="position-relative p-2 px-5" style={{ zIndex: "1" }}>
				<img src={question.background} width={730}></img>
				<div className="row align-items-center" style={getStyle()}>
					<div
						className="col-10 txt18 txt-blue bold text-left"
						style={{ width: "550px" }}
					>
						{question?.text}
					</div>
					<div className="col-2 d-flex align-items-center justify-content-center ps-5">
						<img
							className="cursor-pointer"
							src={
								question.selected
									? icoVerdaderoSelected
									: icoVerdadero
							}
							width={56}
							height={55}
							onClick={() => handleOptionClick(true)}
						></img>
						<img
							className="cursor-pointer ms-4"
							src={
								question.selected === false
									? icoFalsoSelected
									: icoFalso
							}
							width={56}
							height={55}
							onClick={() => handleOptionClick(false)}
						></img>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="h-100 bg-recuerda">
			<div className="container-fluid px-5 pt-5 h-100">
				<div className="d-flex flex-column justify-content-center align-items-center ms-5">
					<Question id={1}></Question>
					<div>
						<Question id={2}></Question>
					</div>
					<div>
						<Question id={3}></Question>
					</div>
				</div>
				<div
					className=""
					style={{
						position: "absolute",
						right: "3.5rem",
						bottom: "10%",
						zIndex: "999",
					}}
				>
					{!isCompleted ? (
						<button
							className="btn-main"
							onClick={() => {
								checkAnswers();
							}}
						>
							<div className="text-btn-main">COMPROBAR</div>
						</button>
					) : (
						<NextButton
							navigateTo={ROUTES.SCENARIO1_EXTRA_RECUERDA}
							activeScenario={activeScenario}
						></NextButton>
					)}
				</div>
				<div
					className="d-flex justify-content-start align-items-end px-4"
					style={{ position: "absolute", bottom: "0px", left: "0px" }}
				>
					<img src={paco} width={"30%"}></img>
				</div>
			</div>
			<div
				style={{
					position: "absolute",
					top: "11%",
					left: "9%",
					zIndex: "0",
				}}
			>
				<img src={donutGlobo} width={"50%"}></img>
			</div>
			<div>
				<img
					style={{
						position: "absolute",
						bottom: "16%",
						right: "25%",
						zIndex: "0",
					}}
					src={donutGlobo}
					width={"18%"}
				></img>
			</div>
			<ScenarioBar activeScenario={activeScenario} />
		</div>
	);
}

export default injectIntl(Scenario1Extra);
