import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import App from "./App";
import Congratulations from "./pages/Congratulations";
import FinalScene from "./pages/FinalScene";
import Gameover from "./pages/Gameover";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Results from "./pages/Results";
import Scenario1 from "./pages/Scenario1/Scenario1";
import Scenario1Extra from "./pages/Scenario1/Scenario1Extra";
import Scenario1ExtraIntro from "./pages/Scenario1/Scenario1ExtraIntro";
import Scenario1ExtraRecuerda from "./pages/Scenario1/Scenario1ExtraRecuerda";
import Scenario1Instructions from "./pages/Scenario1/Scenario1Instructions";
import Scenario1Intro from "./pages/Scenario1/Scenario1Intro";
import Scenario2 from "./pages/Scenario2/Scenario2";
import Scenario2Extra from "./pages/Scenario2/Scenario2Extra";
import Scenario2ExtraIntro from "./pages/Scenario2/Scenario2ExtraIntro";
import Scenario2ExtraSolucion from "./pages/Scenario2/Scenario2ExtraSolucion";
import Scenario2Instructions from "./pages/Scenario2/Scenario2Instructions";
import Scenario2Intro from "./pages/Scenario2/Scenario2Intro";
import Scenario3 from "./pages/Scenario3/Scenario3";
import Scenario3Extra from "./pages/Scenario3/Scenario3Extra";
import Scenario3ExtraInstructions from "./pages/Scenario3/Scenario3ExtraInstructions";
import Scenario3Instructions from "./pages/Scenario3/Scenario3Instructions";
import Scenario3Instructions2 from "./pages/Scenario3/Scenario3Instructions2";
import Scenario3Intro from "./pages/Scenario3/Scenario3Intro";
import Scenario4 from "./pages/Scenario4/Scenario4";
import Scenario4End from "./pages/Scenario4/Scenario4End";
import Scenario4Extra from "./pages/Scenario4/Scenario4Extra";
import Scenario4ExtraIntro from "./pages/Scenario4/Scenario4ExtraIntro";
import Scenario4Instructions from "./pages/Scenario4/Scenario4Instructions";
import Scenario4Intro from "./pages/Scenario4/Scenario4Intro";
import Scenario5 from "./pages/Scenario5/Scenario5";
import Scenario5End from "./pages/Scenario5/Scenario5End";
import Scenario5Explicacion from "./pages/Scenario5/Scenario5Explicacion";
import Scenario5Instructions from "./pages/Scenario5/Scenario5Instructions";
import Scenario5Intro from "./pages/Scenario5/Scenario5Intro";
import StampProgress from "./pages/StampProgress";
import StartGame from "./pages/StartGame";
import Supertest from "./pages/Supertest/Supertest";
import SupertestInstructions from "./pages/Supertest/SupertestInstructions";
import SupertestIntro from "./pages/Supertest/SupertestIntro";
import TransitionScenarios from "./pages/TransitionScenarios";
import { ROUTES } from "./utils/constants";

export default function Rutas() {
	const isRegistered = useSelector((state) => state.group, shallowEqual);

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<App />}>
					<Route path="/" element={<Home />} />
					<Route path={ROUTES.REGISTER} element={<Register />} />

					{!isRegistered ? (
						/*Render register page when not registered.*/
						<Route path="*" element={<Navigate to="/" replace />} />
					) : (
						<Route>
							<Route
								path={ROUTES.START_GAME}
								element={<StartGame />}
							/>
							<Route
								path={ROUTES.TRANSITION_SCENARIOS}
								element={<TransitionScenarios />}
							/>
							<Route
								path={ROUTES.STAMP_PROGRESS}
								element={<StampProgress />}
							/>

							{/*Scenario 1*/}
							<Route
								path={ROUTES.SCENARIO1_INTRO}
								element={<Scenario1Intro />}
							/>
							<Route
								path={ROUTES.SCENARIO1_INSTRUCTIONS}
								element={<Scenario1Instructions />}
							/>
							<Route
								path={ROUTES.SCENARIO1}
								element={<Scenario1 />}
							/>
							<Route
								path={ROUTES.SCENARIO1_EXTRA_INTRO}
								element={<Scenario1ExtraIntro />}
							/>
							<Route
								path={ROUTES.SCENARIO1_EXTRA}
								element={<Scenario1Extra />}
							/>
							<Route
								path={ROUTES.SCENARIO1_EXTRA_RECUERDA}
								element={<Scenario1ExtraRecuerda />}
							/>

							{/*Scenario 2*/}
							<Route
								path={ROUTES.SCENARIO2_INTRO}
								element={<Scenario2Intro />}
							/>
							<Route
								path={ROUTES.SCENARIO2_INSTRUCTIONS}
								element={<Scenario2Instructions />}
							/>
							<Route
								path={ROUTES.SCENARIO2}
								element={<Scenario2 />}
							/>
							<Route
								path={ROUTES.SCENARIO2_EXTRA_INTRO}
								element={<Scenario2ExtraIntro />}
							/>
							<Route
								path={ROUTES.SCENARIO2_EXTRA}
								element={<Scenario2Extra />}
							/>
							<Route
								path={ROUTES.SCENARIO2_EXTRA_2}
								element={<Scenario2Extra />}
							/>
							<Route
								path={ROUTES.SCENARIO2_EXTRA_3}
								element={<Scenario2Extra />}
							/>
							<Route
								path={ROUTES.SCENARIO2_EXTRA_SOLUCION}
								element={<Scenario2ExtraSolucion />}
							/>

							{/*Scenario 3*/}
							<Route
								path={ROUTES.SCENARIO3_INTRO}
								element={<Scenario3Intro />}
							/>
							<Route
								path={ROUTES.SCENARIO3_INSTRUCTIONS}
								element={<Scenario3Instructions />}
							/>
							<Route
								path={ROUTES.SCENARIO3_INSTRUCTIONS_2}
								element={<Scenario3Instructions2 />}
							/>
							<Route
								path={ROUTES.SCENARIO3}
								element={<Scenario3 />}
							/>
							<Route
								path={ROUTES.SCENARIO3_EXTRA_INSTRUCTIONS}
								element={<Scenario3ExtraInstructions />}
							/>
							<Route
								path={ROUTES.SCENARIO3_EXTRA}
								element={<Scenario3Extra />}
							/>

							{/*Scenario 4*/}
							<Route
								path={ROUTES.SCENARIO4_INTRO}
								element={<Scenario4Intro />}
							/>
							<Route
								path={ROUTES.SCENARIO4_INSTRUCTIONS}
								element={<Scenario4Instructions />}
							/>
							<Route
								path={ROUTES.SCENARIO4}
								element={<Scenario4 />}
							/>
							<Route
								path={ROUTES.SCENARIO4_EXTRA_INTRO}
								element={<Scenario4ExtraIntro />}
							/>
							<Route
								path={ROUTES.SCENARIO4_EXTRA}
								element={<Scenario4Extra />}
							/>
							<Route
								path={ROUTES.SCENARIO4_END}
								element={<Scenario4End />}
							/>

							{/*Scenario 5*/}
							<Route
								path={ROUTES.SCENARIO5_INTRO}
								element={<Scenario5Intro />}
							/>
							<Route
								path={ROUTES.SCENARIO5_INSTRUCTIONS}
								element={<Scenario5Instructions />}
							/>
							<Route
								path={ROUTES.SCENARIO5_INSTRUCTIONS}
								element={<Scenario5Instructions />}
							/>
							<Route
								path={ROUTES.SCENARIO5}
								element={<Scenario5 />}
							/>
							<Route
								path={ROUTES.SCENARIO5_EXPLICATION}
								element={<Scenario5Explicacion />}
							/>
							<Route
								path={ROUTES.SCENARIO5_END}
								element={<Scenario5End />}
							/>

							{/*Supertest */}
							<Route
								path={ROUTES.SUPERTEST_INTRO}
								element={<SupertestIntro />}
							/>
							<Route
								path={ROUTES.SUPERTEST_INSTRUCTIONS}
								element={<SupertestInstructions />}
							/>
							<Route
								path={ROUTES.SUPERTEST}
								element={<Supertest />}
							/>
							<Route
								path={ROUTES.SUPERTEST_2}
								element={<Supertest />}
							/>
							<Route
								path={ROUTES.SUPERTEST_3}
								element={<Supertest />}
							/>
							<Route
								path={ROUTES.FINAL_SCENE}
								element={<FinalScene />}
							/>
							<Route
								path={ROUTES.CONGRATULATIONS}
								element={<Congratulations />}
							/>
							<Route
								path={ROUTES.RESULTS}
								element={<Results />}
							/>
							<Route
								path={ROUTES.GAMEOVER}
								element={<Gameover />}
							/>
						</Route>
					)}
				</Route>
			</Routes>
		</BrowserRouter>
	);
}
