import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnswerByQuizAndGroup, postAnswer } from "../../api/answer";
import Intentos from "../../components/Intentos";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateTime } from "../../redux/actions";
import {
	QUESTION_ALREADY_ANSWERED_BUTTON,
	QUESTION_ALREADY_ANSWERED_TEXT,
	QUIZZES,
	ROUTES,
	SCENARIOS,
} from "../../utils/constants";
import { getScenarioNumByPath, getTimer } from "../../utils/helpers";
import { modalQuestionResolution } from "../../utils/logger";

function Scenario4({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const group = useSelector((state) => state.group);

	const activeScenario = getScenarioNumByPath(location.pathname);

	const [selectedOption, setSelectedOption] = useState(null);
	const [isCompleted, setIsCompleted] = useState(false);
	const [intentosRestantes, setIntentosRestantes] = useState(2);
	const [time, setTime] = useState(0);

	const QUIZ_ID = QUIZZES.TIPO_NUTRICION;
	const SCENARIO_ID = SCENARIOS.SCENARIO_4;

	const ENTERAL_TYPE = "Enteral";
	const PARENTERAL_TYPE = "Parenteral";

	const [options, setOptions] = useState([
		{
			id: 1,
			text: "Íleo paralítico desarrollado como complicación posoperatoria que ha conducido a una disminución transitoria de la motilidad intestinal.",
			selectedType: null,
			correctType: ENTERAL_TYPE,
		},
		{
			id: 2,
			text: "Necesidad de reposo intestinal debido a pancreatitis aguda.",
			selectedType: null,
			correctType: ENTERAL_TYPE,
		},
		{
			id: 3,
			text: "Íleo paralítico tras cirugía vesical en paciente ingresado con débito elevado por sonda de aspiración.",
			selectedType: null,
			correctType: PARENTERAL_TYPE,
		},
		{
			id: 4,
			text: "Obstrucción intestinal recurrente debido a progresión importante de la enfermedad de Crohn que ha llevado a una reducción significativa de la superficie absortiva intestinal funcional.",
			selectedType: null,
			correctType: PARENTERAL_TYPE,
		},
		{
			id: 5,
			text: "Isquemia mesentérica crónica.",
			selectedType: null,
			correctType: ENTERAL_TYPE,
		},
		{
			id: 6,
			text: "Posoperatorio inmediato tras esofaguectomía por adenocarcinoma de esófago.",
			selectedType: null,
			correctType: ENTERAL_TYPE,
		},
		{
			id: 7,
			text: "Paciente intervenido de hemicolectomía derecha, ingresado por fístula colorrectal.",
			selectedType: null,
			correctType: ENTERAL_TYPE,
		},
		{
			id: 8,
			text: "Fístula enterocutánea tras cirugía por vólvulo intestinal con débito mayor de 1 litro al día.",
			selectedType: null,
			correctType: PARENTERAL_TYPE,
		},
	]);

	const CORRECT_ANSWER_TEXT = `<div>
			<p class="txt22 txt-green bold">
				¡Correcto!
			</p>
			<div class="txt18">
				<p>
					Revisa las indicaciones de tipo de nutrición según la situación.
				</p>
				<br />
				<p>
					<span class="bold">Es necesario priorizar, siempre que sea posible, la nutrición enteral</span>, al ser la vía más fisiológica y con menor tasa de complicaciones respecto a la nutrición parenteral. 
				</p>
				<p>
					No obstante, la prescripción depende de la existencia de una capacidad para recibir los nutrientes (vía de acceso) y un tramo intestinal suficiente para la absorción.
				</p>
				<p>
					No podemos plantear la indicación de nutrición parenteral o enteral en función simplemente de la patología, sino que <span class="bold">debemos individualizar según la situación clínica del paciente y el estado de su tubo digestivo</span><sup>16</sup>.
				</p>
			</div>
		</div>`;

	const RECUERDA_SELECCIONAR_TEXT = `<div>
		<div class="txt22 txt-red bold">
			Recuerda que las 8 situaciones deben tener una opción marcada para que se active el botón Comprobar.
		</div>
	</div>`;

	const INCORRECT_ANSWER_TEXT = `<div>
		<p class="txt22 txt-red bold">¡Lástima!</p>
		<div class="txt18">
			<p>
				Has fallado una o más de las indicaciones de tipo de nutrición. <span class="bold">Revisa las indicaciones de tipo de nutrición según la situación</span>.
			</p>
			<br />
			<p>
				<span class="bold">Es necesario priorizar, siempre que sea posible, la nutrición enteral</span>, al ser la vía más fisiológica y con menor tasa de complicaciones respecto a la nutrición parenteral. 
			</p>
			<p>
				No obstante, la prescripción depende de la existencia de una capacidad para recibir los nutrientes (vía de acceso) y un tramo intestinal suficiente para la absorción.
			</p>
			<p>
				No podemos plantear la indicación de nutrición parenteral o enteral en función simplemente de la patología, sino que <span class="bold">debemos individualizar según la situación clínica del paciente y el estado de su tubo digestivo</span><sup>16</sup>.
			</p>
		</div>
	</div>`;

	function getWrongAnswerText(numErrores) {
		return `<div>
			<p class="txt18">
				Hay <span class="txt-red bold">${numErrores} errores</span>
			</p>
			<p class="txt18">Te queda un intento. Habla con tus compañeros y consensuad la solución.</p>
			<p class="txt18">
				¡Os jugáis un sello!
			</p>				
			</div>`;
	}

	useEffect(() => {
		getAnswerByQuizAndGroup(QUIZ_ID, group?._id)
			.then((res) => {
				if (res.status === 200 && res.data.length > 0) {
					modalQuestionResolution({
						correct: false,
						allowOutsideClick: false,
						showCancelButton: false,
						showConfirmButton: true,
						customConfirmButtonHtml:
							QUESTION_ALREADY_ANSWERED_BUTTON,
						textHtml: QUESTION_ALREADY_ANSWERED_TEXT,
					}).then((res) => {
						if (res.isConfirmed)
							navigate(ROUTES.SCENARIO4_EXTRA_INTRO);
					});
					setIsCompleted(true);
				}
			})
			.catch((error) => {});
	}, []);

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	async function submitAnswer(correct) {
		const score = correct ? 1 : 0;
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			group: group?._id,
			score: score,
			time: time,
		})
			.then((res) => {
				if (res.status === 201) {
					setIsCompleted(true);
				}
			})
			.catch((error) => {});
	}

	const SelectOption = ({ id }) => {
		let option = options.find((x) => x.id === id);

		function handleOptionClick(type) {
			if (isCompleted) return;
			if (option.selectedType === type) return;

			let updatedOptions = options.map((x) =>
				x.id === id ? { ...x, selectedType: type } : { ...x }
			);

			setOptions(updatedOptions);
		}

		function getBackgroundColor(type) {
			if (!isCompleted) return;
			return option?.correctType === option?.selectedType &&
				option?.selectedType === type
				? "#87CA1B"
				: option?.correctType !== option?.selectedType &&
				  option?.selectedType === type
				? "#FF3A3A"
				: "";
		}

		return (
			<div>
				<div className="row d-flex align-items-center mt-2">
					<div className="col-8">
						<div className="select-option-text txt16 bold">
							{option.text}
						</div>
					</div>
					<div className="col-2 ps-2">
						<div
							className={`select-option txt18 bold ${
								option.selectedType === ENTERAL_TYPE
									? "selected txt-white"
									: option.selectedType === PARENTERAL_TYPE
									? "txt-grey"
									: ""
							}`}
							style={{
								backgroundColor:
									getBackgroundColor(ENTERAL_TYPE),
							}}
							onClick={() => handleOptionClick(ENTERAL_TYPE)}
						>
							{ENTERAL_TYPE}
						</div>
					</div>
					<div className="col-2 ps-2">
						<div
							className={`select-option txt18 bold ${
								option.selectedType === PARENTERAL_TYPE
									? "selected txt-white"
									: option.selectedType === ENTERAL_TYPE
									? "txt-grey"
									: ""
							}`}
							style={{
								backgroundColor:
									getBackgroundColor(PARENTERAL_TYPE),
							}}
							onClick={() => handleOptionClick(PARENTERAL_TYPE)}
						>
							{PARENTERAL_TYPE}
						</div>
					</div>
				</div>
			</div>
		);
	};

	function checkAnswers() {
		if (options.some((x) => !x.selectedType)) {
			modalQuestionResolution({
				correct: false,
				textHtml: RECUERDA_SELECCIONAR_TEXT,
				allowOutsideClick: true,
			});
			return;
		}
		let wrongAnswers = options.filter(
			(x) => x.selectedType !== x.correctType
		);

		let correct = wrongAnswers.length === 0;

		if (correct) {
			modalQuestionResolution({
				correct: true,
				textHtml: CORRECT_ANSWER_TEXT,
				allowOutsideClick: true,
			});
			submitAnswer(correct);
		} else {
			if (intentosRestantes > 1)
				modalQuestionResolution({
					correct: false,
					textHtml: getWrongAnswerText(wrongAnswers.length),
					allowOutsideClick: true,
				});
			else {
				modalQuestionResolution({
					correct: false,
					textHtml: INCORRECT_ANSWER_TEXT,
					allowOutsideClick: true,
				});
				submitAnswer(correct);
			}
		}
		setIntentosRestantes(intentosRestantes - 1);
	}

	return (
		<>
			<div className="h-100 bg-blue-plain">
				<div
					className="container-fluid pt-3 h-100"
					style={{ paddingRight: "1%", paddingLeft: "2%" }}
				>
					<div className="row">
						<div
							className="col-10 d-flex flex-column"
							style={{ maxWidth: "80%" }}
						>
							{options.map((option) => {
								return (
									<>
										<SelectOption
											id={option.id}
										></SelectOption>
									</>
								);
							})}
						</div>
						<div className="col-2 ms-4 d-flex flex-column align-items-end pt-3">
							<Intentos
								intentosRestantes={intentosRestantes}
							></Intentos>
							<div style={{ marginTop: "auto" }}>
								{!isCompleted ? (
									<button
										className="btn-main bold"
										onClick={() => {
											checkAnswers();
										}}
									>
										<div className="text-btn-main">
											COMPROBAR
										</div>
									</button>
								) : (
									<NextButton
										navigateTo={ROUTES.STAMP_PROGRESS}
										navigateToAfter={
											ROUTES.SCENARIO4_EXTRA_INTRO
										}
										activeScenario={activeScenario}
									></NextButton>
								)}
							</div>
						</div>
					</div>
				</div>
				<ScenarioBar activeScenario={activeScenario} />
			</div>
		</>
	);
}

export default injectIntl(Scenario4);
