import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import tastyMED from "../../assets/tasty-med.png";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioNumByPath } from "../../utils/helpers";

function Scenario2ExtraIntro({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const activeScenario = getScenarioNumByPath(location.pathname);

	return (
		<div className="h-100 bg-preguntas-3">
			<div className="container-fluid px-5 pt-5 h-100">
				<p className="txt30 txt-purple bold line-height-40">
					Pero antes de intentar conseguir los demás sellos…
					<div>¡ponte a prueba!</div>
				</p>
				<div className="row">
					<div className="col-7 d-flex flex-column align-items-start">
						<div className="txt20 txt-blue">
							<p className="mt-3">
								Estás suscrito a la revista{" "}
								<i>TastyMed: Artículos de Nutrición Clínica.</i>
							</p>
							<p className="mt-3">
								Se trata de una publicación bimestral que,
								además de ofrecer contenido científico
								interesante, organiza un concurso en cada
								número.
							</p>
							<p className="mt-3">
								En este número, el premio es «
								<span className="bold">
									una experiencia culinaria saludable e
									inolvidable
								</span>
								» en el restaurante que elija el ganador.
							</p>
							<p className="bold mt-4">
								¡Has decidido que ese premio tiene que ser tuyo!
							</p>
						</div>
						<div
							className="px-5"
							style={{
								position: "absolute",
								left: "0px",
								bottom: "18%",
							}}
						>
							<NextButton
								navigateTo={ROUTES.SCENARIO2_EXTRA}
								classes={"mt-4"}
								activeScenario={activeScenario}
							></NextButton>
						</div>
					</div>
					<div className="col-5 d-flex align-items-end justify-content-center">
						<img src={tastyMED} width={"75%"}></img>
					</div>
				</div>
			</div>
			<ScenarioBar activeScenario={activeScenario}></ScenarioBar>
		</div>
	);
}

export default injectIntl(Scenario2ExtraIntro);
