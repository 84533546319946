import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import iconPreguntasImportantes from "../../assets/icon-preguntas-importantes.svg";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioNumByPath } from "../../utils/helpers";

function Scenario1Intro({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const activeScenario = getScenarioNumByPath(location.pathname);

	return (
		<div className="h-100 bg-preguntas-4">
			<div className="container-fluid pt-5 h-100">
				<div className="row h-100">
					<div
						className="col d-flex justify-content-center align-items-center"
						style={{ marginBottom: "28vh" }}
					>
						<div className="d-flex flex-column align-items-center">
							<div>
								<img
									src={iconPreguntasImportantes}
									width={350}
								></img>
							</div>
							<p className="txt30 txt-purple bold mt-3">
								Afirmaciones importantes
							</p>
							<div className="mt-3">
								<NextButton
									navigateTo={ROUTES.SCENARIO1_INSTRUCTIONS}
									activeScenario={activeScenario}
								></NextButton>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar activeScenario={activeScenario} expanded />
		</div>
	);
}

export default injectIntl(Scenario1Intro);
