import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import bubblePaco from "../../assets/bubble-paco.png";
import paco from "../../assets/paco.png";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioNumByPath } from "../../utils/helpers";

function Scenario1ExtraIntro({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const activeScenario = getScenarioNumByPath(location.pathname);

	return (
		<div className="h-100 bg-scenario1-extra">
			<div className="container-fluid px-5 pt-4 h-100">
				<div className="row">
					<div className="col-8">
						<p className="txt30 txt-purple bold line-height-40">
							Pero antes de intentar conseguir los demás sellos,
							tendrás que superar un pequeño reto…
						</p>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-7 d-flex flex-column align-items-start">
						<p className="txt20 txt-blue">
							A punto de acabar tu jornada laboral, Anselmo, uno
							de los internistas del hospital, entra en tu
							consulta:
						</p>
						<img src={bubblePaco} width={"120%"}></img>
						<div
							className="txt20 txt-blue"
							style={{ marginTop: "-5%" }}
						>
							Anselmo es muy insistente y no dejará que te vayas
							hasta que le aclares las dudas. Así que…
						</div>
						<div
							className="d-flex justify-content-start px-5"
							style={{
								position: "absolute",
								left: "0px",
								bottom: "17%",
							}}
						>
							<NextButton
								navigateTo={ROUTES.SCENARIO1_EXTRA}
								activeScenario={activeScenario}
								classes={"mt-2"}
							></NextButton>{" "}
						</div>
					</div>
				</div>
				<div
					className="d-flex justify-content-end px-5"
					style={{
						position: "absolute",
						right: "3%",
						bottom: "1%",
					}}
				>
					<img src={paco} width={"55%"}></img>
				</div>
			</div>
			<ScenarioBar activeScenario={activeScenario}></ScenarioBar>
		</div>
	);
}

export default injectIntl(Scenario1ExtraIntro);
