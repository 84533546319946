import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import trophy from "../assets/copa.png";
import NextButton from "../components/NextButton";
import { setGroup } from "../redux/actions";
import { useLang } from "../translations/i18n";
import { ROUTES } from "../utils/constants";
import { updateStats } from "../utils/helpers";

function Congratulations({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);

	useEffect(() => {
		if (group) {
			dispatch(
				setGroup({
					...group,
					lastVisited: ROUTES.RESULTS,
					finished: true,
				})
			);
			updateStats({
				...group,
				lastVisited: ROUTES.RESULTS,
				finished: true,
			});
		}
	}, []);

	return (
		<div className="h-100">
			<div
				className="container-fluid bg-confeti h-100"
				onClick={() => {
					updateStats({
						...group,
						finished: true,
						lastVisited: ROUTES.RESULTS,
					});
					navigate(ROUTES.RESULTS);
				}}
			>
				<div className="row h-100 justify-content-center">
					<div className="d-flex justify-content-center pb-5 pt-5">
						<div className="text-center pt-3">
							<div className="txt42 txt-blue bold">
								¡ENHORABUENA!
							</div>
							<div className="txt-blue txt24">
								<div className="mt-4">
									Has resuelto el escape. Muchas gracias por
									participar.
								</div>
								<div className="mt-2">
									Y ahora,{" "}
									<span className="bold">
										¿quieres conocer tu puntuación?
									</span>
								</div>
							</div>
							<img
								className="trophy-icon"
								src={trophy}
								width={"70%"}
								alt="Trofeo"
							/>
						</div>
					</div>
				</div>
				<div
					className="px-5"
					style={{ position: "absolute", right: "0", bottom: "7%" }}
				>
					<NextButton
						text={"VER RESULTADOS"}
						navigateTo={ROUTES.RESULTS}
						classes={"px-4 w-fit-content"}
					></NextButton>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Congratulations);
