import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnswerByQuizAndGroup, postAnswer } from "../../api/answer";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import SupertestProgress from "../../components/SupertestProgress";
import { useTimer } from "../../hooks/useTimer";
import { updateTime } from "../../redux/actions";
import {
	QUESTION_ALREADY_ANSWERED_BUTTON,
	QUESTION_ALREADY_ANSWERED_TEXT,
	QUIZZES,
	ROUTES,
	SCENARIOS,
} from "../../utils/constants";
import { getScenarioNumByPath, getTimer } from "../../utils/helpers";
import { modalConfirm, modalQuestionResolution } from "../../utils/logger";

function Supertest({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const group = useSelector((state) => state.group);

	const activeScenario = getScenarioNumByPath(location.pathname);

	const SCENARIO_ID = SCENARIOS.SUPERTEST;

	const activeQuestionNumber =
		location.pathname === ROUTES.SUPERTEST
			? 0
			: location.pathname === ROUTES.SUPERTEST_2
			? 1
			: 2;

	const QUIZ_ID =
		location.pathname === ROUTES.SUPERTEST
			? QUIZZES.SUPERTEST_1
			: location.pathname === ROUTES.SUPERTEST_2
			? QUIZZES.SUPERTEST_2
			: QUIZZES.SUPERTEST_3;

	const [checkedAnswers, setCheckedAnswers] = useState(null);

	const [isCompleted, setIsCompleted] = useState(null);
	const [time, setTime] = useState(0);

	const CONTENT = [
		{
			title: `<div>
					Teniendo en cuenta los criterios de la Global Leadership
					Initiative on Malnutrition (o criterios GLIM), 
					<span class="bold">
						¿cuál de los siguientes parámetros NO sería diagnóstico
						de malnutrición?
					</span>
				</div>`,
			options: [
				{
					id: 1,
					text: "Pérdida de masa grasa",
					selected: false,
					correct: true,
				},
				{
					id: 2,
					text: "Pérdida de peso",
					selected: false,
					correct: false,
				},
				{
					id: 3,
					text: "Índice de masa corporal (IMC) bajo",
					selected: false,
					correct: false,
				},
				{
					id: 4,
					text: "Disminución de la ingesta o de la asimilación de los alimentos",
					selected: false,
					correct: false,
				},
			],
			explication: `<div class="text-left">
					<p>
						El
						<span class="bold">
							diagnóstico de desnutrición en función de los
							criterios GLIM
						</span>
						se basa en:
					</p>
					<p>
						• Criterios fenotípicos: porcentaje de pérdida de peso,
						IMC bajo y pérdida de masa muscular.
					</p>
					<p>
						• Criterios etiológicos: disminución de la ingesta o
						asimilación de los alimentos, y situación de enfermedad
						aguda o crónica con estado proinflamatorio.
					</p>
					<p class="bold">
						La pérdida de masa grasa por sí sola no constituiría un
						criterio para el diagnóstico de desnutrición.
					</p>
				</div>`,
		},
		{
			title: `<div class="bold">
					¿Cuál de las siguientes pruebas NO se utilizaría para
					evaluar la composición corporal?
				</div>`,
			options: [
				{
					id: 1,
					text: "Bioimpedanciometría eléctrica",
					selected: false,
					correct: false,
				},
				{
					id: 2,
					text: "Dinamometría de mano",
					selected: false,
					correct: true,
				},
				{
					id: 3,
					text: "Ecografía nutricional",
					selected: false,
					correct: false,
				},
				{
					id: 4,
					text: "Circunferencia de pantorrilla",
					selected: false,
					correct: false,
				},
			],
			explication: `<div class="text-left">
					<p>
						La valoración morfofuncional se basa en la evaluación de
						la valoración de la ingesta, la distribución de los
						distintos compartimentos corporales y la fuerza/función
						muscular. En función de la prueba elegida, podremos
						evaluar la función o la composición corporal.
					</p>
					<p class="bold">
						La dinamometría de mano no nos permite evaluar la
						composición corporal, sino únicamente la fuerza
						muscular.
					</p>
				</div>`,
		},
		{
			title: `<div class="bold">
					¿Cuál de las siguientes NO es indicación de nutrición
					parenteral?
				</div>`,
			options: [
				{
					id: 1,
					text: "Inestabilidad hemodinámica",
					selected: false,
					correct: true,
				},
				{
					id: 2,
					text: "Alteración grave de la motilidad intestinal",
					selected: false,
					correct: false,
				},
				{
					id: 3,
					text: "Obstrucción mecánica intestinal alta",
					selected: false,
					correct: false,
				},
				{
					id: 4,
					text: "Afectación extensa de la mucosa intestinal que condicione malabsorción",
					selected: false,
					correct: false,
				},
			],
			explication: `<div class="text-left">
					<p>
						La vía de nutrición artificial preferida es la nutrición
						enteral, pero existen distintas condiciones en las que esta
						no es posible, por lo que se requiere el uso de una vía
						parenteral para la nutrición.
					</p>
					<p>
						No obstante, también existen
						<span class="bold">
							determinadas condiciones en las que se encuentra
							contraindicado cualquier tipo de nutrición debido al
							riesgo asociado a esta: la más frecuente es la
							inestabilidad hemodinámica,
						</span>
						sobre todo en pacientes en Unidades de Cuidados
						Intensivos.
					</p>
				</div>`,
		},
	];

	const [options, setOptions] = useState(CONTENT);

	const GOOD_ANSWER_TEXT = `<div>
			<div class="txt22 txt-green bold">¡Correcto!</div>
			<br />
			<p class="txt18">${options[activeQuestionNumber].explication}</p>
		</div>`;

	const WRONG_ANSWER_TEXT = `<div>
		<div class="txt22 txt-red bold">No es correcto</div>
		<br />
		<p class="txt18">Escoge otra respuesta</p>
	</div>`;

	useEffect(() => {
		getAnswerByQuizAndGroup(QUIZ_ID, group?._id)
			.then((res) => {
				if (res.status === 200 && res.data.length > 0) {
					setIsCompleted(false);
					modalQuestionResolution({
						correct: false,
						allowOutsideClick: false,
						showCancelButton: false,
						showConfirmButton: true,
						customConfirmButtonHtml:
							QUESTION_ALREADY_ANSWERED_BUTTON,
						textHtml: QUESTION_ALREADY_ANSWERED_TEXT,
					}).then((res) => {
						if (res.isConfirmed)
							navigate(
								activeQuestionNumber === 0
									? ROUTES.SUPERTEST_2
									: activeQuestionNumber === 1
									? ROUTES.SUPERTEST_3
									: ROUTES.FINAL_SCENE
							);
					});
				}
			})
			.catch((error) => {});
	}, [location]);

	const SelectOption = ({ optionId }) => {
		let option = options[activeQuestionNumber].options.find(
			(x) => x.id === optionId
		);

		const handleOptionClick = () => {
			if (isCompleted) return;
			setCheckedAnswers(false);

			modalConfirm({
				text: "¿Estás seguro de que quieres marcar esta respuesta?",
			}).then((res) => {
				if (res.isConfirmed) {
					const updatedOptions = options.map((item, index) => {
						if (index === activeQuestionNumber) {
							const updatedItem = {
								...item,
								options: item.options.map((x) => ({
									...x,
									selected: x.id === option.id ? true : false,
								})),
							};
							return updatedItem;
						}
						return item;
					});

					setOptions(updatedOptions);
					checkAnswers(updatedOptions);
				}
			});
		};

		return (
			<div style={{ height: "10vh" }}>
				<div
					className={`select-option-rectangle ${
						!isCompleted ? "cursor-pointer" : ""
					} txt-blue bold ${option?.selected ? "selected" : ""}`}
					style={{ width: "40vw" }}
					onClick={handleOptionClick}
				>
					<div
						className={`select-option-number-container ${
							option?.selected ? "txt-white" : "txt-blue"
						} bold txt21`}
						style={{
							backgroundColor:
								option?.selected &&
								option?.correct &&
								checkedAnswers
									? "#87CA1B"
									: option?.selected &&
									  !option?.correct &&
									  checkedAnswers
									? "#FF3A3A"
									: option?.selected
									? "#1B3A94"
									: "",
						}}
					>
						{String.fromCharCode(
							"A".charCodeAt(0) + option?.id - 1
						)}
						.
					</div>
					<div className="ms-3 txt21" style={{ maxWidth: "81%" }}>
						{option?.text}
					</div>
				</div>
			</div>
		);
	};

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	async function submitAnswer(correct) {
		const score = correct ? 1 : 0;
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			group: group?._id,
			score: score,
			time: time,
		})
			.then((res) => {
				if (res.status === 201) {
					setIsCompleted(true);
				}
			})
			.catch((error) => {});
	}

	function checkAnswers(updatedOptions) {
		setCheckedAnswers(true);
		let correct = updatedOptions[activeQuestionNumber].options.some(
			(x) => x.selected && x.correct
		);
		modalQuestionResolution({
			correct: correct,
			textHtml: correct ? GOOD_ANSWER_TEXT : WRONG_ANSWER_TEXT,
			showCancelButton: true,
			customCancelButtonHtml: correct ? "AVANZAR" : null,
			allowOutsideClick: true,
		});

		if (correct) submitAnswer(correct);
	}

	const handleQuestionAdvance = () => {
		setTime(0);
		setCheckedAnswers(false);
		setIsCompleted(false);
	};

	return (
		<div className="h-100 bg-mult-choice">
			<div className="container-fluid px-5 pt-4 h-100">
				<div className="d-flex justify-content-end">
					<SupertestProgress
						activeQuestion={activeQuestionNumber + 1}
						results={options.map((x) =>
							x.options.some((y) => y.selected)
								? x.options.some((y) => y.selected && y.correct)
								: null
						)}
					></SupertestProgress>
				</div>
				<div
					className="row d-flex align-items-center"
					style={{ marginTop: "-5%" }}
				>
					<div className="col-12 d-flex justify-content-center">
						<div className="txt30 txt-purple bold pb-4">
							SUPERTEST
						</div>
					</div>
				</div>
				<div>
					<div className="d-flex flex-column align-items-center pt-3">
						<div
							className="txt28 txt-blue text-center line-height-30"
							dangerouslySetInnerHTML={{
								__html: options[activeQuestionNumber].title,
							}}
						></div>
						<div className="mt-4">
							<SelectOption optionId={1}></SelectOption>
							<div className="mt-3">
								<SelectOption optionId={2}></SelectOption>
							</div>
							<div className="mt-3">
								<SelectOption optionId={3}></SelectOption>
							</div>
							<div className="mt-3">
								<SelectOption optionId={4}></SelectOption>
							</div>
						</div>
					</div>
				</div>
				{isCompleted && (
					<div
						className="px-5"
						style={{
							position: "absolute",
							right: "0px",
							bottom: "10.5%",
						}}
					>
						<div onClick={handleQuestionAdvance}>
							<NextButton
								navigateTo={
									activeQuestionNumber === 0
										? ROUTES.SUPERTEST_2
										: activeQuestionNumber === 1
										? ROUTES.SUPERTEST_3
										: ROUTES.FINAL_SCENE
								}
								activeScenario={activeScenario}
							></NextButton>
						</div>
					</div>
				)}
			</div>
			<ScenarioBar activeScenario={activeScenario} />
		</div>
	);
}

export default injectIntl(Supertest);
