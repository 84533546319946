export const GAME_TIME_SECS = 3600;

export const ROUTES = {
	HOME: "/",
	REGISTER: "/register",
	START_GAME: "/start-game",
	TRANSITION_SCENARIOS: "/scenarios",
	STAMP_PROGRESS: "/stamp-progress",

	SCENARIO1_INTRO: "/scenario1-intro",
	SCENARIO1_INSTRUCTIONS: "/scenario1-instructions",
	SCENARIO1: "/scenario1",
	SCENARIO1_EXTRA_INTRO: "/scenario1-extra-intro",
	SCENARIO1_EXTRA: "/scenario1-extra",
	SCENARIO1_EXTRA_RECUERDA: "/scenario1-extra-recuerda",

	SCENARIO2_INTRO: "/scenario2-intro",
	SCENARIO2_INSTRUCTIONS: "/scenario2-instructions",
	SCENARIO2: "/scenario2",
	SCENARIO2_EXTRA_INTRO: "/scenario2-extra-intro",
	SCENARIO2_EXTRA: "/scenario2-extra",
	SCENARIO2_EXTRA_2: "/scenario2-extra-2",
	SCENARIO2_EXTRA_3: "/scenario2-extra-3",
	SCENARIO2_EXTRA_SOLUCION: "/scenario2-extra-solucion",

	SCENARIO3_INTRO: "/scenario3-intro",
	SCENARIO3_INSTRUCTIONS: "/scenario3-instructions",
	SCENARIO3_INSTRUCTIONS_2: "/scenario3-instructions-2",
	SCENARIO3: "/scenario3",
	SCENARIO3_EXTRA_INSTRUCTIONS: "/scenario3-extra-instructions",
	SCENARIO3_EXTRA: "/scenario3-extra",

	SCENARIO4_INTRO: "/scenario4-intro",
	SCENARIO4_INSTRUCTIONS: "/scenario4-instructions",
	SCENARIO4: "/scenario4",
	SCENARIO4_EXTRA_INTRO: "/scenario4-extra-intro",
	SCENARIO4_EXTRA: "/scenario4-extra",
	SCENARIO4_END: "/scenario4-end",

	SCENARIO5_INTRO: "/scenario5-intro",
	SCENARIO5_INSTRUCTIONS: "/scenario5-instructions",
	SCENARIO5: "/scenario5",
	SCENARIO5_EXPLICATION: "/scenario5-explication",
	SCENARIO5_END: "/scenario5-end",

	SUPERTEST_INTRO: "/supertest-intro",
	SUPERTEST_INSTRUCTIONS: "/supertest-instructions",
	SUPERTEST: "/supertest",
	SUPERTEST_2: "/supertest-2",
	SUPERTEST_3: "/supertest-3",

	FINAL_SCENE: "/final-scene",
	CONGRATULATIONS: "/congratulations",
	END: "/end",
	RESULTS: "/results",
	GAMEOVER: "/gameover",
};

export const SCENARIOS = {
	SCENARIO_1: "65127bfe70cc5310ac640cbd",
	SCENARIO_2: "65127cfd7b2f5959ecc41031",
	SCENARIO_3: "65127d067b2f5959ecc41033",
	SCENARIO_4: "65127d0f7b2f5959ecc41035",
	SCENARIO_5: "65127d167b2f5959ecc41037",
	SUPERTEST: "65127d217b2f5959ecc41039",
};

export const QUIZZES = {
	NUTRICION_CLINICA: "65127e987b2f5959ecc4104c",
	AFIRMACIONES_VF: "65127f5d7b2f5959ecc4105a",
	TECNICAS_VALORACION: "65127fac7b2f5959ecc41061",
	ECOGRAFIA_NUTRICIONAL: "651280137b2f5959ecc41063",
	DINAMOMETRIA_MANO: "6512801d7b2f5959ecc41065",
	BIOIMP_ELECTRICA: "6512802c7b2f5959ecc41067",
	FRAGMENTO_INCOMPLETO: "651280ca7b2f5959ecc4106f",
	CASO_CLINICO_1: "651280f07b2f5959ecc41076",
	CASO_CLINICO_2: "651280f37b2f5959ecc41078",
	CASO_CLINICO_3: "651280f67b2f5959ecc4107a",
	CASO_CLINICO_4: "651280f97b2f5959ecc4107c",
	TIPO_NUTRICION: "6512814f7b2f5959ecc41083",
	CONTRAINDICACIONES_ENTERAL: "6512817d7b2f5959ecc41085",
	AFIRMACION_FALSA_ENTERAL: "651282327b2f5959ecc4108c",
	SUPERTEST_1: "6513e9b7eb4ed56660961616",
	SUPERTEST_2: "6513e9bbeb4ed56660961618",
	SUPERTEST_3: "6513e9beeb4ed5666096161a",
};

const FIRST_STAMP_QUIZ_ID = QUIZZES.NUTRICION_CLINICA;
const SECOND_STAMP_QUIZ_ID = QUIZZES.TECNICAS_VALORACION;
const THIRD_STAMP_QUIZ_ID = QUIZZES.CASO_CLINICO_4;
const FOURTH_STAMP_QUIZ_ID = QUIZZES.TIPO_NUTRICION;
const FIFTH_STAMP_QUIZ_ID = QUIZZES.AFIRMACION_FALSA_ENTERAL;

export const STAMPS_QUIZZES = [
	FIRST_STAMP_QUIZ_ID,
	SECOND_STAMP_QUIZ_ID,
	THIRD_STAMP_QUIZ_ID,
	FOURTH_STAMP_QUIZ_ID,
	FIFTH_STAMP_QUIZ_ID,
];

export const QUESTION_ALREADY_ANSWERED_BUTTON = `<div class="d-flex align-items-center justify-content-center px-3">
		AVANZAR
	</div>`;

export const QUESTION_ALREADY_ANSWERED_TEXT = `<div>
		<div class="txt22">Ya has resuelto esta prueba anteriormente.</div>
		<div class="txt22 mt-2">¡Sigue adelante!</div>
	</div>`;
