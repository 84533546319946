import {
	DndContext,
	MouseSensor,
	TouchSensor,
	useDraggable,
	useDroppable,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnswerByQuizAndGroup, postAnswer } from "../../api/answer";
import iconTabla from "../../assets/icon-tabla.svg";
import iconImportant from "../../assets/importante.svg";
import solucionPDF from "../../assets/pdf/solucion-escenario-2.pdf";
import Intentos from "../../components/Intentos";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateTime } from "../../redux/actions";
import {
	QUESTION_ALREADY_ANSWERED_BUTTON,
	QUESTION_ALREADY_ANSWERED_TEXT,
	QUIZZES,
	ROUTES,
	SCENARIOS,
} from "../../utils/constants";
import { getScenarioNumByPath, getTimer } from "../../utils/helpers";
import {
	modalEnunciado,
	modalPDF,
	modalQuestionResolution
} from "../../utils/logger";

function Scenario2({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const group = useSelector((state) => state.group);

	const activeScenario = getScenarioNumByPath(location.pathname);

	const [intentosRestantes, setIntentosRestantes] = useState(2);
	const [time, setTime] = useState(0);
	const [isCompleted, setIsCompleted] = useState(false);
	const [wrongOptions, setWrongOptions] = useState(null);

	const QUIZ_ID = QUIZZES.TECNICAS_VALORACION;
	const SCENARIO_ID = SCENARIOS.SCENARIO_2;

	const touchSensor = useSensor(TouchSensor);
	const mouseSensor = useSensor(MouseSensor);

	const sensors = useSensors(touchSensor, mouseSensor);

	const FUNCION_MUSCULAR_2 = 1;
	const FUNCION_MUSCULAR_3 = 2;
	const COMP_CORPORAL_1 = 3;
	const COMP_CORPORAL_2 = 4;
	const COMP_CORPORAL_3 = 5;
	const COMP_CORPORAL_FUNCION_MUSCULAR_1 = 6;
	const COMP_CORPORAL_FUNCION_MUSCULAR_2 = 7;
	const COMP_CORPORAL_FUNCION_MUSCULAR_INFL = 8;

	const tagOptions = [
		{ id: FUNCION_MUSCULAR_2, value: "Función muscular (++)" },
		{
			id: FUNCION_MUSCULAR_3,
			value: "Función muscular (+++)",
		},
		{ id: COMP_CORPORAL_1, value: "Composición corporal (+)" },
		{ id: COMP_CORPORAL_2, value: "Composición corporal (++)" },
		{ id: COMP_CORPORAL_3, value: "Composición corporal (+++)" },
		{
			id: COMP_CORPORAL_FUNCION_MUSCULAR_1,
			value: "Composición corporal (+++) + función muscular (+)",
		},
		{
			id: COMP_CORPORAL_FUNCION_MUSCULAR_2,
			value: "Composición corporal (+++) + función muscular (+)",
		},
		{
			id: COMP_CORPORAL_FUNCION_MUSCULAR_INFL,
			value: "Composición corporal (++) + función muscular (+) + inflamación (++)",
		},
	];

	const OFFSET = tagOptions.length; //necessary for a proper behaviour of drag & drop library

	const initialTags = tagOptions.filter((tag) => tag.id !== COMP_CORPORAL_1);

	const [tags, setTags] = useState(initialTags);

	const [boxes, setBoxes] = useState([
		{
			id: COMP_CORPORAL_1 + OFFSET,
			tag: tagOptions.find((x) => x.id === COMP_CORPORAL_1),
		},
		{ id: COMP_CORPORAL_2 + OFFSET, tag: null },
		{
			id: COMP_CORPORAL_FUNCION_MUSCULAR_INFL + OFFSET,
			tag: null,
		},
		{ id: COMP_CORPORAL_FUNCION_MUSCULAR_1 + OFFSET, tag: null },
		{ id: COMP_CORPORAL_FUNCION_MUSCULAR_2 + OFFSET, tag: null },
		{ id: COMP_CORPORAL_3 + OFFSET, tag: null },
		{ id: FUNCION_MUSCULAR_2 + OFFSET, tag: null },
		{ id: FUNCION_MUSCULAR_3 + OFFSET, tag: null },
	]);

	const ENUNCIADO = `<div>
			<p class="txt22 txt-purple bold text-center">
				Tienes que demostrar a la gerente que estás al día en nuevas
				técnicas de valoración morfofuncional.
			</p>
			<div class="txt18 mt-1">
				<p>
					En la tabla <img class="mx-1" src=${iconTabla}></img>
					arrastra al lado de cada técnica
					la letra correspondiente al elemento medido teniendo en
					cuenta su gradación de validez entre paréntesis (+ = validez
					débil; ++ = media; +++ = fuerte).
				</p>
				<div class="d-flex align-items-center">
					<div>Cuando hayas completado toda la tabla, pulsa</div>
					<div class="d-flex align-items-center">
						<button class="btn-main txt8 w-fit-content px-2 mx-2" style="height: 25px;">
							VALIDAR
						</button>
						y comprueba el resultado.
					</div>
				</div>
				<br />
				<div class="d-flex align-items-center">
					<img class="me-2" src=${iconImportant}></img>
					<div>
						<span class="bold">¡Ojo!</span> Dos técnicas pueden
						medir lo mismo y con la misma gradación.
					</div>
				</div>
			</div>
		</div>`;

	const CORRECT_ANSWER_TEXT = `<div>
		<p class="txt22 txt-green bold">¡Correcto!</p>
		<p class="txt18">
			Esto es lo que mide cada técnica (entre paréntesis, gradación de
			validez):
		</p>
	</div>`;

	const INCORRECT_ANSWER_TEXT = `<div>
	<p class="txt22 txt-red bold">¡Vaya!</p>
	<p class="txt22 txt-red bold">No has conseguido completar correctamente la tabla.</p>
	<p class="txt18">
		Esta es la solución (entre paréntesis, gradación de validez):
	</p>
</div>`;

	const RECUERDA_SELECCIONAR_TEXT = `<div>
	<div class="txt22 txt-red bold">
		Recuerda que la tabla debe estar completamente rellenada para que se active el botón "Validar"
	</div>
</div>`;

	function getWrongAnswerText(numErrores) {
		return `<div>
		<p class="txt18">
			Hay <span class="txt-red bold">${numErrores} errores</span>
		</p>
		<p class="txt18">Te queda un intento. Habla con tus compañeros y consensuad la solución.</p>
		<p class="txt18">
			¡Os jugáis un sello!
		</p>				
		</div>`;
	}

	useEffect(() => {
		getAnswerByQuizAndGroup(QUIZ_ID, group?._id)
			.then((res) => {
				if (res.status === 200 && res.data.length > 0) {
					modalQuestionResolution({
						correct: false,
						allowOutsideClick: false,
						showCancelButton: false,
						showConfirmButton: true,
						customConfirmButtonHtml:
							QUESTION_ALREADY_ANSWERED_BUTTON,
						textHtml: QUESTION_ALREADY_ANSWERED_TEXT,
					}).then((res) => {
						if (res.isConfirmed)
							navigate(ROUTES.SCENARIO2_EXTRA_INTRO);
					});
					setIsCompleted(true);
				}
			})
			.catch((error) => {});
	}, []);

	const Draggable = (props) => {
		const { attributes, listeners, setNodeRef, transform } = useDraggable({
			id: props.id,
			disabled: isCompleted,
		});

		const style = {
			transform: CSS.Translate.toString(transform),
			lineHeight:
				props.id === COMP_CORPORAL_FUNCION_MUSCULAR_INFL ||
				props.id === COMP_CORPORAL_FUNCION_MUSCULAR_INFL + OFFSET
					? "18px"
					: "",
		};

		const box = boxes.find((x) => x.id === props?.id + OFFSET);
		const correct = !wrongOptions?.some((x) => x.id === box.id - OFFSET);

		return (
			<div
				className={`drag-drop-option ${
					!isCompleted ? "" : correct ? "bg-correct" : "bg-incorrect"
				} txt16 mt-2`}
				ref={setNodeRef}
				style={style}
				{...listeners}
				{...attributes}
			>
				{props.children}
			</div>
		);
	};

	const Droppable = (props) => {
		const { isOver, setNodeRef } = useDroppable({
			id: props.id,
			data: {
				origin: props.origin,
			},
		});

		const emptyTagListElem = props.origin;

		return (
			<>
				{emptyTagListElem ? (
					<div
						ref={setNodeRef}
						className="drag-drop-empty-option mt-2"
					></div>
				) : (
					<div
						className="drag-drop-target txt16 mt-2"
						style={{
							lineHeight:
								props.id ===
									COMP_CORPORAL_FUNCION_MUSCULAR_INFL ||
								props.id ===
									COMP_CORPORAL_FUNCION_MUSCULAR_INFL + OFFSET
									? "18px"
									: "",
						}}
						ref={setNodeRef}
					>
						{props.children}
					</div>
				)}
			</>
		);
	};

	const handleDragEnd = ({ active, over }) => {
		if (!active || !over) return;

		const draggedTag = tagOptions.find((x) => x.id === active?.id);
		const targetBox = boxes.find((x) => x.id === over?.id);

		const boxId = boxes.find((x) => x.tag?.id === draggedTag?.id)?.id;

		const updatedBoxes = boxes.map((b) =>
			b.id === targetBox?.id
				? {
						...b,
						tag: draggedTag,
				  }
				: b.id === boxId
				? { ...b, tag: null }
				: b
		);

		setBoxes(updatedBoxes);

		let updatedTags;

		if (over?.data?.current?.origin) {
			updatedTags = tagOptions.filter(
				(x) => tags.some((y) => y.id === x.id) || x.id === draggedTag.id
			);
		} else {
			updatedTags = tags.filter((x) => x.id !== draggedTag.id);
		}
		setTags(updatedTags);
	};

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	async function submitAnswer(correct) {
		const score = correct ? 1 : 0;
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			group: group?._id,
			score: score,
			time: time,
		})
			.then((res) => {
				if (res.status === 201) {
					setIsCompleted(true);
				}
			})
			.catch((error) => {});
	}

	function checkAnswers() {
		let normalizedBoxes = boxes.map((x) => ({ ...x, id: x.id - OFFSET }));
		let wrongOptions = normalizedBoxes.filter(
			(x) =>
				x.id !== x.tag?.id &&
				!(
					(x.id === COMP_CORPORAL_FUNCION_MUSCULAR_1 &&
						x.tag?.id === COMP_CORPORAL_FUNCION_MUSCULAR_2) ||
					(x.id === COMP_CORPORAL_FUNCION_MUSCULAR_2 &&
						x.tag?.id === COMP_CORPORAL_FUNCION_MUSCULAR_1)
				)
		);

		let correct = wrongOptions.length === 0;
		let completed = false;

		setWrongOptions(wrongOptions);

		if (correct) {
			modalQuestionResolution({
				correct: true,
				textHtml: CORRECT_ANSWER_TEXT,
				allowOutsideClick: false,
				showCancelButton: false,
				showConfirmButton: true,
				customConfirmButtonHtml: `<div>VER SOLUCIÓN</div>`,
			}).then((res) => {
				if (res.isConfirmed)
					modalPDF({
						pdf: solucionPDF,
						title: "Técnicas de valoración morfofuncional",
					});
			});
			completed = true;
		} else {
			if (intentosRestantes > 1)
				modalQuestionResolution({
					correct: false,
					textHtml: getWrongAnswerText(wrongOptions.length),
					allowOutsideClick: true,
				});
			else {
				modalQuestionResolution({
					correct: false,
					textHtml: INCORRECT_ANSWER_TEXT,
					allowOutsideClick: false,
					showCancelButton: false,
					showConfirmButton: true,
					customConfirmButtonHtml: `<div>VER SOLUCIÓN</div>`,
				}).then((res) => {
					if (res.isConfirmed)
						modalPDF({
							pdf: solucionPDF,
							title: "Técnicas de valoración morfofuncional",
						});
				});
				completed = true;
			}
		}
		setIntentosRestantes(intentosRestantes - 1);

		if (completed) submitAnswer(correct);
	}

	return (
		<>
			<div className="h-100 bg-blue-plain">
				<div className="container-fluid px-5 pt-3 h-100">
					<div className="d-flex align-items-start justify-content-between">
						<div className="txt20 txt-blue bold mt-3">
							Nosotros hemos rellenado la primera casilla… El
							resto te toca a ti.{" "}
						</div>
						<Intentos
							intentosRestantes={intentosRestantes}
						></Intentos>
					</div>
					<DndContext
						sensors={sensors}
						onDragEnd={handleDragEnd}
						modifiers={[restrictToWindowEdges]}
					>
						<div
							className="row d-flex align-items-start"
							style={{ marginTop: "-15px" }}
						>
							<div className="col-4 d-flex flex-column align-items-center">
								<div className="drag-drop-header txt16 txt-white bold">
									Técnica
								</div>
								<div className="drag-drop-header-option txt16 bold mt-2">
									Antropometría clásica (peso, talla, IMC)
								</div>
								<div className="drag-drop-header-option txt16 bold mt-2">
									Pliegues, circunferencias y perímetros
								</div>
								<div className="drag-drop-header-option txt16 bold mt-2">
									Impedanciometría
								</div>
								<div className="drag-drop-header-option txt16 bold mt-2">
									Ecografía nutricional
								</div>
								<div className="drag-drop-header-option txt16 bold mt-2">
									TAC/RMN
								</div>
								<div className="drag-drop-header-option txt16 bold mt-2">
									DEXA (absorciometría de rayos X de energía
									dual)
								</div>
								<div className="drag-drop-header-option txt16 bold mt-2">
									Dinamometría
								</div>
								<div className="drag-drop-header-option txt16 bold mt-2">
									Pruebas de capacidad funcional
								</div>
							</div>
							<div className="col-4 d-flex flex-column align-items-center">
								<div className="drag-drop-header txt16 txt-white bold">
									¿Qué mide?
								</div>
								{boxes.map((box) => {
									return (
										<>
											{box.tag === null ? (
												<Droppable
													id={box.id}
												></Droppable>
											) : (
												<Draggable id={box.tag.id}>
													{box.tag.value}
												</Draggable>
											)}
										</>
									);
								})}
							</div>
							<div className="col-4 d-flex flex-column align-items-center">
								<div className="drag-drop-header bg-transparent txt16 bold">
									Elemento medido
								</div>
								<div className="w-100">
									{tagOptions.map((tag, index) => {
										const isDropped = !tags.some(
											(x) => x?.id === tag?.id
										);
										return (
											<>
												{!isDropped ? (
													<Draggable id={tag.id}>
														{tag.value}
													</Draggable>
												) : (
													<Droppable
														id={tag.id}
														origin={true}
													>
														{
															tagOptions.find(
																(x) =>
																	x.id ===
																	tag.id
															)?.value
														}
													</Droppable>
												)}
											</>
										);
									})}
								</div>
							</div>
						</div>
					</DndContext>
					<div className="d-flex justify-content-between align-items-center">
						<div className="d-flex align-items-center">
							<img className="me-2" src={iconImportant}></img>
							<div className="txt16 txt-blue">
								<span className="bold">¡Ojo!</span> Dos técnicas
								pueden medir lo mismo y con la misma gradación.
							</div>
						</div>
						<div className="d-flex align-items-center mt-3">
							<button
								className="btn-main-cancel me-3 txt-blue border-blue w-fit-content p-4"
								onClick={() => {
									modalEnunciado({
										html: ENUNCIADO,
									});
								}}
							>
								VER ENUNCIADO
							</button>
							{!isCompleted ? (
								<button
									className="btn-main bold"
									onClick={() => {
										if (boxes.some((x) => x.tag == null)) {
											modalQuestionResolution({
												correct: false,
												textHtml:
													RECUERDA_SELECCIONAR_TEXT,
												allowOutsideClick: true,
												close: true,
											});
										} else {
											checkAnswers();
										}
									}}
								>
									<div className="text-btn-main">VALIDAR</div>
								</button>
							) : (
								<NextButton
									navigateTo={ROUTES.STAMP_PROGRESS}
									navigateToAfter={
										ROUTES.SCENARIO2_EXTRA_INTRO
									}
									activeScenario={activeScenario}
								></NextButton>
							)}
						</div>
					</div>
				</div>
				<ScenarioBar activeScenario={activeScenario} />
			</div>
		</>
	);
}

export default injectIntl(Scenario2);
