import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getGroupById } from "../api/group";
import fotoFinalContentos from "../assets/foto-final-contentos.png";
import fotoFinalNeutros from "../assets/foto-final-neutros2.png";
import fotoFinalTristes from "../assets/foto-final-tristes.png";
import globoFinal from "../assets/globo-final.png";
import NextButton from "../components/NextButton";
import ScenarioBar from "../components/ScenarioBar";
import { ROUTES } from "../utils/constants";
import { getScenarioNumByPath } from "../utils/helpers";

function FinalScene({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const group = useSelector((state) => state.group);

	const activeScenario = getScenarioNumByPath(location.pathname);

	const [stampProgress, setStampProgress] = useState([]);

	const achievedStamps = stampProgress.filter((x) => x).length;

	useEffect(() => {
		if (group) {
			getGroupById(group?._id)
				.then((res) => {
					if (res.status === 200) {
						let group = res.data;
						let stamp1 = group?.stamp1;
						let stamp2 = group?.stamp2;
						let stamp3 = group?.stamp3;
						let stamp4 = group?.stamp4;
						let stamp5 = group?.stamp5;

						let stamps = [stamp1, stamp2, stamp3, stamp4, stamp5];

						setStampProgress(stamps);
					}
				})
				.catch((error) => {});
		}
	}, []);

	const CONTENT = (
		<div>
			{achievedStamps === 5 ? (
				<div>
					<div className="txt22 txt-white bold">
						Entras en el despacho de Carmen y le pones encima de la
						mesa, con una sonrisa triunfante, el papel completamente
						sellado.
					</div>
					<div className="txt22 txt-white bold mt-4">
						Ella también está contenta. Saca un grueso documento de
						un cajón mientras te dice:
					</div>
				</div>
			) : achievedStamps >= 3 ? (
				<div>
					<div className="txt22 txt-white bold">
						Entras en el despacho de Carmen y le pones encima de la
						mesa el papel con los sellos que has conseguido. «Y
						ahora, ¿qué?», le preguntas.
					</div>
					<div className="txt22 txt-white bold mt-4">
						Ella te responde:
					</div>
				</div>
			) : (
				<div>
					<div className="txt22 txt-white bold">
						Entras en el despacho de Carmen y le pones encima de la
						mesa el papel de los sellos. «Y ahora, ¿qué?», le
						preguntas.
					</div>
					<div className="txt22 txt-white bold mt-4">
						Ella suspira y te responde:
					</div>
				</div>
			)}
		</div>
	);

	return (
		<div className="h-100 bg-final-scene">
			<div className="container-fluid pt-4 h-100">
				<div className="row d-flex justify-content-center h-100">
					<div
						className="col-8 text-center"
						style={{ maxWidth: "60%" }}
					>
						{CONTENT}
						<img
							className="mt-4 pt-2"
							src={
								achievedStamps === 5
									? fotoFinalContentos
									: achievedStamps >= 3
									? fotoFinalNeutros
									: fotoFinalTristes
							}
							width={"100%"}
						></img>
						<div
							style={{
								position: "relative",
								marginTop: "-48%",
								marginLeft: "30%",
								width: "100%",
							}}
						>
							<img src={globoFinal} width={"100%"}></img>
							<div
								className="txt-blue txt16 font-chalkboard"
								style={{
									position: "absolute",
									width: "85%",
									top: "22%",
									left: "9%",
								}}
							>
								{achievedStamps === 5 ? (
									<>
										Estaba tan segura de que ibas a superar
										mis pruebas que he ido avanzando
										trabajo. Aquí tienes el plan de
										implantación de una Unidad de Nutrición
										Clínica en nuestro hospital. Revísalo
										bien y nos vemos en 15 días para
										ponernos manos a la obra. ¡Ah! ¡Y cuento
										contigo para que lo lideres!
									</>
								) : achievedStamps >= 3 ? (
									<>
										Bueno, es cierto que algunas pruebas
										eran muy difíciles. De todos modos,
										aunque no hayas conseguido los 5 sellos,
										no deberíamos dejar de lado este
										proyecto. ¿Qué te parece si te formas en
										las áreas en las que has fallado y
										retomamos este tema en unos meses? Y
										cuento contigo para que lo lideres, ¿eh?
									</>
								) : (
									<>
										Lo cierto es que algunas pruebas eran
										muy difíciles. De todos modos, aunque no
										hayas conseguido muchos sellos, no
										deberíamos dejar de lado este proyecto.
										¿Qué te parece si amplías tu formación y
										retomamos este tema el año que viene?
										Tendrías que liderarlo tú, ¿eh?
									</>
								)}
							</div>
						</div>
					</div>
				</div>
				<div
					className="px-4"
					style={{
						position: "absolute",
						right: "0px",
						bottom: "10%",
					}}
				>
					<NextButton
						navigateTo={ROUTES.CONGRATULATIONS}
						activeScenario={activeScenario}
					></NextButton>
				</div>
			</div>
			<ScenarioBar activeScenario={activeScenario} />
		</div>
	);
}

export default injectIntl(FinalScene);
