import React, { useState } from "react";
import icochevrondown from "../assets/scenariobar/flecha-scenario-bar-down.svg";
import icochevronup from "../assets/scenariobar/flecha-scenario-bar-up.svg";
import icocompleted from "../assets/scenariobar/ico-completed.svg";
import icoLockClosed from "../assets/candado-cerrado.svg";
import icoLockOpenedWhite from "../assets/candado-abierto-blanco.svg";

function ScenarioBar({ activeScenario, expanded }) {
	const [scenarioBarOpen, setScenarioBarOpen] = useState(expanded || false);

	function handleClick(e) {
		if (!scenarioBarOpen) setScenarioBarOpen(true);
		else setScenarioBarOpen(false);
	}

	const getClasses = (idx) => {
		if (idx < activeScenario) return "bg-inactive-scenario-bar";
		else if (idx === activeScenario) return "bg-active-scenario-bar bold";
		return "";
	};

	const getIcon = (idx) => {
		if (idx === activeScenario) return icoLockOpenedWhite;
		else if (idx < activeScenario) return icocompleted;
		return icoLockClosed;
	};

	return (
		<div
			className={
				scenarioBarOpen
					? "scenarioBar py-2 px-3 active"
					: "scenarioBar py-2 px-3 inactive"
			}
		>
			<div className="buttonDown" onClick={handleClick}>
				{scenarioBarOpen && (
					<img src={icochevrondown} alt="Esconder menú" />
				)}
				{!scenarioBarOpen && (
					<img src={icochevronup} alt="Mostrar menú" />
				)}
			</div>
			<div className="row h-100 align-items-center">
				{!scenarioBarOpen && (
					<div className="scenarioIndicator">Escenarios</div>
				)}

				<div className="col px-2">
					<div
						className={`scenarioBox ${getClasses(
							1
						)} d-flex align-items-center justify-content-center`}
					>
						<div className="lock">
							<img src={getIcon(1)} />
						</div>
						<div
							className="text-center px-2"
							style={{ maxWidth: "95%" }}
						>
							<div className="mt-1">Afirmaciones importantes</div>
						</div>
					</div>
				</div>
				<div className="col px-2">
					<div
						className={`scenarioBox  ${getClasses(
							2
						)} d-flex align-items-center justify-content-center`}
					>
						<div className="lock">
							<img src={getIcon(2)} />
						</div>
						<div
							className="text-center px-2"
							style={{ maxWidth: "95%" }}
						>
							<div className="mt-1">
								Valoración morfofuncional. Nuevas técnicas
							</div>
						</div>
					</div>
				</div>
				<div className="col px-2">
					<div
						className={`scenarioBox  ${getClasses(
							3
						)} d-flex align-items-center justify-content-center`}
					>
						<div className="lock">
							<img src={getIcon(3)} />
						</div>
						<div
							className="text-center px-2"
							style={{ maxWidth: "95%" }}
						>
							<div className="mt-1">
								Dietoterapia y suplementación oral
							</div>
						</div>
					</div>
				</div>
				<div className="col px-2">
					<div
						className={`scenarioBox  ${getClasses(
							4
						)} d-flex align-items-center justify-content-center`}
					>
						<div className="lock">
							<img src={getIcon(4)} />
						</div>
						<div
							className="text-center px-2"
							style={{ maxWidth: "95%" }}
						>
							<div className="mt-1">
								Nutrición enteral y parenteral
							</div>
						</div>
					</div>
				</div>
				<div className="col px-2">
					<div
						className={`scenarioBox  ${getClasses(
							5
						)} d-flex align-items-center justify-content-center`}
					>
						<div className="lock">
							<img src={getIcon(5)} />
						</div>
						<div
							className="text-center px-2"
							style={{ maxWidth: "95%" }}
						>
							<div className="mt-1">
								Nutrición enteral domiciliaria
							</div>
						</div>
					</div>
				</div>
				<div className="col px-2">
					<div
						className={`scenarioBox  ${getClasses(
							6
						)} d-flex align-items-center justify-content-center`}
					>
						<div className="lock">
							<img src={getIcon(6)} />
						</div>
						<div
							className="text-center px-1"
							style={{ maxWidth: "95%" }}
						>
							<div className="mt-1">Supertest</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default ScenarioBar;
