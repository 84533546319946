import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getGroupById } from "../api/group";
import carpetaSellos from "../assets/carpeta-sello.png";
import donutBlue from "../assets/donut-blue.png";
import dossierScenario1 from "../assets/dossier-scenario1.png";
import dossierScenario2 from "../assets/dossier-scenario2.png";
import dossierScenario3 from "../assets/dossier-scenario3.png";
import dossierScenario4 from "../assets/dossier-scenario4.png";
import dossierScenario5 from "../assets/dossier-scenario5.png";
import selloP1NotAchieved from "../assets/popup-sello-p1-not-achieved.png";
import selloP1Achieved from "../assets/popup-sello1-achieved.png";
import selloP2Achieved from "../assets/popup-sello2-achieved.png";
import selloP3Achieved from "../assets/popup-sello3-achieved.png";
import selloP4Achieved from "../assets/popup-sello4-achieved.png";
import selloP5Achieved from "../assets/popup-sello5-achieved.png";
import selloGris from "../assets/sello-inactive.svg";
import selloRojo from "../assets/sello-rojo.svg";
import selloVerde from "../assets/sello-verde.svg";
import semiDonut from "../assets/semidonut.png";
import NextButton from "../components/NextButton";
import ScenarioBar from "../components/ScenarioBar";
import { getLocationAfterStampProgressScreen } from "../utils/helpers";
import { modalSello } from "../utils/logger";

function StampProgress({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();
	const [group, setGroup] = useState(useSelector((state) => state.group));
	const [refresh, setRefresh] = useState(false);
	const [lastStamp, setLastStamp] = useState(null);

	const activeScenario =
		prevState?.state?.activeScenario || group?.actualScenario;

	const navigateTo =
		prevState?.state?.navigateTo ||
		getLocationAfterStampProgressScreen(lastStamp);

	const [stampProgress, setStampProgress] = useState([
		null,
		null,
		null,
		null,
		null,
	]);

	function getSelloById(id) {
		let index = id - 1;
		if (stampProgress[index] === null) return selloGris;
		if (stampProgress[index]) return selloVerde;
		if (!stampProgress[index]) return selloRojo;
	}

	function getDossierById(id) {
		if (id === 1) return dossierScenario1;
		if (id === 2) return dossierScenario2;
		if (id === 3) return dossierScenario3;
		if (id === 4) return dossierScenario4;
		if (id === 5) return dossierScenario5;
	}

	function getImageOfStampNumber(stampNumber, achieved) {
		if (stampNumber === 1) {
			if (achieved) return selloP1Achieved;
			else return selloP1NotAchieved;
		} else if (stampNumber === 2) {
			if (achieved) return selloP2Achieved;
			else return selloP1NotAchieved;
		} else if (stampNumber === 3) {
			if (achieved) return selloP3Achieved;
			else return selloP1NotAchieved;
		} else if (stampNumber === 4) {
			if (achieved) return selloP4Achieved;
			else return selloP1NotAchieved;
		} else if (stampNumber === 5) {
			if (achieved) return selloP5Achieved;
			else return selloP1NotAchieved;
		}
	}

	function getTextOfStampNumber(stampNumber, achieved) {
		if (stampNumber === 1) {
			if (achieved)
				return `<div>
						<p class="txt-blue bold txt22">
							Ya tienes el primer sello.
						</p>
						<p class="txt-blue bold txt22">¡Sigue adelante!</p>
					</div>`;
			else
				return `<div>
						<div class="d-flex justify-content-center">
							<div class="position-relative w-fit-content">
								<img src=${dossierScenario1} width="170"></img>
								<div
									class="position-absolute"
									style="top: 47%; right: 40%;"
								>
									<img src=${selloRojo} width="55"></img>
								</div>
							</div>
						</div>
						<p class="txt-blue bold txt22 mt-3">
							No has conseguido el primer sello, pero puedes
							intentar hacerte con todos los que faltan.
						</p>
						<p class="txt-blue bold txt22">¡Adelante!</p>
					</div>`;
		} else if (stampNumber === 2) {
			if (achieved)
				return `<div>
						<p class="txt-blue bold txt22">
							Has conseguido el segundo de los sellos.
						</p>
						<p class="txt-blue bold txt22">¡A por el tercero!</p>
					</div>`;
			else
				return `<div>
						<div class="d-flex justify-content-center">
							<div class="position-relative" style="margin-top: -20px;">
								<img class="ms-5" src=${dossierScenario2} width="260" style="transform: rotate(15deg);"></img>
								<div
									class="position-absolute"
									style="top: 45%; right: 44%;"
								>
									<img src=${selloRojo} width="55"></img>
								</div>
							</div>
						</div>
						<p class="txt-blue bold txt22" style="margin-top: -20px;">
							No has conseguido el segundo sello, pero puedes
							intentar hacerte con todos los que faltan.
						</p>
						<p class="txt-blue bold txt22">¡Sigue adelante!</p>
					</div>`;
		} else if (stampNumber === 3) {
			if (achieved)
				return `<div>
					<p class="txt-blue bold txt22">
						Has conseguido el tercero de los sellos.
					</p>
					<p class="txt-blue bold txt22">¡A por el cuarto!</p>
				</div>`;
			else
				return `<div>
						<div class="d-flex justify-content-center">
							<div class="position-relative">
								<img
									class="ms-3"
									src=${dossierScenario3}
									width="180"
								></img>
								<div
									class="position-absolute"
									style="top: 49%; right: 41%;"
								>
									<img src=${selloRojo} width="55"></img>
								</div>
							</div>
						</div>
						<p class="txt-blue bold txt22">
							No has conseguido el tercer sello, pero puedes
							intentar hacerte con los que faltan.
						</p>
						<p class="txt-blue bold txt22">¡Adelante!</p>
					</div>`;
		} else if (stampNumber === 4) {
			if (achieved)
				return `<div>
					<p class="txt-blue bold txt22">
						Has conseguido el cuarto de los sellos.
					</p>
					<p class="txt-blue bold txt22">¡A por el último!</p>
				</div>`;
			else
				return `<div>
						<div class="d-flex justify-content-center">
							<div
								class="position-relative"
								style="margin-top: -20px;"
							>
								<img
									class="ms-2"
									src=${dossierScenario4}
									width="210"
									style="transform: rotate(15deg);"
								></img>
								<div
									class="position-absolute"
									style="top: 51%; right: 40%;"
								>
									<img src=${selloRojo} width="55"></img>
								</div>
							</div>
						</div>
						<p class="txt-blue bold txt22">
							No has conseguido el cuarto sello, pero puedes
							intentar hacerte con el último.
						</p>
						<p class="txt-blue bold txt22">¡Adelante!</p>
					</div>`;
		} else if (stampNumber === 5) {
			if (achieved)
				return `<div>
					<p class="txt-blue bold txt22">
						¡Enhorabuena!
					</p>
					<p class="txt-blue bold txt22">¡Has conseguido el último de los sellos!</p>
				</div>`;
			else
				return `<div>
						<div class="d-flex justify-content-center">
							<div class="position-relative">
								<img
									class="ms-3"
									src=${dossierScenario5}
									width="170"
								></img>
								<div
									class="position-absolute"
									style="top: 48%; right: 38%;"
								>
									<img src=${selloRojo} width="55"></img>
								</div>
							</div>
						</div>
						<p class="txt-blue bold txt22">
							No has conseguido el último sello.
						</p>
						<p class="txt-blue bold txt22">¡Lástima!</p>
					</div>`;
		}
	}

	function handleLastAnsweredStamp(stamps) {
		let lastAnsweredStamp = 1;
		let stampAchieved = false;

		stamps.forEach((element, index) => {
			if (element !== null) {
				lastAnsweredStamp = index + 1;
				stampAchieved = element;
			}
		});

		setLastStamp(lastAnsweredStamp);

		modalSello({
			imageSello: getImageOfStampNumber(lastAnsweredStamp, stampAchieved),
			textHtml: getTextOfStampNumber(lastAnsweredStamp, stampAchieved),
			showConfirmButton: !stampAchieved,
			achieved: stampAchieved,
		});
	}

	useEffect(() => {
		if (group) {
			getGroupById(group?._id)
				.then((res) => {
					if (res.status === 200) {
						let group = res.data;
						let stamp1 = group?.stamp1;
						let stamp2 = group?.stamp2;
						let stamp3 = group?.stamp3;
						let stamp4 = group?.stamp4;
						let stamp5 = group?.stamp5;

						let stamps = [stamp1, stamp2, stamp3, stamp4, stamp5];

						setStampProgress(stamps);
						handleLastAnsweredStamp(stamps);
					}
				})
				.catch((error) => {});
		}
	}, [refresh]);

	const DossierWithStamp = ({
		id,
		dossierWidth,
		stampWidth,
		stampBottomPosition,
		stampLeftPosition,
	}) => {
		const imageDossier = getDossierById(id);
		const imageSello = getSelloById(id);

		return (
			<div className="position-relative">
				<img
					src={imageDossier}
					style={{ width: dossierWidth }}
					alt="dossier"
				/>
				<img
					src={imageSello}
					style={{
						bottom: stampBottomPosition,
						left: stampLeftPosition,
					}}
					width={stampWidth}
					className="position-absolute"
					alt="sello"
				/>
			</div>
		);
	};

	return (
		<div className="h-100 bg-sellos">
			<div className="container-fluid px-5 pt-4 h-100">
				<div
					className="d-flex align-items-end justify-content-center"
					style={{ marginLeft: "-10%" }}
				>
					<div>
						<img src={carpetaSellos} width={440}></img>
					</div>
					<div style={{ marginLeft: "-5%" }}>
						<DossierWithStamp
							id={1}
							dossierWidth={"27vh"}
							stampBottomPosition={"25%"}
							stampLeftPosition={"25%"}
							stampWidth={"35.5%"}
						></DossierWithStamp>
					</div>
					<div className="ms-4">
						<DossierWithStamp
							id={2}
							dossierWidth={"40vh"}
							stampBottomPosition={"28%"}
							stampLeftPosition={"27%"}
							stampWidth={"24%"}
						></DossierWithStamp>
					</div>
					<img
						src={semiDonut}
						width={"12%"}
						className="mb-5 pb-5"
						style={{
							marginLeft: "-130px",
							zIndex: "1",
						}}
					></img>
				</div>
				<div className="d-flex align-items-start justify-content-center ms-4">
					<DossierWithStamp
						id={3}
						dossierWidth={"27vh"}
						stampBottomPosition={"23%"}
						stampLeftPosition={"22%"}
						stampWidth={"35.5%"}
					></DossierWithStamp>
					<div className="ms-5">
						<DossierWithStamp
							id={4}
							dossierWidth={"32vh"}
							stampBottomPosition={"23%"}
							stampLeftPosition={"34%"}
							stampWidth={"30%"}
						></DossierWithStamp>
					</div>
					<div className="ms-5">
						<DossierWithStamp
							id={5}
							dossierWidth={"26vh"}
							stampBottomPosition={"23%"}
							stampLeftPosition={"25%"}
							stampWidth={"37%"}
						></DossierWithStamp>
					</div>
					<img src={donutBlue} width={"8%"} className="pt-4"></img>
				</div>
				<div
					className="px-5"
					style={{
						position: "absolute",
						bottom: "6vh",
						right: "0",
					}}
				>
					<NextButton
						navigateTo={navigateTo}
						activeScenario={activeScenario}
					></NextButton>
				</div>
			</div>
			<ScenarioBar activeScenario={activeScenario}></ScenarioBar>
		</div>
	);
}

export default injectIntl(StampProgress);
