import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioNumByPath } from "../../utils/helpers";

function Scenario2ExtraSolucion({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const activeScenario = getScenarioNumByPath(location.pathname);

	const [options, setOptions] = useState([
		{
			id: 1,
			letter: "c",
			text: "Evaluar la masa y la calidad muscular",
		},
		{
			id: 2,
			letter: "c",
			text: "Evaluar la fuerza muscular",
			selected: false,
			correct: false,
		},
		{
			id: 3,
			letter: "b",
			text: "La hidratación corporal",
		},
	]);

	const SelectOption = ({ optionId }) => {
		let option = options.find((x) => x.id === optionId);

		return (
			<div style={{ height: "5vh", width: "100%" }}>
				<div
					className="select-option-rectangle selected txt-blue bold"
					style={{ width: "320px" }}
				>
					<div
						className="select-option-number-container txt-blue bold txt16"
						style={{
							width: "15%",
							backgroundColor: "#87CA1B",
						}}
					>
						{option?.letter}.
					</div>
					<div className="ms-2 txt16" style={{ maxWidth: "81%" }}>
						{option?.text}
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="h-100 bg-mult-choice-solution">
			<div className="container-fluid px-5 pt-5 h-100">
				<div className="">
					<div className="row d-flex align-items-center justify-content-center">
						<div className="col-5 d-flex flex-column align-items-start">
							<div className="txt24 txt-blue bold">
								1. La ecografía nutricional
							</div>
							<div className="txt24 txt-blue bold">permite:</div>
							<div className="mt-4 pt-1">
								<SelectOption optionId={1}></SelectOption>
							</div>
						</div>
						<div className="col-7 d-flex justify-content-start">
							<div className="txt-blue txt18">
								La{" "}
								<span className="bold">
									ecografía nutricional
								</span>{" "}
								se basa en la evaluación de un músculo
								determinado para conocer su{" "}
								<span className="bold">tamaño</span> (masa
								muscular) a partir de parámetros cuantitativos
								(área muscular, circunferencia, etc.) y su{" "}
								<span className="bold">calidad</span> a partir
								de parámetros cualitativos (relación de sus ejes
								o ecogenicidad muscular)
								<sup>9</sup>.
							</div>
						</div>
					</div>
					<div className="row d-flex align-items-center justify-content-center mt-3 pt-4">
						<div className="col-5 d-flex flex-column align-items-start">
							<div className="txt24 txt-blue bold">
								2. La dinamometría de mano permite:
							</div>
							<div className="mt-4 pt-1">
								<SelectOption optionId={2}></SelectOption>
							</div>
						</div>
						<div className="col-7 d-flex justify-content-start">
							<div
								className="txt-blue txt18"
								style={{ maxWidth: "95%" }}
							>
								La{" "}
								<span className="bold">
									dinamometría de mano
								</span>{" "}
								es una medida de la fuerza muscular y está
								relacionada con la{" "}
								<span className="bold">
									velocidad de la marcha
								</span>
								. No evalúa de manera completa la capacidad
								funcional, dado que, para ello, son necesarios
								test que puedan integrar fuerza, equilibrio y
								capacidad de repetición
								<sup>5</sup>.
							</div>
						</div>
					</div>
					<div className="row d-flex align-items-center justify-content-center mt-3 pt-4">
						<div className="col-5 d-flex flex-column align-items-start">
							<div className="txt24 txt-blue bold">
								3. La determinación básica en la bioimpedancia
								eléctrica es:
							</div>
							<div className="mt-4 pt-1">
								<SelectOption optionId={3}></SelectOption>
							</div>
						</div>
						<div className="col-7 d-flex justify-content-start">
							<div className="txt-blue txt18">
								Las determinaciones realizadas en la{" "}
								<span className="bold">
									bioimpedanciometría eléctrica
								</span>{" "}
								se basan en la{" "}
								<span className="bold">
									capacidad de conducción de electricidad a
									través de un sistema
								</span>
								, por lo que evaluaremos la hidratación y las
								membranas celulares. La determinación de la masa
								muscular y la masa grasa se realiza mediante
								fórmulas estimativas
								<sup>10</sup>.
							</div>
						</div>
					</div>
				</div>
				<div
					className="px-5"
					style={{
						position: "absolute",
						bottom: "12%",
						right: "0px",
					}}
				>
					<NextButton
						navigateTo={ROUTES.TRANSITION_SCENARIOS}
						activeScenario={3}
					></NextButton>
				</div>
			</div>
			<ScenarioBar activeScenario={activeScenario} />
		</div>
	);
}

export default injectIntl(Scenario2ExtraSolucion);
