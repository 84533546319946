import { combineReducers } from "redux";
import {
	SET_GAME,
	SET_GROUP,
	UPDATE_SCENARIO,
	UPDATE_TIME
} from "./actions";

const initialState = {
	game: null,
	group: null,
	scenario: null,
};

function game(state = initialState.game, action) {
	switch (action.type) {
		case SET_GAME:
			return action.game;
		default:
			return state;
	}
}

function group(state = initialState.group, action) {
	switch (action.type) {
		case SET_GROUP:
			return action.group;
		case UPDATE_TIME:
			let newState2 = {
				...state,
				time: action.time,
			};
			return newState2;
		case UPDATE_SCENARIO:
			let newState3 = {
				...state,
				activeScenario: action.activeScenario,
			};
			return newState3;
		default:
			return state;
	}
}

export default combineReducers({ game, group });
