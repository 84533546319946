export const SET_GAME = "SET_GAME";
export const SET_GROUP = "SET_GROUP";
export const UPDATE_SCENARIO = "UPDATE_SCENARIO";
export const UPDATE_TIME = "UPDATE_TIME";
export const SUM_SCORES = "SUM_SCORES";

export function setGame(game) {
	return {
		type: SET_GAME,
		game,
	};
}
export function setGroup(group) {
	return {
		type: SET_GROUP,
		group,
	};
}
export function updateScenario(activeScenario) {
	return {
		type: UPDATE_SCENARIO,
		activeScenario,
	};
}
export function updateTime(time) {
	return {
		type: UPDATE_TIME,
		time,
	};
}
