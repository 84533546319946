import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import cinco from "../../assets/cinco.svg";
import dos from "../../assets/dos.svg";
import habla from "../../assets/habla-compañeros.png";
import iconImportant from "../../assets/importante.svg";
import masInfo from "../../assets/mas-info.svg";
import tres from "../../assets/tres.svg";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioNumByPath } from "../../utils/helpers";

function Scenario1Instructions({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const activeScenario = getScenarioNumByPath(location.pathname);

	return (
		<>
			<div className="h-100 bg-preguntas-3">
				<div className="container-fluid px-5 pt-4 h-100">
					<div className="row">
						<div className="col-5 d-flex flex-column align-items-start">
							<p className="txt30 txt-purple bold line-height-40">
								Habla con tus compañeros
							</p>
							<div className="txt20 txt-blue">
								<p>
									En la siguiente batería de afirmaciones
									sobre nutrición clínica, hay:
								</p>
								<div className="d-flex align-items-center position-relative">
									<img className="me-2" src={cinco}></img>
									<span
										className="txt20 txt-white bold position-absolute"
										style={{
											left: "13.5px",
											bottom: "7px",
										}}
									>
										5
									</span>
									<span>fundamentales</span>
								</div>
								<div className="d-flex align-items-center position-relative">
									<img className="me-2" src={tres}></img>
									<span
										className="txt20 txt-white bold position-absolute"
										style={{
											left: "13.5px",
											bottom: "7px",
										}}
									>
										3
									</span>
									<span>adecuadas</span>
								</div>
								<div className="d-flex align-items-center position-relative">
									<img className="me-2" src={dos}></img>
									<span
										className="txt20 txt-white bold position-absolute"
										style={{
											left: "13.5px",
											bottom: "7px",
										}}
									>
										2
									</span>
									<span>innecesarias</span>
								</div>
								<p></p>
								<p>
									Deberás seleccionar y comprobar las
									afirmaciones una a una. Superarás la prueba
									si seleccionas las{" "}
									<span className="bold">
										5 respuestas fundamentales
									</span>{" "}
									y no eliges ninguna de las{" "}
									<span className="bold">2 innecesarias</span>
									.
								</p>
								<div className="d-flex align-items-start">
									<img
										className="me-2 mt-2"
										src={iconImportant}
									></img>
									<span>
										Una vez comprobada una afirmación,
										obtendrás la respuesta con algunos datos
										interesantes <img src={masInfo}></img>.
									</span>
								</div>
								<p></p>
								<p>
									Lee las respuestas con atención, ya que
									puedes necesitar algunos de esos datos más
									adelante.
								</p>
							</div>
						</div>
						<div className="col-7 d-flex justify-content-start align-items-center">
							<div className="d-flex flex-column align-items-end">
								<img src={habla} width={"80%"} />
								<div
									className="position-absolute"
									style={{ bottom: "20%" }}
								>
									<NextButton
										navigateTo={ROUTES.SCENARIO1}
										activeScenario={activeScenario}
									></NextButton>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ScenarioBar activeScenario={activeScenario} />
			</div>
		</>
	);
}

export default injectIntl(Scenario1Instructions);
