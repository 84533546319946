import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
	getAvailableTableByGameId,
	getDefaultGame,
	getGameById,
} from "../api/game";
import { getGroupByTableAndGame, postGroup } from "../api/group";
import flechaAvanzar from "../assets/flecha-avanzar.svg";
import logo from "../assets/logo-receta-exito.svg";
import nutriciaAzul from "../assets/nutricia-azul.svg";
import addIcon from "../assets/register/add-icon.svg";
import icofoto from "../assets/register/ico-foto.svg";
import removeIcon from "../assets/register/remove-icon.svg";
import { setGame, setGroup } from "../redux/actions";
import { useLang } from "../translations/i18n";
import { ROUTES } from "../utils/constants";
import { getGroupTable, setGroupTable, updateTimer } from "../utils/helpers";
import { alertError, alertGroup, modalTableNum } from "../utils/logger";

function getEmptyGroup() {
	return {
		fullName: "",
		game: null,
		image: "",
		tableNum: "",
		members: [],
	};
}

function Register({ intl }) {
	const [activeGame, setActiveGame] = useState(null);
	const [activeGroup, setActiveGroup] = useState(getEmptyGroup());
	const [actualMember, setActualMember] = useState("");
	const [members, setMembers] = useState([]);
	const [selectedImage, setSelectedImage] = useState(null);
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();

	const noTimeScreens = [
		ROUTES.START_GAME,
		ROUTES.END,
		ROUTES.RESULTS,
		ROUTES.GAMEOVER,
	];

	useEffect(() => {
		window.scrollTo(0, 0);
		const asyncFetch = async () => {
			if (prevState?.state?.game) {
				getGameById(prevState.state.game).then((res) => {
					if (res.status === 200) {
						handleData(res.data);
					}
				});
			} else {
				getDefaultGame().then((res) => {
					if (res.status === 200) {
						let defaultGame = res.data;
						handleData(defaultGame);
					}
				});
			}
		};

		asyncFetch();
	}, []);

	function handleData(game) {
		dispatch(setGame(game));
		setActiveGame(game);
		setActiveGroup((prevGroup) => ({
			...prevGroup,
			game: game._id,
		}));
		let table = getGroupTable();
		if (table) {
			getGroupByTableAndGame(table, game._id).then((result) => {
				if (result.status === 200) reconnect(result.data.group);
			});
		} else {
			if (game.demo)
				getAvailableTableByGameId(game._id)
					.then((tableRes) => {
						if (tableRes.status === 200) {
							let tableNum = tableRes.data;
							modalTableNum({ tableNum: tableNum });
							handleGroupTableChange(tableNum.toString());
						}
					})
					.catch((error) => {
						alertError({
							error: " ",
							customMessage:
								error?.response?.data?.message || " ",
						});
					});
		}
	}

	function saveGroup(e) {
		e.preventDefault();
		if (members.length) {
			activeGroup.members = members;
			postGroup(activeGroup, selectedImage)
				.then((res) => {
					let group = res.data;
					if (res.status === 201) {
						group.time = 0;
						dispatch(setGroup(group));
						setGroupTable(group.tableNum);
						navigate(ROUTES.START_GAME);
					}
				})
				.catch((error) => {
					//change
					alertError({
						error: " ",
						customMessage: error?.response?.data?.message || " ",
					});
				});
		} else
			alertError({
				error: " ",
				customMessage: "¡No has añadido a ningún participante!",
			});
	}

	function reconnect(group) {
		dispatch(setGroup(group));
		setGroupTable(group.tableNum);
		if (group.lastVisited) {
			if (!noTimeScreens.includes(group.lastVisited) && !group.finished)
				updateTimer(group.time);
			navigate(group.lastVisited);
		} else navigate(ROUTES.START_GAME);
	}

	const handleAddMember = () => {
		if (
			members.length < 9 &&
			actualMember.length != 0 &&
			actualMember.length < 45 &&
			actualMember.trim().indexOf(" ") != -1
		) {
			setMembers([...members, actualMember]);
			setActualMember("");
		} else {
			let message =
				actualMember.length >= 45
					? "Nombre demasiado largo"
					: actualMember.trim().indexOf(" ") === -1
					? "¡Cada participante debe tener nombre y apellido!"
					: "Los grupos deben ser de máximo 9 participantes";
			alertError({
				error: " ",
				customMessage: message,
			});
		}
	};

	const handleChange = (element) => (event) => {
		setActiveGroup({ ...activeGroup, [element]: event.target.value });
	};

	const handleGroupTableChange = (value) => {
		setActiveGroup((prevGroup) => ({
			...prevGroup,
			tableNum: value || "",
		}));
	};

	async function handleGroupTable(event) {
		getGroupByTableAndGame(event.target.value, activeGame._id)
			.then(async (resGroup) => {
				if (resGroup.status === 200) {
					await alertGroup({ data: resGroup.data }).then((res) => {
						if (res.isConfirmed) {
							reconnect(resGroup.data.group);
						} else {
							handleGroupTableChange();
							document.getElementById("sector").selectedIndex = 0;
						}
					});
				}
			})
			.catch((error) => {
				handleGroupTableChange(event.target.value);
			});
	}

	function renderOptions() {
		return Array.from(
			{
				length: parseInt(activeGame?.numTables),
			},
			(_, index) => (index + 1).toString()
		).map((option, index) => (
			<option key={index} value={option}>
				{option}
			</option>
		));
	}

	function renderMembers() {
		return members.map((name, index) => (
			<div
				key={index}
				className="d-flex justify-content-between align-items-center mt-3 px-2 pe-5"
			>
				<div className="txtBlue px-4">{name}</div>
				<img
					src={removeIcon}
					alt=""
					className="btn-remove d-flex align-items-center justify-content-center"
					onClick={() =>
						setMembers(members.filter((member) => member != name))
					}
				/>
			</div>
		));
	}

	return (
		<div className="h-100">
			<div className="d-flex justify-content-between align-items-center legalGebro px-5 pb-3 pt-3 bg-white">
				<div>
					<img
						style={{ marginLeft: "-10%" }}
						src={logo}
						alt="La Receta del Exito"
						className="img-fluid w-100"
					/>
				</div>
				<div>
					<img
						src={nutriciaAzul}
						alt="Nutricia"
						className="img-fluid"
					/>
				</div>
			</div>
			<div className="container-fluid bgBlue h-100">
				<div className="row pt-5 d-flex justify-content-between px-5">
					<div className="col-4">
						<div className="title1 mb-1 txtBlue bold">
							{"Haz una foto de equipo"}
						</div>
						<div className="fotoButton mt-3 image-upload text-center bg-transparent">
							<label htmlFor={"file-input"}>
								<img
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: icofoto
									}
									alt="Haz una foto de equipo"
									className={`img-fluid icofoto ${
										selectedImage ? "icofoto-selected" : ""
									}`}
								/>
							</label>
							<input
								id={"file-input"}
								name={"file-input"}
								type="file"
								accept="image/*"
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
						</div>
					</div>
					<div className="col-5">
						<div className="title1 mb-1 txtBlue bold">
							{"Añade los participantes"}
						</div>
						<div className="container-add">
							<input
								id={`fullName`}
								InputLabelProps={{
									shrink: true,
								}}
								value={actualMember}
								onChange={(event) => {
									setActualMember(event.target.value);
								}}
								autoComplete="off"
								placeholder="Escribe nombre y apellido"
								required
								className="form-control input-add bg-transparent txtBlue"
							/>
							<img
								src={addIcon}
								alt=""
								className="btn-add d-flex align-items-center justify-content-center"
								onClick={handleAddMember}
							/>
						</div>
						<div className="row col-5 mt-3 position-absolute">
							{renderMembers()}
						</div>
					</div>
				</div>
				<div className="row d-flex justify-content-between px-5">
					<div className="mt-4 col-5">
						<div className="title1 mb-1 txtBlue bold">
							{"Elige el número de mesa"}
						</div>
						<select
							id="sector"
							name="sector"
							defaultValue={"default"}
							className="form-control bg-transparent txtBlue table-select"
							onChange={handleGroupTable}
						>
							<option disabled hidden value={"default"}>
								{activeGame?.demo && activeGroup.tableNum !== ""
									? activeGroup.tableNum.toString()
									: "Selecciona una mesa"}
							</option>
							{renderOptions()}
						</select>
					</div>
				</div>
				<div className="row d-flex justify-content-between px-5">
					<div className="col-5">
						<div className="title1 mt-4 mb-1 txtBlue">
							<span className="bold">
								{"Pon un nombre a tu equipo "}
							</span>
							<div className="d-inline-flex title1B txtBlue txt14">
								{"(máximo 15 caracteres)"}
							</div>
						</div>
						<input
							id={`fullName`}
							InputLabelProps={{
								shrink: true,
							}}
							autoComplete="off"
							maxLength={15}
							onChange={handleChange("fullName")}
							placeholder="Escribe el nombre"
							required
							className="form-control bg-transparent txtBlue"
						/>
					</div>
					<div
						className="col-5 d-flex justify-content-end align-items-center"
						style={{ marginTop: "10%" }}
					>
						<div
							className="btn-main"
							onClick={(e) => {
								saveGroup(e);
							}}
						>
							<div className="text-btn-main">AVANZAR</div>
							<img className="ms-2" src={flechaAvanzar}></img>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Register);
