import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnswerByQuizAndGroup, postAnswer } from "../../api/answer";
import iconSeleccionar from "../../assets/icon-seleccionar.svg";
import iconAmpliarSmall from "../../assets/mas-info-pequeño.svg";
import iconAmpliar from "../../assets/mas-info.svg";
import papel1 from "../../assets/papel1.png";
import papel2 from "../../assets/papel2.png";
import papel3 from "../../assets/papel3.png";
import papel4 from "../../assets/papel4.png";
import papel5 from "../../assets/papel5.png";
import papel6 from "../../assets/papel6.png";
import Intentos from "../../components/Intentos";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateTime } from "../../redux/actions";
import {
	QUESTION_ALREADY_ANSWERED_BUTTON,
	QUESTION_ALREADY_ANSWERED_TEXT,
	QUIZZES,
	ROUTES,
	SCENARIOS,
} from "../../utils/constants";
import { getScenarioNumByPath, getTimer } from "../../utils/helpers";
import { modalPapel, modalQuestionResolution } from "../../utils/logger";

function Scenario5({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const group = useSelector((state) => state.group);

	const activeScenario = getScenarioNumByPath(location.pathname);

	const [checkedAnswers, setCheckedAnswers] = useState(false);
	const [isCompleted, setIsCompleted] = useState(false);

	const [intentosRestantes, setIntentosRestantes] = useState(2);
	const [time, setTime] = useState(0);

	const [options, setOptions] = useState([
		{
			id: 1,
			image: papel1,
			selected: null,
			correct: false,
		},
		{
			id: 2,
			image: papel2,
			selected: null,
			correct: false,
		},
		{
			id: 3,
			image: papel3,
			selected: null,
			correct: false,
		},
		{
			id: 4,
			image: papel4,
			selected: null,
			correct: false,
		},
		{
			id: 5,
			image: papel5,
			selected: null,
			correct: true,
		},
		{
			id: 6,
			image: papel6,
			selected: null,
			correct: false,
		},
	]);

	const QUIZ_ID = QUIZZES.AFIRMACION_FALSA_ENTERAL;
	const SCENARIO_ID = SCENARIOS.SCENARIO_5;

	useEffect(() => {
		getAnswerByQuizAndGroup(QUIZ_ID, group?._id)
			.then((res) => {
				if (res.status === 200) {
					if (res.status === 200 && res.data.length > 0) {
						modalQuestionResolution({
							correct: false,
							allowOutsideClick: false,
							showCancelButton: false,
							showConfirmButton: true,
							customConfirmButtonHtml:
								QUESTION_ALREADY_ANSWERED_BUTTON,
							textHtml: QUESTION_ALREADY_ANSWERED_TEXT,
						}).then((res) => {
							if (res.isConfirmed)
								navigate(ROUTES.SCENARIO5_EXPLICATION);
						});
						setIsCompleted(true);
					}
				}
			})
			.catch((error) => {});
	}, []);

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	async function submitAnswer(correct) {
		const score = correct ? 1 : 0;
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			group: group?._id,
			score: score,
			time: time,
		})
			.then((res) => {
				if (res.status === 201) {
					setIsCompleted(true);
				}
			})
			.catch((error) => {});
	}

	const CORRECT_ANSWER_TEXT = `<div>
			<p class="txt22 txt-green bold">
				¡Efectivamente!			
			</p>
			<p class="txt18">				
				La afirmación falsa es: No es necesario formar
				al paciente sobre la manipulación aséptica de las vías
				de nutrición enteral debido al bajo riesgo de infección.
			</p>
		</div>`;

	const WRONG_ANSWER_TEXT = `<div>
		<p class="txt22 txt-red bold">
			¡Ojo!
		</p>
		<p class="txt18">
			Esta no es la afirmación falsa.
		</p>
		<p class="txt18 bold">
			Inténtalo de nuevo.
		</p>
	</div>`;

	const INCORRECT_ANSWER_TEXT = `<div>
		<p class="txt22 text-red bold">
			¡Lástima!
		</p>
		<p class="txt18">
			No has dado con la afirmación falsa, que es:
			No es necesario formar al paciente sobre la manipulación
			aséptica de las vías de nutrición enteral debido al
			bajo riesgo de infección.			
		</p>
		</div>
	</div>`;

	const RECUERDA_SELECCIONAR_TEXT = `<div>
	<div class="txt22 txt-red bold">
		Recuerda que debe tener una opción marcada para que se active el botón Comprobar.
	</div>
	</div>`;

	const SelectOption = ({ id }) => {
		let option = options.find((x) => x.id === id);

		const handleOptionClick = () => {
			if (isCompleted) return;
			let updatedOptions = options.map((x) =>
				x.id === id
					? { ...x, selected: true }
					: { ...x, selected: false }
			);

			setOptions(updatedOptions);
		};

		let baseStyle = { position: "absolute" };
		const specificStyle =
			id === 1
				? { bottom: "15%", right: "8%" }
				: id === 2
				? { bottom: "12%", right: "8%" }
				: id === 3
				? { bottom: "22%", right: "7%" }
				: id === 4
				? { bottom: "15%", right: "7%" }
				: id === 5
				? { bottom: "15%", right: "7%" }
				: id === 6
				? { bottom: "35%", right: "6%" }
				: "";
		const style = { ...baseStyle, ...specificStyle };

		return (
			<div className="d-flex align-items-start">
				<div className="position-relative d-flex justify-content-around">
					<div
						className={`checkbox ${
							checkedAnswers && option.correct
								? "correct"
								: checkedAnswers &&
								  option.selected &&
								  !option.correct
								? "incorrect"
								: option.selected
								? "checked"
								: ""
						} mt-1 cursor-pointer`}
						onClick={handleOptionClick}
					></div>
					<img src={option.image} width={"80%"}></img>
					<img
						src={iconAmpliarSmall}
						style={style}
						width={22}
						onClick={() => modalPapel({ image: option.image })}
					></img>
				</div>
			</div>
		);
	};

	function checkAnswers() {
		if (!options.some((x) => x.selected)) {
			modalQuestionResolution({
				correct: false,
				textHtml: RECUERDA_SELECCIONAR_TEXT,
				allowOutsideClick: true,
			});
			return;
		}
		let correct = options.some((x) => x.selected && x.correct);

		let completed = false;

		if (correct) {
			modalQuestionResolution({
				correct: correct,
				textHtml: CORRECT_ANSWER_TEXT,
				allowOutsideClick: true,
			});
			completed = true;
		} else {
			if (intentosRestantes > 1)
				modalQuestionResolution({
					correct: correct,
					textHtml: WRONG_ANSWER_TEXT,
					allowOutsideClick: true,
				});
			else {
				modalQuestionResolution({
					correct: correct,
					textHtml: INCORRECT_ANSWER_TEXT,
					allowOutsideClick: true,
				});
				completed = true;
			}
		}
		setIntentosRestantes(intentosRestantes - 1);

		if (completed) {
			setCheckedAnswers(true);
			submitAnswer(correct);
		}
	}

	return (
		<>
			<div className="h-100 bg-preguntas-3">
				<div className="container-fluid px-5 pt-4 h-100">
					<div className="row d-flex align-items-center">
						<div className="col-11 d-flex justify-content-center ps-5">
							<div>
								<div className="d-flex justify-content-center align-items-center">
									<div className="txt20 txt-blue">
										Haz clic sobre la imagen
										<img
											className="mx-1"
											src={iconAmpliar}
										></img>
										de cada recorte del artículo para{" "}
										<span className="bold">ampliarla</span>.
									</div>
								</div>
								<div className="d-flex justify-content-center align-items-center">
									<div className="txt20 txt-blue">
										<img
											className="me-2"
											src={iconSeleccionar}
										></img>
										Selecciona la{" "}
										<span className="bold">
											afirmación falsa
										</span>{" "}
										y pulsa{" "}
										<span className="bold">Comprobar</span>.
									</div>
								</div>
							</div>
						</div>
						<div className="col-1 d-flex justify-content-end">
							<Intentos
								intentosRestantes={intentosRestantes}
							></Intentos>
						</div>
					</div>
					<br />
					<div className="row d-flex justify-content-center align-items-start px-3 pt-2">
						<div className="col-4">
							<SelectOption id={1}></SelectOption>
						</div>
						<div className="col-4">
							<SelectOption id={2}></SelectOption>
						</div>
						<div className="col-4 d-flex justify-content-end">
							<SelectOption id={3}></SelectOption>
						</div>
					</div>
					<br />
					<div className="row d-flex justify-content-center align-items-start px-3">
						<div className="col-5">
							<SelectOption id={4}></SelectOption>
						</div>
						<div className="col-4">
							<SelectOption id={5}></SelectOption>
						</div>
						<div className="col-3 d-flex justify-content-end">
							<SelectOption id={6}></SelectOption>
						</div>
						<div
							className="px-5 d-flex justify-content-between"
							style={{
								bottom: "10vh",
								position: "absolute",
							}}
						>
							<div className="txt18 txt-blue">
								Conseguirás{" "}
								<span className="bold">
									el quinto y último sello
								</span>{" "}
								si resuelves la prueba en cualquiera de los
								intentos.
							</div>
							{!isCompleted ? (
								<button
									className="btn-main bold"
									onClick={() => {
										checkAnswers();
									}}
								>
									<div className="text-btn-main">
										COMPROBAR
									</div>
								</button>
							) : (
								<NextButton
									navigateTo={ROUTES.SCENARIO5_EXPLICATION}
									activeScenario={activeScenario}
								></NextButton>
							)}
						</div>
					</div>
				</div>
				<ScenarioBar activeScenario={activeScenario} />
			</div>
		</>
	);
}

export default injectIntl(Scenario5);
