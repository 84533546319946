import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import puntitos from "../../assets/puntitos.svg";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioNumByPath } from "../../utils/helpers";

function Scenario4Instructions({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const activeScenario = getScenarioNumByPath(location.pathname);

	return (
		<>
			<div className="h-100 bg-instrucciones-scenario4">
				<div className="container-fluid px-5 pt-5 h-100">
					<div className="row">
						<div className="col-7 d-flex flex-column align-items-start">
							<div>
								<img src={puntitos}></img>
							</div>
							<p className="txt30 txt-purple bold line-height-40 mt-4">
								Para conseguir{" "}
								<span className="txt-pink">
									el cuarto de los sellos
								</span>
								, tienes que demostrar a la gerente tus
								conocimientos en nutrición enteral y parenteral.
							</p>
							<div className="txt20 txt-blue">
								<p className="mt-0">
									A continuación, aparecerán{" "}
									<span className="bold">8 situaciones</span>;
									señala en cuáles de ellas es de indicación
									la{" "}
									<span className="bold">
										nutrición enteral
									</span>{" "}
									y en cuáles está contraindicada y debe
									instaurarse{" "}
									<span className="bold">
										nutrición parenteral
									</span>
									.
								</p>
								<div className="d-flex align-items-center">
									<div>
										Cuando hayas completado las 8
										situaciones, pulsa
									</div>
									<div className="d-flex align-items-center">
										<button className="btn-main txt8 w-fit-content h-fit-content ms-2">
											COMPROBAR
										</button>
									</div>
								</div>
								<p> para ver el resultado.</p>
								<div className="mt-4">
									<span className="bold">
										Solo dispones de 2 intentos.
									</span>{" "}
									Conseguirás el sello si resuelves la prueba
									en cualquiera de ellos.
								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div
						className="px-5"
						style={{
							position: "absolute",
							left: "0px",
							bottom: "24vh",
						}}
					>
						<NextButton
							navigateTo={ROUTES.SCENARIO4}
							activeScenario={activeScenario}
							classes={"mt-4"}
						></NextButton>
					</div>
				</div>
				<ScenarioBar activeScenario={activeScenario} />
			</div>
		</>
	);
}

export default injectIntl(Scenario4Instructions);
