import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import iconTabla from "../../assets/icon-tabla.svg";
import ilusValoracion from "../../assets/ilus-valoracion.png";
import iconImportant from "../../assets/importante.svg";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioNumByPath } from "../../utils/helpers";

function Scenario2Instructions({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const activeScenario = getScenarioNumByPath(location.pathname);

	return (
		<>
			<div className="h-100 bg-preguntas-3">
				<div className="container-fluid px-5 pt-5 h-100">
					<div className="row">
						<div className="col-6 d-flex flex-column align-items-start">
							<p className="txt30 txt-purple bold line-height-40">
								Tienes que demostrar a la gerente que estás al
								día en nuevas técnicas de valoración
								morfofuncional.
							</p>
							<div className="txt20 txt-blue mt-4">
								<p>
									En la tabla{" "}
									<img className="mx-1" src={iconTabla}></img>
									de la siguiente pantalla, arrastra al lado
									de cada técnica la letra correspondiente al
									elemento medido teniendo en cuenta su
									gradación de validez entre paréntesis (+ =
									validez débil; ++ = media; +++ = fuerte).
								</p>
								<div className="d-flex align-items-center mt-4">
									<div>
										Cuando hayas completado toda la tabla,
										pulsa
									</div>
									<div className="d-flex align-items-center">
										<button className="btn-main txt8 w-fit-content h-fit-content ms-2">
											VALIDAR
										</button>
									</div>
								</div>
								<p> y comprueba el resultado.</p>
								<br />
								<div className="d-flex align-items-center">
									<img
										className="me-2"
										src={iconImportant}
									></img>
									<div>
										<span className="bold">¡Ojo!</span> Dos
										técnicas pueden medir lo mismo
									</div>
								</div>
								<div>y con la misma gradación.</div>
							</div>
						</div>
						<div className="col-6 d-flex align-items-center justify-content-center mt-5 pt-5">
							<img src={ilusValoracion} width={"70%"} />
						</div>
					</div>
					<div
						className="px-5 me-5"
						style={{
							position: "absolute",
							right: "0px",
							bottom: "15%",
						}}
					>
						<NextButton
							navigateTo={ROUTES.SCENARIO2}
							classes={"mt-4"}
							activeScenario={activeScenario}
						></NextButton>
					</div>
				</div>
				<ScenarioBar activeScenario={activeScenario} />
			</div>
		</>
	);
}

export default injectIntl(Scenario2Instructions);
