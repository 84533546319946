import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getGameById } from "../api/game";
import flechaAvanzar from "../assets/flecha-avanzar.svg";
import puntitos from "../assets/puntitos.svg";
import ScenarioBar from "../components/ScenarioBar";
import { useLang } from "../translations/i18n";
import { ROUTES } from "../utils/constants";
import { updateStats, updateTimer } from "../utils/helpers";
import { modalWait } from "../utils/logger";

function GameIntroduction({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const game = useSelector((state) => state.game);
	const group = useSelector((state) => state.group);
	const [isCompleted, setIsCompleted] = useState(false);
	const { state } = useLocation();
	const { completed } = state || false;

	const activeScenario = 0;

	const handleStartGame = (e) => {
		e.preventDefault();
		getGameById(game?._id).then((res) => {
			if (res.status === 200) {
				let game = res.data;
				if (!game.active && !game.demo) {
					modalWait({
						html: `<div class="d-flex flex-column">
								<div>El juego aún no ha empezado.</div>
								<div class="mt-2">
									Espera a que el moderador dé la orden
								</div>
							</div>`,
					});
				} else {
					let time = group?.time ? parseInt(group.time) : 0;
					updateTimer(time);
					updateStats({
						...group,
						actualScenario: 1,
						time: 0,
						lastVisited: ROUTES.TRANSITION_SCENARIOS,
					});
					navigate(ROUTES.TRANSITION_SCENARIOS, {
						state: {
							activeScenario: 1,
						},
					});
				}
			}
		});
	};

	return (
		<div className="h-100 bg-instrucciones">
			<div className="container-fluid h-100 pt-5 px-5">
				<div className="row pt-4">
					<div className="col-6 d-flex flex-column align-items-start">
						<div>
							<img src={puntitos}></img>
						</div>
						<p className="txt30 txt-purple bold mt-4 line-height-40">
							¡Ayuda a Paco a implementar una Unidad de Nutrición
							Clínica!
						</p>
						<p className="txt20 txtBlue">
							Tienes que{" "}
							<span className="bold">superar 6 escenarios</span>{" "}
							para conseguir los sellos correspondientes.
						</p>
						<p className="txt20 txtBlue">
							Presta atención,{" "}
							<span className="bold">
								debate con tus compañeros y consensuad una
								respuesta
							</span>
							. Solo un equipo podrá convencer a la gerente: el
							que consiga el documento con los{" "}
							<span className="bold">5 sellos</span> en el menor
							tiempo posible.
						</p>
						<p className="txt20 txtBlue">
							Dispones de <span className="bold">60 minutos</span>{" "}
							como máximo.
						</p>
						<div style={{ position: "absolute", bottom: "30%" }}>
							<button
								onClick={handleStartGame}
								className={`btn-main bold`}
							>
								<div className="text-btn-main">AVANZAR</div>
								<img className="ms-2" src={flechaAvanzar}></img>
							</button>
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar activeScenario={activeScenario} />
		</div>
	);
}

export default injectIntl(GameIntroduction);
