import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import casosClinicos from "../../assets/casos-clinicos.png";
import gerente from "../../assets/gerente.png";
import iconoZip from "../../assets/icono-zip.svg";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioNumByPath } from "../../utils/helpers";

function Scenario3ExtraInstructions({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const activeScenario = getScenarioNumByPath(location.pathname);

	const [unzipped, setUnzipped] = useState(false);
	const [showImage, setShowImage] = useState(false);
	const [canAdvance, setCanAdvance] = useState(false);

	const doubleClickThreshold = 300;
	const [lastClickTime, setLastClickTime] = useState(0);

	return (
		<>
			<div className="h-100 bg-preguntas-3">
				<div className="container-fluid px-5 pt-5 h-100">
					<div className="row">
						<div className="col-7 d-flex justify-content-start">
							<p className="txt30 txt-purple bold line-height-40">
								¡Ahora sí! Haces{" "}
								<span className="txt-pink">doble clic</span> en
								el archivo comprimido, que contiene 4 carpetas.
							</p>
						</div>
						<div className="row d-flex align-items-start">
							<div className="col-5 d-flex flex-column align-items-start">
								<p className="txt20 txt-blue">
									Demuéstrale a Carmen lo que sabes
									resolviendo su prueba.
								</p>
								<p className="txt20 txt-blue bold">
									Recuerda que es importante que debatas con
									tus compañeros y consensuéis las respuestas.
								</p>
							</div>
							<div
								className={`col-6 ms-5 d-flex flex-column ${
									canAdvance
										? "align-items-end pe-5"
										: "align-items-center pe-5"
								}`}
								style={{ marginTop: "-10%" }}
							>
								<div className="d-flex flex-column align-items-center">
									<img
										className={`${
											!canAdvance
												? "cursor-pointer zip-click-animation"
												: ""
										}`}
										style={{ zIndex: "100" }}
										onClick={() => {
											const currentTime =
												new Date().getTime();
											if (
												currentTime - lastClickTime <
												doubleClickThreshold
											) {
												setUnzipped(true);
											}
											setLastClickTime(currentTime);
										}}
										src={iconoZip}
										width={140}
									/>
									{unzipped && (
										<div>
											<div
												className="d-flex flex-column align-items-center"
												style={{
													marginTop: "-15px",
												}}
											>
												<div
													id="line"
													className="vertical-line-dashed unzip-line-animation"
													onAnimationEnd={() =>
														setShowImage(true)
													}
												></div>
												{showImage && (
													<img
														className="unzip-animation"
														src={casosClinicos}
														width={"90%"}
														onAnimationEnd={() =>
															setCanAdvance(true)
														}
													/>
												)}
											</div>
										</div>
									)}
								</div>
								{canAdvance && (
									<div
										className="position-absolute delayed-div px-5 me-5"
										style={{ bottom: "10%", right: "0%" }}
									>
										<NextButton
											navigateTo={ROUTES.SCENARIO3_EXTRA}
											activeScenario={activeScenario}
										></NextButton>
									</div>
								)}
							</div>
						</div>
					</div>
					<div>
						<div
							className="px-5"
							style={{
								position: "absolute",
								bottom: "0px",
								left: "0px",
							}}
						>
							<img src={gerente} width={"50%"}></img>
						</div>
					</div>
				</div>
				<ScenarioBar activeScenario={activeScenario} />
			</div>
		</>
	);
}

export default injectIntl(Scenario3ExtraInstructions);
