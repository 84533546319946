import { baseClient, API } from "../index";
import { upload, uploadSingleFile } from "../files";

export const postGroup = async (group, image) => {
	let res = await upload(image);
	group.image = res.fileURL;
	return baseClient().post(`${API}/group`, group);
};

export const updateGroup = async (id, group, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "groups");
		group.image = response.data.fileURL;
	}
	return baseClient().put(`${API}/group/${id}`, group);
};

export const getGroupById = async (id) => {
	return baseClient().get(`${API}/group/${id}`);
};

// Get group by table & game
export const getGroupByTableAndGame = async (table, game) => {
	return baseClient().get(`${API}/group/table/${table}/game/${game}`);
};
