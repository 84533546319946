import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import "./App.css";
import TopBar from "./components/TopBar";
import "./customStyles/modals.css";
import { ROUTES } from "./utils/constants";

function App() {
	const location = useLocation();
	const dispatch = useDispatch();

	const noTopBarScreens = [
		ROUTES.HOME,
		ROUTES.REGISTER,
		ROUTES.START_GAME,
		ROUTES.CONGRATULATIONS,
		ROUTES.END,
		ROUTES.RESULTS,
		ROUTES.GAMEOVER,
	];
	const showTopBar = !noTopBarScreens.some(
		(x) => location.pathname.toString() === x
	);

	window.onbeforeunload = function () {
		return "Your work will be lost.";
	};

	return (
		<>
			<div className="App">
				{showTopBar && <TopBar></TopBar>}
				<Outlet />
			</div>
		</>
	);
}
export default App;
