import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import emailError from "../../assets/email-error.png";
import gerente from "../../assets/gerente.png";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioNumByPath } from "../../utils/helpers";

function Scenario3Instructions2({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const activeScenario = getScenarioNumByPath(location.pathname);

	return (
		<>
			<div className="h-100 bg-preguntas-3">
				<div className="container-fluid px-5 pt-5 h-100">
					<div className="row">
						<div className="col-8">
							<p className="txt28 txt-purple bold line-height-30">
								¡Vaya! Carmen Prieto en persona te ha preparado
								una prueba para conseguir un sello.
							</p>
						</div>
					</div>
					<div className="row d-flex align-items-start pt-2">
						<div className="col-5">
							<p className="txt20 txt-blue">
								Procedes a descargar el adjunto, haces clic para
								descomprimirlo y{" "}
								<span className="bold">
									te sale el siguiente mensaje en pantalla
								</span>
								:
							</p>
						</div>
						<div className="col-7 ps-4">
							<div className="d-flex flex-column align-items-end">
								<img src={emailError} width={"105%"} />
								<div style={{ marginTop: "-2%" }}>
									<NextButton
										navigateTo={ROUTES.SCENARIO3}
										activeScenario={activeScenario}
									></NextButton>
								</div>
							</div>
						</div>
					</div>
					<div
						className="px-5"
						style={{
							position: "absolute",
							bottom: "0px",
							left: "0px",
						}}
					>
						<img src={gerente} width={"50%"}></img>
					</div>
				</div>
				<ScenarioBar activeScenario={activeScenario} />
			</div>
		</>
	);
}

export default injectIntl(Scenario3Instructions2);
