import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import flechaAvanzar from "../assets/flecha-avanzar.svg";
import { updateScenario, updateTime } from "../redux/actions";
import { getTimer, updateStats } from "../utils/helpers";

function NextButton({
	navigateTo,
	navigateToAfter,
	activeScenario,
	text,
	classes,
}) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const group = useSelector((state) => state.group);
	const gameId = useSelector((state) => state.game)?._id;
	const scenario = activeScenario || location?.state?.activeScenario;

	async function handleNext(e) {
		e.preventDefault();
		let time = getTimer();
		dispatch(updateTime(time));
		dispatch(updateScenario(scenario));
		updateStats({
			...group,
			actualScenario: scenario || group?.actualScenario,
			time: time,
			lastVisited: navigateTo,
		});
		navigate(navigateTo, {
			state: {
				navigateTo: navigateToAfter,
				activeScenario: scenario,
			},
		});
	}

	return (
		<button
			onClick={(e) => handleNext(e)}
			className={`btn-main bold ${classes} `}
		>
			<div className="text-btn-main">{text || "AVANZAR"}</div>
			<img className="ms-2" src={flechaAvanzar}></img>
		</button>
	);
}
export default NextButton;
