import Swal from "sweetalert2";
import confirmationIcon from "../assets/bombilla.svg";
import closeCross from "../assets/close.svg";
import flechaAvanzar from "../assets/flecha-avanzar.svg";
import happyFace from "../assets/happy-face.svg";
import iconPDF from "../assets/icon-pdf.svg";
import medico from "../assets/medico.png";
import neutralFace from "../assets/neutral-face.svg";
import sadFace from "../assets/sad-face.svg";
import videoInstrucciones from "../assets/videos/video-instrucciones.mp4";

export const logError = ({
	error,
	customMessage = "No custom message from backoffice",
}) => {
	error?.response?.status
		? console.log(
				"ERROR\n" +
					`Status: ${error.response.status}.\n` +
					`Status error: ${error.response.statusText}.\n` +
					`API Message: ${error.response.data?.message}\n` +
					`Backoffice Message: ${customMessage}`
		  )
		: console.log(
				"ERROR\n" +
					`Backoffice message: ${customMessage}\n` +
					`${error}`
		  );
};

export const alertError = ({ error, customMessage }) => {
	const message =
		error?.response?.data?.message ||
		error?.response?.statusText ||
		error ||
		customMessage ||
		"Error has no message";

	const params = {
		icon: "error",
		title: (customMessage?.length < 70 && customMessage) || "Ooops...",
		text: message,
	};

	Swal.fire(params);
};

export const alertResume = ({} = {}) => {
	const params = {
		icon: "question",
		title: "¿Quieres seguir con la partida predeterminada?",
		confirmButtonText: "Sí, continuar partida",
		cancelButtonText: "No, jugar la de pruebas",
		showCancelButton: true,
		reverseButtons: true,
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: false,
		customClass: {
			popup: "modal-resolution-container",
			confirmButton: "btn-main w-fit-content",
			cancelButton: "btn-main-cancel w-fit-content",
		},
	};
	return Swal.fire(params);
};

export const alertGroup = ({ data } = {}) => {
	const html = `
	<div class='text-start'>
		<div class='bold'>Nombre: <span class='regular'>${
			data.group.fullName
		}</span></div>
		<div class='bold'>Mesa: <span class='regular'>${
			data.group.tableNum
		}</span></div>
		<div class='bold'>Participantes:<br/> ${data.members
			.map((member) => {
				return `<span class='ps-3 my-1 regular'>${member.fullName}<br/></span>
		`;
			})
			.join("")}</div>
	</div>`;
	const params = {
		icon: "question",
		html: html,
		title: "¿Es este tu grupo?",
		confirmButtonText: "Sí, continuar partida",
		cancelButtonText: "No, escoger otro",
		showCancelButton: true,
		reverseButtons: true,
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: false,
		customClass: {
			confirmButton: "btn-main w-fit-content",
			cancelButton: "btn-main-cancel w-fit-content",
		},
	};
	return Swal.fire(params);
};

export const modalTableNum = ({ tableNum } = {}) => {
	let html = `<div class="p-4 d-flex flex-column align-items-center text-center txt22 txt-blue">
			<div>
				Se te ha asignado la mesa <span class="bold">${tableNum}.</span>
			</div>
			<div class="mt-3">¡Acuérdate del número por si hay cualquier problema!</div>
		</div>`;
	const params = {
		html: html || "",
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: true,
		showCloseButton: true,
		showConfirmButton: true,
		showCancelButton: false,
		confirmButtonText: `<div class="d-flex align-items-center justify-content-center">DE ACUERDO</div>`,
		backdrop: "rgba(0,0,0, 0.7)",
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: "modal-question-explanation",
			confirmButton: "btn-main",
			closeButton: "modal-btn-close",
		},
	};
	Swal.fire(params);
};

export const modalEnunciado = ({ title, html } = {}) => {
	const params = {
		title: title || "",
		html: html || "",
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		showConfirmButton: false,
		showCancelButton: true,
		cancelButtonText: `<div class="d-flex align-items-center justify-content-center">
			<img class="me-2" src=${closeCross} alt="close"></img>CERRAR</div>`,
		backdrop: "rgba(0,0,0, 0.7)",
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: "modal-enunciado-container",
			htmlContainer: "modal-enunciado-html",
			title: "modal-text-title",
			closeButton: "modal-btn-close",
			cancelButton: "btn-main-cancel",
		},
	};
	Swal.fire(params);
};

export const modalText = ({ title, html, showCancelButton } = {}) => {
	const params = {
		title: title || "",
		html: html || "",
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		showConfirmButton: false,
		showCancelButton:
			showCancelButton !== undefined ? showCancelButton : false,
		cancelButtonText: `<div class="d-flex align-items-center justify-content-center">
			<img class="me-2" src=${closeCross} alt="close"></img>CERRAR</div>`,
		backdrop: "rgba(0,0,0, 0.7)",
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: "modal-text-container",
			htmlContainer: "modal-text-html",
			title: "modal-text-title",
			closeButton: "modal-btn-close",
			cancelButton: "btn-main-cancel w-fit-content",
		},
	};
	Swal.fire(params);
};

export const modalPapel = ({ image } = {}) => {
	let html = `<div class="d-flex justify-content-center align-items-center text-center"><img src=${image}></img></div>`;
	const params = {
		html: html || "",
		allowOutsideClick: true,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: false,
		showConfirmButton: false,
		showCancelButton: true,
		cancelButtonText: `<div class="d-flex align-items-center justify-content-center">
			<img class="me-2" src=${closeCross} alt="close"></img>CERRAR</div>`,
		backdrop: "rgba(0,0,0, 0.7)",
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: "modal-papel-container",
			closeButton: "modal-btn-close",
			cancelButton: "btn-main-cancel w-fit-content px-4",
		},
	};
	Swal.fire(params);
};

export const modalConfirm = ({ text, titleClass } = {}) => {
	let icon = `<img src=${confirmationIcon} alt='test' class="modal-confirm-icon"/>`;
	const params = {
		title: text || "¿Estás seguro de tu elección?",
		html: icon || "",
		allowEnterKey: false,
		allowEscapeKey: false,
		reverseButtons: true,
		confirmButtonText: `<div class="text-btn-main">ACEPTAR</div>`,
		cancelButtonText: `<div class="text-btn-main">CANCELAR</div>`,
		showCancelButton: true,
		backdrop: "rgba(0,0,0, .7)",
		customClass: {
			popup: "modal-confirm-container pb-5",
			title: titleClass || "modal-confirm-title bold mt-2",
			confirmButton: "btn-main bold mt-6",
			cancelButton: "btn-main-cancel bold mt-6",
		},
	};
	return Swal.fire(params);
};

export const modalQuestionExplanation = ({
	question,
	answerHtml,
	showConfirmButton,
	allowOutsideClick,
	showCloseButton,
} = {}) => {
	let html =
		`<div class="text-center">
		<div>
			<img class="my-4" src=` +
		medico +
		` alt='medico' height="100"/>
		</div>
		<div class="txt22 txt-blue bold">${question}</div>
		<br />
		<div class="txt18"${answerHtml}</div>	
	</div>`;
	const params = {
		html: html,
		allowOutsideClick:
			allowOutsideClick !== undefined ? allowOutsideClick : true,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: showCloseButton !== undefined ? showCloseButton : true,
		showConfirmButton:
			showConfirmButton !== undefined ? showConfirmButton : false,
		backdrop: "rgba(0,0,0, .7)",
		confirmButtonText: `<div class="d-flex align-items-center justify-content-center"><img class="me-2" src=${iconPDF}></img><div class="text-btn-main">VER CRITERIOS GLIM</div></div>`,
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: "modal-question-explanation",
			confirmButton: "btn-main w-fit-content",
			closeButton: "modal-btn-close",
		},
	};
	return Swal.fire(params);
};

export const modalQuestionResolution = ({
	correct,
	textHtml,
	fontCourier,
	allowOutsideClick,
	showConfirmButton,
	showCancelButton,
	customConfirmButtonHtml,
	customCancelButtonHtml,
	cancelFitContent,
	popupClass,
} = {}) => {
	let html = `<div class="text-center">
    <div>
      <img src=${
			correct === null ? neutralFace : correct ? happyFace : sadFace
		} alt='cara' height="50"/>
    </div>
    <br />
    <div>${textHtml}</div>
  </div>`;
	let fontClass = fontCourier ? "txt-courier" : "";

	const params = {
		html: html,
		allowOutsideClick:
			allowOutsideClick !== null ? allowOutsideClick : false,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: false,
		showCancelButton:
			showCancelButton === true || showCancelButton === false
				? showCancelButton
				: true,
		showConfirmButton:
			showConfirmButton === true || showConfirmButton === false
				? showConfirmButton
				: false,
		confirmButtonText: `<div class="d-flex align-items-center justify-content-center">${
			customConfirmButtonHtml
				? customConfirmButtonHtml
				: `<img class="me-2" src=${iconPDF}></img><div class="text-btn-main">VER CRITERIOS GLIM</div></div>`
		}</div>`,
		cancelButtonText: `<div class="d-flex align-items-center justify-content-center ${fontClass}">${
			customCancelButtonHtml
				? customCancelButtonHtml
				: `<img class="me-2" src=${closeCross} alt="close"></img>CERRAR</div>`
		}`,
		backdrop: "rgba(0,0,0, .7)",
		customClass: {
			popup: popupClass ? popupClass : "modal-question-explanation",
			confirmButton: "btn-main w-fit-content",
			cancelButton: `btn-main-cancel ${
				cancelFitContent ? "w-fit-content" : ""
			}`,
		},
	};
	return Swal.fire(params);
};

export const modalCasoClinico = ({ correct, textHtml } = {}) => {
	let html = `<div class="text-center">
    <div>
      <img src=${
			correct === null ? neutralFace : correct ? happyFace : sadFace
		} alt='cara' height="50"/>
    </div>
    <br />
    <div>${textHtml}</div>
  </div>`;

	const params = {
		html: html,
		allowOutsideClick: true,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		showCancelButton: false,
		showConfirmButton: false,
		backdrop: "rgba(0,0,0, .7)",
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: "modal-caso-clinico-resolution",
			htmlContainer: "modal-caso-clinico-html",
			closeButton: "modal-btn-close",
		},
	};
	return Swal.fire(params);
};

export const modalSello = ({
	imageSello,
	textHtml,
	showConfirmButton,
	achieved,
} = {}) => {
	let html = `<div class="text-center d-flex flex-column align-items-center">
			<div>
				${achieved ? `<img src=${imageSello} alt='sello' width="55%"/>` : ""}
			</div>
			<div class="mt-3">${textHtml}</div>
		</div>`;
	const params = {
		html: html,
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: true,
		showConfirmButton: showConfirmButton,
		showCloseButton: true,
		backdrop: "rgba(0,0,0, .7)",
		confirmButtonText: `<div class="d-flex align-items-center justify-content-center"><div class="text-btn-main">AVANZAR</div><img class="ms-2" src=${flechaAvanzar} alt="go"></img></div>`,
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: `modal-sello-container ${showConfirmButton ? "pb-5" : ""}`,
			confirmButton: "btn-main",
			closeButton: "modal-btn-close",
		},
	};
	return Swal.fire(params);
};

export const modalWait = ({ html }) => {
	let result = `<div class="d-flex flex-column align-items-center txt22 txt-blue">${html}</div>`;
	const params = {
		html: result,
		allowEnterKey: false,
		allowEscapeKey: false,
		confirmButtonText: `<div class="text-btn-main">ACEPTAR</div>`,
		showCancelButton: false,
		customClass: {
			popup: "modal-wait-container",
			confirmButton: "btn-main",
		},
	};
	return Swal.fire(params);
};

export const modalPDF = ({
	pdf,
	title,
	showCloseButton,
	allowOutsideClick,
	customCancelButtonHtml,
} = {}) => {
	let html = `<iframe
			id="pdfviewer"
			src=${pdf}
			frameborder="0"
			width="90%"
			height="550px"
			type="application/pdf"
		></iframe>`;
	const params = {
		title: title || "CRITERIOS GLIM",
		html: html,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton:
			showCloseButton === true || showCloseButton === false
				? showCloseButton
				: true,
		allowOutsideClick:
			allowOutsideClick === true || allowOutsideClick === false
				? allowOutsideClick
				: true,
		showCancelButton: true,
		showConfirmButton: false,
		cancelButtonText: customCancelButtonHtml
			? customCancelButtonHtml
			: `<div class="d-flex align-items-center justify-content-center">AVANZAR</div>`,
		backdrop: "rgba(0,0,0, .7)",
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: "modal-pdf-container",
			htmlContainer: "modal-pdf-html",
			title: "modal-text-title",
			cancelButton: "btn-main-cancel",
			closeButton: "modal-btn-close",
		},
	};
	return Swal.fire(params);
};

export const modalIntro = () => {
	let html = `
		<video class='video-playerB' autoplay controls='true' id='video' loop controlsList="nofullscreen" src=${videoInstrucciones}> 
			<source src=${videoInstrucciones} type='video/mp4'>
		</video>`;
	const params = {
		html: html || "",
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		showConfirmButton: false,
		backdrop: "rgba(0,0,0, .7)",
		closeButtonHtml: `<div class="modal-btn-close-video"></div>`,
		customClass: {
			popup: "modal-video-container",
			htmlContainer: "modal-video-html",
			closeButton: "modal-btn-close-video",
		},
	};
	Swal.fire(params);
};
