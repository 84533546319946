import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getGroupById } from "../../api/group";
import medicoAlegre from "../../assets/medico-alegre.png";
import medicoNeutro from "../../assets/medico-neutro.png";
import medicoTriste from "../../assets/medico-triste.png";
import selloRojo from "../../assets/sello-rojo.svg";
import selloVerde from "../../assets/sello-verde.svg";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioNumByPath } from "../../utils/helpers";

function Scenario5End({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const group = useSelector((state) => state.group);

	const activeScenario = getScenarioNumByPath(location.pathname);

	const [stampProgress, setStampProgress] = useState([]);

	const achievedStamps = stampProgress.filter((x) => x).length;

	useEffect(() => {
		if (group) {
			getGroupById(group?._id)
				.then((res) => {
					if (res.status === 200) {
						let group = res.data;
						let stamp1 = group?.stamp1;
						let stamp2 = group?.stamp2;
						let stamp3 = group?.stamp3;
						let stamp4 = group?.stamp4;
						let stamp5 = group?.stamp5;

						let stamps = [stamp1, stamp2, stamp3, stamp4, stamp5];

						setStampProgress(stamps);
					}
				})
				.catch((error) => {});
		}
	}, []);

	const CONTENT = (
		<div>
			{achievedStamps === 5 ? (
				<div>
					<div className="txt42 txt-blue bold">
						¡Has conseguido los 5 sellos!
					</div>
					<div className="txt26 txt-blue mt-4">
						El siguiente paso es{" "}
						<span className="bold">
							llevarle el documento sellado a Carmen
						</span>{" "}
						y <span className="bold">agendar una reunión</span> para
						poner en marcha la Unidad de Nutrición Clínica.
					</div>
				</div>
			) : achievedStamps >= 3 ? (
				<div>
					<div className="txt32 txt-blue bold">
						No has conseguido todos los sellos,
					</div>
					<div className="txt26 txt-blue mt-4">
						aunque te has acercado. De todos modos, vas a llevarle
						el documento a Carmen para hablar con ella.
					</div>
				</div>
			) : (
				<div className="txt26 txt-blue">
					Aunque has conseguido pocos sellos, igualmente tendrías que
					hablar con Carmen. Quizás consigas convencerla a pesar de
					todo…
				</div>
			)}
		</div>
	);

	return (
		<div className="h-100 bg-preguntas-3">
			<div
				className={`container-fluid ${
					achievedStamps === 5 ? "bg-confeti" : ""
				} h-100`}
			>
				<div className="row d-flex justify-content-center align-items-center h-100">
					<div
						className="col-7 text-center"
						style={{ marginTop: "-15%" }}
					>
						<div className="d-flex flex-column align-items-center mt-2 line-height-40">
							<div className="d-flex align-items-center mb-4">
								{stampProgress.map((achieved, index) => (
									<div
										className={`sello-container ${
											index !== 0 ? "ms-3" : ""
										}`}
									>
										<img
											className="sello-result"
											src={
												achieved
													? selloVerde
													: selloRojo
											}
											alt="sello"
										/>
									</div>
								))}
							</div>
							<div style={{ maxWidth: "90%" }}>
								{CONTENT}
								<div className="txt26 txt-blue mt-4">
									Pero antes de acceder a su despacho,
								</div>
								<div className="txt26 txt-blue">
									tienes que superar el{" "}
									<span className="bold txt-pink">
										supertest
									</span>
									.
								</div>
							</div>
							<div className="mt-5">
								<NextButton
									navigateTo={ROUTES.TRANSITION_SCENARIOS}
									activeScenario={activeScenario}
								></NextButton>
							</div>
						</div>
					</div>
				</div>
				<div
					className="px-5"
					style={{
						position: "absolute",
						right: "0px",
						bottom: "13%",
					}}
				>
					<img
						src={
							achievedStamps === 5
								? medicoAlegre
								: achievedStamps >= 3
								? medicoNeutro
								: medicoTriste
						}
						width={
							achievedStamps === 5
								? 220
								: achievedStamps >= 3
								? 145
								: 160
						}
					></img>
				</div>
			</div>
			<ScenarioBar activeScenario={activeScenario} />
		</div>
	);
}

export default injectIntl(Scenario5End);
