import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnswerByQuizAndGroup, postAnswer } from "../../api/answer";
import iconSeleccionar from "../../assets/icon-seleccionar.svg";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { updateTime } from "../../redux/actions";
import {
	QUESTION_ALREADY_ANSWERED_BUTTON,
	QUESTION_ALREADY_ANSWERED_TEXT,
	QUIZZES,
	ROUTES,
	SCENARIOS,
} from "../../utils/constants";
import { getScenarioNumByPath, getTimer } from "../../utils/helpers";
import { modalQuestionResolution } from "../../utils/logger";

function Scenario3({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const group = useSelector((state) => state.group);

	const activeScenario = getScenarioNumByPath(location.pathname);

	const [isSelectorOpen, setIsSelectorOpen] = useState(false);
	const [isCompleted, setIsCompleted] = useState(false);
	const [time, setTime] = useState(new Date().getTime());

	const QUIZ_ID = QUIZZES.FRAGMENTO_INCOMPLETO;
	const SCENARIO_ID = SCENARIOS.SCENARIO_3;

	const GOOD_ANSWER_TEXT = `<div class="txt-courier">
	<div class="txt22 txt-green txt-courier-bold">¡Correcto!</div>
	<br />
	<p class="txt18 line-height-30">Se le ha renovado la licencia del software descompresor durante un año más.</p>
	</div>`;

	const WRONG_ANSWER_TEXT = `<div class="txt-courier">
	<div class="txt18">
		El fragmento <span class="txt-red txt-courier-bold">no es correcto!</span>
	</div>
	<br />
	<p class="txt18">Inténtelo de nuevo.</p>
	</div>`;

	const RECUERDA_SELECCIONAR_TEXT = `<div class="txt-courier">
	<p class="txt18">
		El fragmento no está completo.</p>
	<p class="txt18">Seleccione la(s) 
		palabra(s) que le falta(n).
	</p>	
	</div>`;

	useEffect(() => {
		getAnswerByQuizAndGroup(QUIZ_ID, group?._id)
			.then((res) => {
				if (res.status === 200 && res.data.length > 0) {
					modalQuestionResolution({
						correct: false,
						allowOutsideClick: false,
						showCancelButton: false,
						showConfirmButton: true,
						customConfirmButtonHtml:
							QUESTION_ALREADY_ANSWERED_BUTTON,
						textHtml: QUESTION_ALREADY_ANSWERED_TEXT,
					}).then((res) => {
						if (res.isConfirmed)
							navigate(ROUTES.SCENARIO3_EXTRA_INSTRUCTIONS);
					});
					setIsCompleted(true);
				}
			})
			.catch((error) => {});
	}, []);

	const FIBRA_ID = "1";
	const NUTRIENTES_ID = "2";
	const PROTEINA_ID = "3";

	const CONTENT = [
		{ id: FIBRA_ID, value: "fibra" },
		{ id: NUTRIENTES_ID, value: "inmunonutrientes" },
		{ id: PROTEINA_ID, value: "proteína" },
	];

	const [selectedOptions, setSelectedOptions] = useState([
		{ selectorId: 1, selectedId: null, correctId: PROTEINA_ID },
		{ selectorId: 2, selectedId: null, correctId: FIBRA_ID },
		{ selectorId: 3, selectedId: null, correctId: NUTRIENTES_ID },
	]);

	const WordSelector = ({ selectorId }) => {
		const handleWordChange = (event) => {
			if (isCompleted) return;

			let updatedOptions = selectedOptions.map((option) =>
				option.selectorId === selectorId
					? { ...option, selectedId: event.target.value }
					: { ...option }
			);

			setSelectedOptions(updatedOptions);
		};

		return (
			<div className="select-wrapper me-2">
				<div className={`custom-select`}>
					<select
						id="sector"
						name="sector"
						className="form-control txt-black txt17"
						onChange={handleWordChange}
						value={
							CONTENT.find(
								(x) =>
									x.id ===
									selectedOptions.find(
										(x) => x.selectorId === selectorId
									)?.selectedId
							)?.id || "default"
						}
					>
						<option disabled hidden value={"default"}></option>
						{CONTENT.map((option) => {
							return (
								<option
									disabled={isCompleted}
									key={option.id}
									value={option.id}
								>
									{option.value}
								</option>
							);
						})}
					</select>
				</div>
			</div>
		);
	};

	async function submitAnswer(correct) {
		const score = correct ? 1 : 0;
		let totalTime = getTimer();
		let questionTime = Math.round((new Date().getTime() - time) / 1000);
		dispatch(updateTime(totalTime, 1));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			group: group?._id,
			score: score,
			time: questionTime,
		})
			.then((res) => {
				if (res.status === 201) {
					setIsCompleted(true);
				}
			})
			.catch((error) => {});
	}

	function checkAnswers() {
		if (selectedOptions.some((x) => x.selectedId === null)) {
			modalQuestionResolution({
				correct: false,
				textHtml: RECUERDA_SELECCIONAR_TEXT,
				allowOutsideClick: true,
				fontCourier: true,
			});
			return;
		}
		let correct = !selectedOptions.some(
			(x) => x.selectedId !== x.correctId
		);
		modalQuestionResolution({
			correct: correct,
			textHtml: correct ? GOOD_ANSWER_TEXT : WRONG_ANSWER_TEXT,
			allowOutsideClick: true,
			fontCourier: true,
		});

		if (correct) submitAnswer(correct);
	}

	return (
		<div className="h-100 bg-preguntas-3 pt-4">
			<div className="d-flex justify-content-center align-items-center">
				<img className="me-2" src={iconSeleccionar}></img>
				<div className="txt20 txt-blue">
					<span className="bold">
						Selecciona las palabras correctas
					</span>{" "}
					para el siguiente fragmento:
				</div>
			</div>
			<div
				className="container pt-4 bg-pantalla-zip mt-3"
				style={{ height: "70%" }}
			>
				<div
					className="d-flex justify-content-center"
					style={{
						marginLeft: "8.5%",
						marginTop: "21vh",
						maxWidth: "83%",
					}}
				>
					<div className="txt18 line-height-30">
						Para ajustarnos a la evidencia científica y ser más
						eficientes a la hora de seleccionar un tipo de
						suplementación artificial, no podemos ceñirnos
						únicamente a la denominación comercial de la fórmula.
						Debemos basarnos en la fuente de
						<WordSelector selectorId={1}></WordSelector>utilizada,
						la densidad calórica, el contenido en
						<WordSelector selectorId={2}></WordSelector>y su perfil
						(soluble/insoluble) de dicha fórmula, el tipo del resto
						de los macronutrientes (hidratos de carbono y lípidos)
						utilizados y la presencia o no de
						<WordSelector selectorId={3}></WordSelector>
						con posibilidad de influir en la patogenia de la
						enfermedad. Todo ello, centrado en la patología del
						paciente y los requerimientos energeticoproteicos de
						este<sup>11</sup>.
					</div>
				</div>
			</div>
			<div
				style={{
					position: "absolute",
					bottom: "7vh",
					right: "5%",
					zIndex: "999",
				}}
			>
				{!isCompleted ? (
					<button
						className="btn-main"
						onClick={() => {
							checkAnswers();
						}}
					>
						<div className="text-btn-main">COMPROBAR</div>
					</button>
				) : (
					<div>
						<NextButton
							navigateTo={ROUTES.SCENARIO3_EXTRA_INSTRUCTIONS}
							activeScenario={activeScenario}
						></NextButton>
					</div>
				)}
			</div>
			<ScenarioBar activeScenario={activeScenario} />
		</div>
	);
}

export default injectIntl(Scenario3);
