import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import email from "../../assets/email.png";
import gerente from "../../assets/gerente.png";
import puntitos from "../../assets/puntitos.svg";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioNumByPath } from "../../utils/helpers";

function Scenario3Instructions({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const activeScenario = getScenarioNumByPath(location.pathname);

	return (
		<>
			<div className="h-100 bg-preguntas-3">
				<div className="container-fluid px-5 pt-5 h-100">
					<div className="row h-100">
						<div className="col-5 d-flex flex-column align-items-start">
							<div className="mt-2">
								<img src={puntitos}></img>
							</div>
							<p className="txt30 txt-purple bold line-height-40 mt-4">
								La gerente te ha enviado un correo electrónico
								con{" "}
								<span className="txt-pink">
									4 fichas adjuntas
								</span>{" "}
								y el siguiente mensaje:
							</p>
						</div>
						<div className="col-7 ps-5 d-flex flex-column align-items-end mt-5">
							<img src={email} width={"105%"} />
							<div
								style={{
									marginTop: "1vh",
								}}
							>
								<NextButton
									navigateTo={ROUTES.SCENARIO3_INSTRUCTIONS_2}
									activeScenario={activeScenario}
								></NextButton>
							</div>
						</div>
					</div>
					<div
						className="px-5"
						style={{
							position: "absolute",
							bottom: "0",
							left: "0px",
						}}
					>
						<img src={gerente} width={"50%"}></img>
					</div>
				</div>
				<ScenarioBar activeScenario={activeScenario} />
			</div>
		</>
	);
}

export default injectIntl(Scenario3Instructions);
